import { Component, ViewEncapsulation } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatTooltipModule } from '@angular/material/tooltip'
import { FormlyModule } from '@ngx-formly/core'
import { ErrorMessageComponent } from 'app/shared/error-message/error-message.component'
import { BaseCustomFieldComponent } from '../base-custom-field.component'
import { NgIf } from '@angular/common'
import MapboxDraw from '@mapbox/mapbox-gl-draw'
import * as mapboxgl from 'mapbox-gl'
// import { LngLatLike } from 'mapbox-gl'
// import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import { MapBoxComponent } from 'app/shared/map-box/mapBox.component'
import { FormModes } from 'app/core/enums/form-modes.enum'
import { CustomInputNoteComponent } from '../custom-note/custom-note.component'
import { TranslocoModule } from '@jsverse/transloco'
@Component({
  selector: 'app-custom-map',
  standalone: true,
  imports: [
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule,
    MatInputModule,
    MatIconModule,
    MatTooltipModule,
    ErrorMessageComponent,
    NgIf,
    MapBoxComponent,
    CustomInputNoteComponent,
    TranslocoModule,
  ],
  templateUrl: './custom-map.component.html',
  styleUrl: './custom-map.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class CustomMapComponent extends BaseCustomFieldComponent {
  map: mapboxgl.Map
  draw: MapboxDraw
  drawnPolygons: any[] = []
  addingMarker = false
  formModes: FormModes = FormModes.Edit

  setLocationMarker(value) {
    this.formControl.setValue(value)
  }
}
