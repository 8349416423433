import { ChangeDetectorRef, Component, inject, OnInit } from '@angular/core'
import { AbstractControl, FormArray, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatTooltipModule } from '@angular/material/tooltip'
import { FieldType, FieldTypeConfig, FormlyFieldConfig, FormlyModule } from '@ngx-formly/core'
import { ErrorMessageComponent } from 'app/shared/error-message/error-message.component'
import * as moment from 'moment'
@Component({
  selector: 'app-custom-input-text',
  standalone: true,
  imports: [
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule,
    MatInputModule,
    MatIconModule,
    MatTooltipModule,
    ErrorMessageComponent,
  ],
  template: ``,
})
export class BaseCustomFieldComponent extends FieldType<FieldTypeConfig> implements OnInit {
  note: boolean = false
  noteText: string = ''
  evidenceImage: string = ''
  saveNote: boolean = false
  value: string = ''
  keyField: string = ''
  markerCoordinatesValue: any[] = []
  private cdr = inject(ChangeDetectorRef)
  ngOnInit(): void {
    if (this.formControl) {
      this.keyField = this.formControl.parent ? this.getKeyFromModel(this.formControl.parent) : undefined
      this.formControl.valueChanges.subscribe(value => {
        this.setValue(value)
        this.cdr.detectChanges()
      })

      if (this.props['saveValue']) {
        if (this.props['type'] == 'map') {
          this.markerCoordinatesValue = this.props['saveValue']
          this.formControl.setValue(this.props['saveValue'])
        } else {
          this.formControl.setValue(this.props['saveValue'][0])
        }

        this.noteText = this.props['note']
        this.cdr.detectChanges()
      }
      this.setValue(this.formControl.value)
    }
  }

  setValue(value: any) {
    if (typeof value !== 'object' && this.field.type !== 'custom-date-input') {
      value = value ? [String(value)] : []
    }
    if (value && this.field.type === 'custom-date-input') {
      if (this.props['type'] === 'date' && moment(value).isValid()) {
        value = [String(moment(value).format())]
      }
      if (this.props['type'] === 'time') {
        value = [String(value)]
      }
      if (this.props['type'] === 'dateTime') {
        if (value.dateField && value.timeField) {
          let valueTMP
          if (moment(value?.dateField).isValid()) {
            valueTMP = moment(value?.dateField).format()
          }
          value = valueTMP + ' ' + value?.timeField
          delete this.model[this.keyField]
        }
      }
    }
    if (value && this.field.type === 'custom-file-input') {
      value = value.map(item => item.image)
    }
    if (value && this.field.type === 'custom-map') {
      value = [String(value[0].lng), String(value[0].lat)]
    }
    if (this.model && this.keyField) {
      let formattedValue: any = {
        key: this.keyField,
        value,
        note: this.noteText,
        image: this.evidenceImage,
        type: this.field.type,
        subtype: this.props['type'],
      }
      this.model[this.keyField] = formattedValue
    }
  }
  get type() {
    return this.props['type'] || 'text'
  }

  get showError(): boolean {
    return this.formControl.invalid && this.formControl.touched
  }

  get errorMessage(): string {
    const errors = this.formControl.errors
    if (errors) {
      if (errors.required) {
        return this.field.validation?.messages?._required?.toString()
      }
      if (errors.minLength) {
        return this.field.validation?.messages?._minLength?.toString()
      }
      if (errors.numeric) {
        return this.field.validation?.messages?._numeric?.toString()
      }
      if (errors.max) {
        return this.field.validation?.messages?._max?.toString()
      }
      if (errors.min) {
        return this.field.validation?.messages?._min?.toString()
      }
    }
    return ''
  }

  private getKeyFromModel(parent: FormGroup | FormArray): string | undefined {
    if (parent instanceof FormGroup) {
      for (const key in parent.controls) {
        if (parent.controls[key] === this.formControl) {
          return key
        }
      }
    }
    return undefined
  }

  getNote(event: any) {
    this.noteText = event
    this.setValue(this.formControl.value)
  }

  getImage(event: any) {
    this.evidenceImage = event
    this.setValue(this.formControl.value)
  }

  public haveSameProperty(
    fieldConfig: FormlyFieldConfig,
    formControl: AbstractControl,
    propertyName: string,
  ): { areEqual: boolean; firstPropertyValue?: any } {
    if (fieldConfig.hasOwnProperty(propertyName) && formControl.hasOwnProperty(propertyName)) {
      return { areEqual: true, firstPropertyValue: fieldConfig[propertyName] }
    }
    return { areEqual: false }
  }
}
