import { Injectable } from '@angular/core';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { Permission } from 'app/core/interfaces/identity/permission.interface';
import { RegisteredPermissions, Permissions } from './data';

@Injectable({
    providedIn: 'root'
})
export class PermissionsMockApi
{
    private readonly _permissions: Permission[];

    /**
     * Constructor
     */
    constructor(private _fuseMockApiService: FuseMockApiService)
    {
        this._permissions = RegisteredPermissions.concat(Permissions);

        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void
    {
        // -----------------------------------------------------------------------------------------------------
        // @ Navigation - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/common/permissions', 0)
            .reply(() => {

                // Return the response
                return [
                    200,
                    this._permissions
                ];
            });
    }
}
