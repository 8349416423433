import { Component } from '@angular/core'
import { BaseCustomFieldComponent } from '../base-custom-field.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { FormlyModule } from '@ngx-formly/core'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatIconModule } from '@angular/material/icon'
import { CustomInputNoteComponent } from '../custom-note/custom-note.component'

@Component({
  selector: 'app-custom-slide-toggle',
  standalone: true,
  imports: [
    FormsModule,
    MatIconModule,
    ReactiveFormsModule,
    FormlyModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    MatTooltipModule,
    CustomInputNoteComponent,
  ],
  templateUrl: './custom-slide-toggle.component.html',
  styleUrl: './custom-slide-toggle.component.scss',
})
export class CustomSlideToggleComponent extends BaseCustomFieldComponent {}
