import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FuseAlertComponent } from '@fuse/components/alert';
import { FuseScrollbarDirective } from '@fuse/directives/scrollbar';
import { TranslocoModule } from '@jsverse/transloco';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { LibraryComponent } from 'app/core/library/library.component';
import { ConstantsPipe } from 'app/core/pipes/constants.pipe';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { BreadcrumbComponent } from 'xng-breadcrumb';
import { ErrorMessageComponent } from './error-message/error-message.component';
import { NoDataFoundComponent } from './no-data-found/no-data-found.component';
import { OptionsButtonListComponent } from './options-button-list/options-button-list.component';
import { FreshdeskWebwidgetModule } from 'angular-freshdesk';
import { FreshdeskConfig } from 'app/core/config/freshdesk.config';
import { SearchListComponent } from 'app/shared/search-list/search-list.component';
import { NewButtonListComponent } from './new-button-list/new-button-list.component';
import { AppBreadcrumbsComponent } from 'app/shared/app-breadcrumbs/app-breadcrumbs.component';
import { ActionsButtonListComponent } from './actions-button-list/actions-button-list.component';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    BreadcrumbComponent,
    ConstantsPipe,
    ErrorMessageComponent,
    FormsModule,
    FuseAlertComponent,
    FuseScrollbarDirective,
    HttpClientModule,
    LibraryComponent,
    MatAutocompleteModule,
    MatButtonModule,
    MatDatepickerModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatMomentDateModule,
    MatOptionModule,
    MatPaginatorModule,
    MatRadioModule,
    MatSelectModule,
    MatSnackBarModule,
    MatSortModule,
    MatTooltipModule,
    NgxMatSelectSearchModule,
    NoDataFoundComponent,
    ReactiveFormsModule,
    RouterModule,
    RxReactiveFormsModule,
    TranslocoModule,
    OptionsButtonListComponent,
    FreshdeskWebwidgetModule.forRoot(FreshdeskConfig),
    NewButtonListComponent,
    SearchListComponent,
    AppBreadcrumbsComponent,
    ActionsButtonListComponent
  ],
  exports: [
    CommonModule,
    BreadcrumbComponent,
    ConstantsPipe,
    ErrorMessageComponent,
    FormsModule,
    FuseAlertComponent,
    FuseScrollbarDirective,
    HttpClientModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatDatepickerModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatMomentDateModule,
    MatOptionModule,
    MatPaginatorModule,
    MatRadioModule,
    MatSelectModule,
    MatSnackBarModule,
    MatSortModule,
    MatTooltipModule,
    NgxMatSelectSearchModule,
    NoDataFoundComponent,
    ReactiveFormsModule,
    RouterModule,
    RxReactiveFormsModule,
    TranslocoModule,
    FreshdeskWebwidgetModule,
    OptionsButtonListComponent,
    NewButtonListComponent,
    SearchListComponent,
    AppBreadcrumbsComponent,
    ActionsButtonListComponent
  ]
})
export class SharedModule {}
