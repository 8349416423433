import { FreshdeskWebwidgetConfig } from "angular-freshdesk";

export class FreshdeskConfig extends FreshdeskWebwidgetConfig
{
    widgetId = 150000003017;
    locale = 'es';

    
    callback(FreshworksWidget) 
    {
        FreshworksWidget('hide', 'launcher');
    }
    
}