import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';

/**
 * Local storage service
 */
@Injectable({
  providedIn: 'root'
})
export class LStorageService {
  // Todo: encrypt data and decrypt data

  private _prefix: string = 'itague-';

  constructor(private localStorage: LocalStorageService) {}

  setItem(key: string, value: string) {
    this.localStorage.store(this._prefix + key, value);
  }

  getItem(key: string) {
    return this.localStorage.retrieve(this._prefix + key);
  }

  removeItem(key: string): void {
    this.localStorage.clear(this._prefix + key);
  }

  clear() {
    this.localStorage.clear();
  }

  observeStorageIten(key: string) {
    return this.localStorage.observe(this._prefix + key);
  }
}
