import { NgIf, NgFor } from '@angular/common'
import { AfterViewInit, ChangeDetectorRef, Component, ViewEncapsulation } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatTooltipModule } from '@angular/material/tooltip'
import { FormlyModule } from '@ngx-formly/core'
import { ErrorMessageComponent } from 'app/shared/error-message/error-message.component'
import { BaseCustomFieldComponent } from '../base-custom-field.component'
import { MatButtonModule } from '@angular/material/button'
import { Observable } from 'rxjs'
import { ImageCropperComponent } from 'app/shared/image-cropper/image-cropper.component'
import { BlockScrollStrategy, Overlay } from '@angular/cdk/overlay'
import { MatDialog } from '@angular/material/dialog'

@Component({
  selector: 'app-custom-file-input',
  standalone: true,
  imports: [
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule,
    MatIconModule,
    MatTooltipModule,
    ErrorMessageComponent,
    NgIf,
    NgFor,
    MatButtonModule,
  ],
  templateUrl: './custom-file-input.component.html',
  styleUrl: './custom-file-input.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class CustomFileInputComponent extends BaseCustomFieldComponent implements AfterViewInit {
  private _overlay: Overlay
  private _scrollStrategy: BlockScrollStrategy
  files: any[] = []
  disableButton: boolean = false
  maxFileSize: number = 5 * 1024 * 1024 //5mb
  constructor(private _matDialog: MatDialog, private cd: ChangeDetectorRef) {
    super()
  }
  ngAfterViewInit(): void {
    if (this.props['maxNumberFiles'] == undefined) {
      this.props['maxNumberFiles'] = 5
    }
  }

  onChange = (fileUrl: any[]) => {}

  onFileChange(event: any) {
    const files = event.target.files as FileList
    const validTypes = this.props['filesTypes'].split(',').map(mimeType => mimeType.trim())
    if (!validTypes.includes(files[0].type)) {
      // this.showFileError.emit(true)
      this.formControl.setValue(null)
      return
    }

    if (files.length > 0) {
      const _file = URL.createObjectURL(files[0])
      this.openItemEditor(_file).subscribe(result => {
        if (result) {
          if (result.size > this.props['maxFileSize'] * 1024 * 1024) {
            // this.errorMessage = `The file size exceeds the maximum allowed size of ${this.maxFileSize / (1024 * 1024)} MB.`;
            alert('max MB')
            return
          }
          let file = files[0]
          file['image'] = result
          this.files.push(file)
          this.onChange(this.files)
          if (this.files.length === this.props['maxFiles']) {
            this.disableButton = true
          } else {
            this.disableButton = false
          }
          this.formControl.patchValue(this.files)
          this.cd.detectChanges()
        }
      })
    }
  }

  openItemEditor(image: string): Observable<any> {
    let data = {
      image,
      custom_cropper: true,
    }
    this._scrollStrategy = this._overlay?.scrollStrategies.block()
    const dialogRef = this._matDialog.open(ImageCropperComponent, {
      autoFocus: false,
      disableClose: true,
      data: data,
      panelClass: ['app-modal-panel-full', 'custom-cropper'],
      scrollStrategy: this._scrollStrategy,
    })

    return dialogRef.afterClosed()
  }

  removeItem(index: number): void {
    if (index > -1 && index < this.files.length) {
      this.files.splice(index, 1)
      this.disableButton = false
      if (this.files.length === 0) {
        this.formControl.setValue(null)
      }
    }
  }
}
