<ng-container *transloco="let t">
  <div class="app-modal-container">

    <!-- Title -->
    <div class="modal-header app-modal-header">
      <div class="app-modal-title my-3 text-xl font-medium">{{ title }}</div>
    </div>

    <!-- Content -->
    <div class="modal-content app-modal-form-container" mat-dialog-content>
      @if(data?.description){
      <div class="app-modal-description my-3"><strong>{{ data.description }}</strong></div>
      }
      @if(data?.content){
      <div class="app-modal-description"><strong>{{ data.content }}</strong></div>
      }
      <ng-content select="[modal-content]"></ng-content>
    </div>

    <!-- Actions -->
    <div class="modal-footer app-modal-footer">

      <!-- Close -->
      @if (configCloseButton.show) {
      <button mat-stroked-button [matDialogClose]="configCloseButton.action">
        <span class="app-button-text">{{ t('buttons.labels.close')}}</span>
      </button>
      }

      <!-- Save -->
      @if (configSaveButton.show) {
      <button mat-flat-button [color]="'primary'" (click)="configSaveButton.action()"
        [disabled]="configSaveButton.isDisabled">
        <span class="app-button-text">{{ t('buttons.labels.save')}}</span>
      </button>
      }

      <!-- Continue -->
      @if (configContinueButton.show) {
      <button mat-flat-button [color]="'primary'" (click)="configContinueButton.action()"
        [disabled]="configContinueButton.isDisabled">
        <span class="app-button-text">{{ t('buttons.labels.continue') }}</span>
      </button>
      }
    </div>
  </div>
</ng-container>