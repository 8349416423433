import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-new-button-list',
    templateUrl: './new-button-list.component.html',
    styleUrls: ['./new-button-list.component.scss'],
    standalone: true,
    imports: [MatButtonModule, RouterLink, MatIconModule]
})
export class NewButtonListComponent {

  @Input() label:string;
  @Input() link:string|any[] = ['new'];

}
