import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import {
  defaultNavigation,
  defaultNavigationEn,
  dependentNavigation,
  dependentNavigationEn
} from 'app/mock-api/common/navigation/data';
import { Permissions } from 'app/mock-api/common/permissions/data';
import { RoleTypes } from 'app/core/enums/role-types.enum';
import { Permission } from 'app/core/interfaces/identity/permission.interface';
import { CaslActions } from '../../../core/enums/casl-actions.enum';
import { Languages } from 'app/core/enums/languages.enum';
import { UserService } from 'app/core/services/identity/user.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationMockApi {
  private readonly _defaultNavigation: FuseNavigationItem[] = defaultNavigation;
  private readonly _defaultNavigationEn: FuseNavigationItem[] = defaultNavigationEn;
  private readonly _dependentNavigation: FuseNavigationItem[] = dependentNavigation;
  private readonly _dependentNavigationEn: FuseNavigationItem[] = dependentNavigationEn;
  private readonly _permissions: Permission[] = Permissions;

  /**
   * Constructor
   */
  constructor(private _fuseMockApiService: FuseMockApiService, private _userService: UserService) {
    // Register Mock API handlers
    this.registerHandlers();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Register Mock API handlers
   */
  registerHandlers(): void {
    // -----------------------------------------------------------------------------------------------------
    // @ Navigation - GET
    // -----------------------------------------------------------------------------------------------------
    this._fuseMockApiService.onGet('api/common/navigation', 100).reply(({ request }) => {
      let language = request.headers.get('Accept-Language');

      let navigationToProcess: FuseNavigationItem[] = [];
      let userNavigation: FuseNavigationItem[] = [];

      // Set navigation to process
      if (this._userService.roleType === RoleTypes.Generic) {
        navigationToProcess =
          language == Languages.EsCo ? cloneDeep(this._defaultNavigation) : cloneDeep(this._defaultNavigationEn);
      } else if (this._userService.roleType === RoleTypes.Dependent) {
        navigationToProcess =
          language == Languages.EsCo ? cloneDeep(this._dependentNavigation) : cloneDeep(this._dependentNavigationEn);
      }

      // Set items for user navigation
      navigationToProcess.forEach((navItem) => {
        //Validate permissions for this items
        const menuWithPermission = this._permissions.find(
          (menu) =>
            menu.subject == navItem.id &&
            menu.action == CaslActions.View &&
            menu.roles.some((role) => this._userService.getRoles().includes(role))
        );
        if (menuWithPermission) {
          let navItemClone = cloneDeep(navItem);
          navItemClone.children = [];

          if (navItem.children) {
            navItem.children.forEach((child) => {
              const subMenuWithPermission = this._permissions.find(
                (menu) =>
                  menu.subject == child.id &&
                  menu.action == CaslActions.View &&
                  menu.roles.some((role) => this._userService.getRoles().includes(role))
              );
              if (subMenuWithPermission) {
                navItemClone.children.push(child);
              }
            });
          }

          userNavigation.push(navItemClone);
        }
      });

      // Return the response
      return [
        200,
        {
          default: userNavigation
        }
      ];
    });
  }
}
