import { Pipe, PipeTransform } from '@angular/core';
import { GenericConstants } from '../constants/generic.constants';
import { StorageKeysConstants } from '../constants/storage-keys.constants';
import { LibraryEntitiesConstants } from '../constants/library-entities.contants';

@Pipe({
    name: 'constants',
    standalone: true
})
export class ConstantsPipe implements PipeTransform {

  transform(value: string, args?: any): any {
    return {
      genericConstants: GenericConstants,
      storageKeysConstants: StorageKeysConstants,
      libraryEntitiesConstants: LibraryEntitiesConstants
    };
  }

}
