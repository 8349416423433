// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  name: 'dev',
  production: false,
  apiItagueUrl: 'https://dev.wg.itague.co/api/v1/',
  dsnSentry: 'https://7918754df54c4ebe8b1501e8db863dfa@o1289456.ingest.sentry.io/6507935',
  defaultCountryId: 43,
  apiIot: 'http://dev.app.itague.co:8117/api/v1/iot/measurements-hub',
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error' // Included with Angular CLI.
