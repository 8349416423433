export enum IndexTypes {
  Settings = 'settings',
  Planning = 'planning',
  Management = 'management',
  Inventory = 'inventory',
  ManagementInventory = 'managementInventory',
  Reports = 'reports',
  Analytics = 'analytics',
  Staff = 'staff',
  Certifications = 'certifications',
  Iot = 'iot',
}
