import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
  // Redirect empty path to '/example'
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard',
    data: {
      breadcrumb: {
        label: 'home',
        info: 'home'
      }
    }
  },

  // Redirect signed-in user to the '/example'
  //
  // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
  // path. Below is another redirection for that path to redirect the user to the desired
  // location. This is a small convenience to keep all main routes together here on this file.
  { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'dashboard' },

  // Auth routes for guests
  {
    path: '',
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty'
    },
    children: [
      { path: 'confirm-email', loadChildren: () => import('app/features/auth/confirm-email/confirm-email.routes') },
      {
        path: 'confirmation-required',
        loadChildren: () => import('app/features/auth/confirmation-required/confirmation-required.routes')
      },
      {
        path: 'forgot-password',
        loadChildren: () => import('app/features/auth/forgot-password/forgot-password.routes')
      },
      { path: 'reset-password', loadChildren: () => import('app/features/auth/reset-password/reset-password.routes') },
      { path: 'sign-in', loadChildren: () => import('app/features/auth/sign-in/sign-in.routes') },
      { path: 'sign-up', loadChildren: () => import('app/features/auth/sign-up/sign-up.routes') }
    ]
  },

  // Auth routes for authenticated users
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty'
    },
    children: [{ path: 'sign-out', loadChildren: () => import('app/features/auth/sign-out/sign-out.routes') }]
  },

  // Admin routes
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: initialDataResolver
    },
    children: [
      { path: 'dashboard', loadChildren: () => import('app/features/dashboard/dashboard.routes') },
      { path: 'my-account', loadChildren: () => import('app/features/my-account/master/my-account.routes') },
      { path: 'settings', loadChildren: () => import('app/features/settings/settings.routes') },
      {
        path: 'organizations',
        loadChildren: () =>
          import('app/features/organizations/master/organizations.module').then((m) => m.OrganizationsModule)
      },
      {
        path: 'producer-farms',
        loadChildren: () =>
          import('app/features/producer-farms/master/producer-farms.module').then((m) => m.ProducerFarmsModule)
      },
      {
        path: 'catalogs',
        loadChildren: () => import('app/features/catalogs/catalogs.module').then((m) => m.CatalogsModule)
      },
      {
        path: 'management',
        loadChildren: () => import('app/features/management/management.module').then((m) => m.ManagementModule)
      },
      {
        path: 'planning',
        loadChildren: () => import('app/features/planning/planning.module').then((m) => m.PlanningModule)
      },
      {
        path: 'inventory',
        loadChildren: () => import('app/features/inventory/inventory.module').then((m) => m.InventoryModule)
      },
      {
        path: 'analytics',
        loadChildren: () => import('app/features/analytics/analytics.module').then((m) => m.AnalyticsModule)
      },
      {
        path: 'file-manager',
        loadChildren: () => import('app/features/file-manager/file-manager.module').then((m) => m.FileManagerModule)
      },
      { path: 'staff', loadChildren: () => import('app/features/staff/staff.module').then((m) => m.StaffModule) },
      {
        path: 'certifications',
        loadChildren: () =>
          import('app/features/certifications/certifications.module').then((m) => m.CertificationsModule)
      },
      {
        path: 'iot',
        loadChildren: () => import('app/features/iot/iot.module').then((m) => m.IoTModule)
      },
      { path: 'experimental', loadChildren: () => import('app/features/experimental/experimental.routes') },

      /*
       */

      // Errors & Catch all
      {
        path: '404-not-found',
        pathMatch: 'full',
        loadChildren: () => import('app/features/pages/error-404/error-404.module').then((m) => m.Error404Module)
      },
      { path: '500-server-error', loadChildren: () => import('app/features/pages/error-500/error-500.routes') },
      { path: '**', redirectTo: '404-not-found' }
    ]
  }
];
