import {
  Component,
  Inject,
  OnInit,
  ViewEncapsulation,
  OnDestroy,
  ElementRef,
  ViewChild,
  AfterViewInit
} from '@angular/core';
import { UntypedFormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService, TranslocoModule, TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { SubmitNextActions } from 'app/core/enums/submit-next-actions.enum';
import { AppAlertService } from 'app/core/services/others/app-alert.service';
import { FormBaseComponent } from '../../base/form-base.component';
import { RequestUploadFile } from '../interfaces/request-upload-file.interface';
import { ConfigFileForm } from '../interfaces/config-file-form.interface';
import { HttpEventType } from '@angular/common/http';
import { FilesService } from '../services/files.service';
import { map, tap, last, takeUntil, Observable, delay, startWith } from 'rxjs';
import { AppErrorHandlerService } from '../../services/errors/app-error-handler.service';
import { GenericUtil } from '../../utils/generic.util';
import { SystemQueryOptions } from 'app/core/interfaces/others/system-query-options.interface';
import { EntityFile } from '../interfaces/entity-file.interface';
import { GenericValidators } from 'app/core/validators/generic.validator';
import { ConfirmDialogResponses } from '../../enums/confirm-dialog-responses.enum';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { ErrorMessageComponent } from '../../../shared/error-message/error-message.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { AsyncPipe, LowerCasePipe } from '@angular/common';
import { GenericConstants } from 'app/core/constants/generic.constants';
import { EntityFilesService } from '../services/entity-files.service';

export const loader = GenericConstants.APP_LANGUAGES.reduce((acc, lang) => {
  acc[lang] = () => import(`./i18n/${lang}.json`);
  return acc;
}, {});

@Component({
  selector: 'app-library-file',
  templateUrl: './library-file.component.html',
  styleUrls: ['./library-file.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    TranslocoModule,
    FormsModule,
    ReactiveFormsModule,
    RxReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    MatIconModule,
    MatTooltipModule,
    ErrorMessageComponent,
    MatInputModule,
    MatButtonModule,
    MatProgressBarModule,
    MatDialogModule,
    AsyncPipe,
    LowerCasePipe
  ],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'LibraryFileComponent',
        alias: 'libfil',
        loader
      },
      multi: true
    }
  ]
})
export class LibraryFileComponent
  extends FormBaseComponent<RequestUploadFile>
  implements OnInit, OnDestroy, AfterViewInit
{
  // Properties
  progressBarShow = false;
  maxSize: number = 0;
  requireEntityFileType: boolean = false;
  optionalEntityFiles$: Observable<EntityFile[]> = this._entityFilesService.optionalItems$;

  confirmDialogResponses = ConfirmDialogResponses;

  formatsAllowed: string = '';
  allowedFiles: File[] = [];
  notAllowedFiles: {
    fileName: string;
    fileSize: string;
    errorMsg: string;
  }[] = [];

  uploadStarted = false;
  uploadPercent: number = 0;
  Caption: string[] = [];
  formatsAllowedToShow: string = '';
  maxSizeToShow: string = '';

  @ViewChild('fileInput') fileInput: ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfigFileForm,
    public matDialogRef: MatDialogRef<LibraryFileComponent>,
    protected _translocoService: TranslocoService,
    protected _route: ActivatedRoute,
    protected _router: Router,
    protected _formBuilder: UntypedFormBuilder,
    private _entityFilesService: EntityFilesService,
    private _filesService: FilesService,
    private _appHandlerErrorService: AppErrorHandlerService,
    protected _appAlertService: AppAlertService,
    protected _genericUtil: GenericUtil
  ) {
    super();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  ngOnInit(): void {
    this._baseNgOnInit('libfil', 'male');
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this._baseNgOnDestroy();
  }

  ngAfterViewInit() {
    //this.cd.detectChanges();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  submitItem(nextAction: SubmitNextActions): void {
    // If form is invalid show errors form
    if (this.itemForm.invalid) {
      return this._showErrorsForm();
    } else {
      // Disable the form
      this.itemForm.disable();

      this.progressBarShow = true;

      this.uploadStarted = true;
      this.notAllowedFiles = [];

      let request: RequestUploadFile = {
        file: this.allowedFiles[0],
        entityFileTypeId: this.requireEntityFileType
          ? this.itemForm.get('entityFileTypeId').value.id
          : this.data.entityFileTypeId,
        entityGuid: this.data.entityGuid,
        externalEntity: this.data.externalEntity,
        observation: this.itemForm.get('observation').value,
        temporary: this.data.temporary
      };

      this._filesService
        .upload(request)
        .pipe(
          takeUntil(this._unsubscribeAll),
          map((event) => {
            if (event.type === HttpEventType.UploadProgress) {
              const currentDone = event.loaded / event.total;
              this.uploadPercent = Math.round((event.loaded / event.total) * 100);
            } else if (event.type === HttpEventType.Response) {
              return event;
            }
          }),
          tap((message) => {}),
          last()
        )
        .subscribe({
          next: (event) => {
            this.progressBarShow = false;
            this.matDialogRef.close(event.body);
          },
          error: (error) => {
            // Failure
            this.progressBarShow = false;
            // Re-enable the form
            this.itemForm.enable();

            this._appHandlerErrorService.handleError(error);
          }
        });
    }
  }

  // When user selects files.
  onChange(event: any) {
    const fileExtRegExp: RegExp = /(?:\.([^.]+))?$/;
    let fileList: FileList;

    //Remove previously files selected
    this.allowedFiles = [];
    this.Caption = [];
    this.itemForm.patchValue({ fileName: '' });
    this.notAllowedFiles = [];

    if (event.type === 'drop') {
      fileList = event.dataTransfer.files;
    } else {
      fileList = event.target.files || event.srcElement.files;
    }

    // 'forEach' does not exist on 'filelist' that's why this good old 'for' is used.
    for (let i = 0; i < fileList.length; i++) {
      const nameFileArray: RegExpExecArray | null = fileExtRegExp.exec(fileList[i].name);
      let currentFileExt: string = '';

      if (nameFileArray != null && nameFileArray.length > 0) {
        currentFileExt = nameFileArray[1].toLowerCase(); // Get file extension.
      }

      const isFormatValid = this.formatsAllowed.includes('*') ? true : this.formatsAllowed.includes(currentFileExt);

      const isSizeValid = fileList[i].size <= this.maxSize;

      // Check whether current file format and size is correct as specified in the configurations.
      if (isFormatValid && isSizeValid) {
        this.allowedFiles.push(fileList[i]);
      } else {
        this.notAllowedFiles.push({
          fileName: fileList[i].name,
          fileSize: this._genericUtil.convertSize(fileList[i].size),
          errorMsg: !isFormatValid ? 'Invalid format' : 'Invalid size'
        });

        if (!isFormatValid) {
          this.itemForm.get('file').setErrors({ fileformat: true, currentFileExt: currentFileExt });
        } else {
          this.itemForm.get('file').setErrors({ filesize: true, fileSize: this.notAllowedFiles[0].fileSize });
        }
      }
    }

    // If there's any allowedFiles.
    if (this.allowedFiles.length > 0) {
      this.itemForm.patchValue({ fileName: this.allowedFiles[0].name });
    }

    this.uploadStarted = false;
    this.uploadPercent = 0;
    event.target.value = null;
  }

  /**
   * Open file browser dialog
   */
  showFileBrowseDialog() {
    this.fileInput.nativeElement.click();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Protected methods
  // -----------------------------------------------------------------------------------------------------

  protected _loadData(): void {
    if (this.data.entityFileTypeId == null || this.data.entityFileTypeId == 0) {
      this.requireEntityFileType = true;

      const itemQueryOptions: SystemQueryOptions = {
        select: 'id,fileTypeName,fileTypeDescription,maxSize,required',
        orderby: 'fileTypeName asc',
        expand: 'extensions',
        otherParams: `entityGuid=${this.data.entityGuid}&externalGuid=${this.data.externalEntity}&optional=true`
      };

      this._entityFilesService
        .getOptionals(itemQueryOptions)
        .pipe(startWith(null), delay(0), takeUntil(this._unsubscribeAll))
        .subscribe();
    }

    // Track changes in Configuration and see if user has even provided Configuration.
    if (this.data && this.requireEntityFileType == false) {
      // Assign User Configurations to Library Properties.
      this.maxSize = this.data.maxSize || 1024000; // mb to bytes.
      this.formatsAllowed = this.data.formatsAllowed.toLowerCase() || '*';
      this.formatsAllowedToShow = this.formatsAllowed.replaceAll('.', '');
      this.formatsAllowedToShow = this.formatsAllowedToShow.replaceAll(',', ', ');
      this.maxSizeToShow = this._genericUtil.convertSize(this.maxSize);
    }
  }

  protected _createItemForm(): void {
    // Create the form
    this.itemForm = this._formBuilder.group({
      entityFileTypeId: [
        '',
        [GenericValidators.conditional(() => this.requireEntityFileType == true, Validators.required)]
      ],
      fileName: ['', [Validators.required]],
      file: ['', [Validators.required]],
      observation: ['', [Validators.max(300)]]
    });

    //this.itemForm.get('fileName').disable();
  }

  protected _editItemForm(): void {
    // none
  }

  protected _defineEmitEventsForControls(): void {
    // Subscriptions to changes in independent fields
    if (this.requireEntityFileType) {
      this.itemForm.get('entityFileTypeId').valueChanges.subscribe((value) => {
        this.maxSize = value.maxSize || 1024000; // mb to bytes.

        let formatsAllowed: string = '';

        let counter: number = 0;

        for (let extension of value.extensions) {
          if (counter > 0) {
            formatsAllowed = formatsAllowed.concat(',');
          }
          formatsAllowed = formatsAllowed.concat('.').concat(extension.name);
          counter++;
        }
        this.formatsAllowed = formatsAllowed.toLowerCase() || '*';

        this.formatsAllowedToShow = formatsAllowed.toLowerCase().replaceAll('.', '');
        this.formatsAllowedToShow = this.formatsAllowedToShow.replaceAll(',', ', ');
        this.maxSizeToShow = this._genericUtil.convertSize(this.maxSize);
        this.data.help = value.fileTypeDescription;
      });
    }
  }
}
