import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { TranslocoService, TranslocoDirective, TranslocoModule, TRANSLOCO_SCOPE } from '@jsverse/transloco'
import { AppAlertService } from '../services/others/app-alert.service'
import { ListBaseComponent } from 'app/core/base/list-base.component'
import { EntityFile } from './interfaces/entity-file.interface'
import { ListBase } from '../base/list.interface'
import { FilesService } from './services/files.service'
import { ConfigList } from '../interfaces/others/config-list.interface'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { LibraryFileComponent } from './library-file/library-file.component'
import { merge } from 'lodash-es'
import { ConfigFileForm } from './interfaces/config-file-form.interface'
import { Subscription, takeUntil } from 'rxjs'
import { AppFile } from './interfaces/app-file.interface'
import { GenericUtil } from '../utils/generic.util'
import { SystemQueryOptions } from 'app/core/interfaces/others/system-query-options.interface'
import { Output, EventEmitter } from '@angular/core'
import { ConfirmDialogResponses } from '../enums/confirm-dialog-responses.enum'
import { ConstantsPipe } from '../pipes/constants.pipe'
import { MatPaginatorModule } from '@angular/material/paginator'
import { NoDataFoundComponent } from '../../shared/no-data-found/no-data-found.component'
import { MatMenuModule } from '@angular/material/menu'
import { MatChipsModule } from '@angular/material/chips'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatSortModule } from '@angular/material/sort'
import { MatIconModule } from '@angular/material/icon'
import { MatButtonModule } from '@angular/material/button'
import { AsyncPipe } from '@angular/common'
import { GenericConstants } from '../constants/generic.constants'
import { FormModes } from '../enums/form-modes.enum'
import { Ability } from '@casl/ability'
import { Files } from '../interfaces/library/file.interface'
import { FileUrlService } from '../services/others/file-url.service'
import { FileUrl } from '../interfaces/library/file-url.interface'
import { AppFileViewerConfig } from 'app/shared/app-file-viewer/app-file-viewer-config.interface'
import { AppFileViewerComponent } from 'app/shared/app-file-viewer/app-file-viewer.component'
import { EntityFilesService } from './services/entity-files.service'

export const loader = GenericConstants.APP_LANGUAGES.reduce((acc, lang) => {
  acc[lang] = () => import(`./i18n/${lang}.json`)
  return acc
}, {})

@Component({
  selector: 'app-library',
  templateUrl: './library.component.html',
  styleUrls: ['./library.component.scss'],
  standalone: true,
  imports: [
    TranslocoModule,
    MatButtonModule,
    MatIconModule,
    MatSortModule,
    MatTooltipModule,
    MatChipsModule,
    MatMenuModule,
    NoDataFoundComponent,
    MatPaginatorModule,
    AsyncPipe,
    ConstantsPipe,
    AppFileViewerComponent,
  ],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'LibraryComponent',
        alias: 'libcom',
        loader,
      },
      multi: true,
    },
  ],
})
export class LibraryComponent extends ListBaseComponent<EntityFile> implements ListBase, OnChanges {
  @Input() entityGuid: string
  @Input() externalGuid: string
  @Input() temporary: boolean
  @Input() isEditable: boolean = false
  @Input() showAddButton: boolean = true

  @Output() pendingRequired = new EventEmitter<number>()
  @Output() caslSubjectEmit: EventEmitter<any> = new EventEmitter()

  configList: ConfigList = {
    columns: ['id', 'fileTypeName', 'fileTypeDescription', 'maxSize', 'required'],
    filters: ['fileTypeName'],
    expands: ['File', 'Extensions($select=name)'],
    defaultSort: 'fileTypeName',
    defaultOrder: 'asc',
  }

  private _configFileForm: ConfigFileForm = {
    title: '',
    help: '',
    formatsAllowed: '.jpg',
    entityFileTypeId: 0,
    entityGuid: '',
    externalEntity: '',
    temporary: false,
  }

  private _pendingRequiredFiles: number

  constructor(
    protected _appAlertService: AppAlertService,
    protected _translocoService: TranslocoService,
    protected _filesService: FilesService,
    private _matDialog: MatDialog,
    public genericUtil: GenericUtil,
    protected _ability: Ability,
    private _entityFilesService: EntityFilesService,
    private _fileUrlService: FileUrlService,
  ) {
    super(_entityFilesService)
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------
  ngOnDestroy(): void {
    this.baseNgOnDestroy()
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.externalGuid) {
      this.configList.otherParams = [
        `entityGuid=${this.entityGuid}`,
        `externalGuid=${this.externalGuid}`,
        'optional=false',
      ]
      this.baseNgOnInit(this.configList, 'libcom', 'male')

      if (changes?.isEditable?.currentValue) {
        this.getPendingRequiredFiles()
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get pending required files
   */
  get pendingRequiredFiles(): number {
    return this._pendingRequiredFiles
  }

  /**
   * Set pending required files
   */
  set pendingRequiredFiles(numberFiles: number) {
    this._pendingRequiredFiles = numberFiles
    this.pendingRequired.emit(this._pendingRequiredFiles)
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Open dialog for load a specific file
   * @param item
   * @returns
   */
  openRequiredLoadForm(item: EntityFile) {
    if (item.extensions.length === 0) {
      this._appAlertService.showError('No se puede cargar el archivo ya que no hay definida ninguna extensión válida.')
      return
    }

    this._configFileForm.title = item.fileTypeName
    this._configFileForm.help = item.fileTypeDescription
    this._configFileForm.maxSize = item.maxSize
    this._configFileForm.entityFileTypeId = item.id
    this._configFileForm.entityGuid = this.entityGuid
    this._configFileForm.externalEntity = this.externalGuid
    this._configFileForm.temporary = this.temporary

    let formatsAllowed: string = ''
    let counter: number = 0

    for (let extension of item.extensions) {
      if (counter > 0) {
        formatsAllowed = formatsAllowed.concat(',')
      }

      formatsAllowed = formatsAllowed.concat('.').concat(extension.name)
      counter++
    }

    this._configFileForm.formatsAllowed = formatsAllowed

    // Merge the user config with the default config
    const userConfig = merge({}, this._configFileForm)

    // Open the dialog
    let dialogRef = this._matDialog
      .open(LibraryFileComponent, {
        autoFocus: false,
        disableClose: true,
        data: userConfig,
        panelClass: ['fuse-confirmation-dialog-panel', 'app-modal-panel-small'],
      })
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (response: any) => {
          if (response != ConfirmDialogResponses.Cancelled) {
            item.file = response
            this.getPendingRequiredFiles()
          }
        },
      })
  }

  /**
   * Open dialog for load a specific file
   * @param item
   * @returns
   */
  openOptionalLoadForm() {
    this._translocoService
      .selectTranslate('libcom.item-name')
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: translate => {
          this._configFileForm.title = translate
        },
      })

    this._configFileForm.help = ''
    this._configFileForm.entityGuid = this.entityGuid
    this._configFileForm.externalEntity = this.externalGuid
    this._configFileForm.temporary = this.temporary
    this._configFileForm.entityFileTypeId = null

    // Merge the user config with the default config
    const userConfig = merge({}, this._configFileForm)

    // Open the dialog
    let dialogRef = this._matDialog
      .open(LibraryFileComponent, {
        autoFocus: false,
        disableClose: true,
        data: userConfig,
        panelClass: 'app-modal-panel-small',
      })
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (response: AppFile | string) => {
          if (response != ConfirmDialogResponses.Cancelled) {
            this._loadItemsPage()
            this.getPendingRequiredFiles()
          }
        },
      })
  }

  /**
   *
   * @param file
   */
  viewFile(file: Files) {
    this._fileUrlService
      .getUrl(file.guid)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (response: FileUrl[]) => {
          if (response.length) {
            const fileViewerConfig: MatDialogConfig<AppFileViewerConfig> = {
              data: {
                title: file.name,
                fileUrl: response[0],
                extension: response[0].extensionName,
              },
              panelClass: 'app-modal-panel-full',
            }

            // Open the dialog
            let dialogRef = this._matDialog
              .open(AppFileViewerComponent, fileViewerConfig)
              .afterClosed()
              .pipe(takeUntil(this._unsubscribeAll))
              .subscribe()
          }
        },
      })
  }

  /**
   * Save file
   * @param appFile
   */
  saveFile(file: Files) {
    this._filesService.saveFile(
      file.guid,
      `${file.name}.${file.extensionName}`,
      this._translocoService.translate('generic-messages.messages.save-finished-file'),
    )
  }

  /**
   * Get pendind required files to upload
   */
  getPendingRequiredFiles() {
    const itemQueryOptions: SystemQueryOptions = {
      select: 'id',
      expand: 'file',
      filter: 'required eq true and file eq null',
      count: true,
      top: 0,
      otherParams: `entityGuid=${this.entityGuid}&externalGuid=${this.externalGuid}&optional=false`,
    }

    this._entityFilesService
      .getPendingRequired(itemQueryOptions)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: response => {
          this.pendingRequiredFiles = response['@odata.count']
        },
      })
  }

  /**
   * Delete item
   * @param item
   */
  protected _deleteItem(item: EntityFile) {
    this._filesService
      .delete(item.file)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: response => {
          this._appAlertService.showSuccess(
            this._translocoService.translate('generic-messages.messages.success-erase', {
              gender: this.itemGenre,
              name: this.itemName,
            }),
          )
          this._loadItemsPage()
          this.getPendingRequiredFiles()
        },
      })
  }

  /**
   * Allows to configure the action to be performed with the form that will be opened in the modal
   * @param formMode
   * @param itemId
   */
  runAction(formMode: FormModes, itemId?: number) {
    switch (formMode) {
      case FormModes.Create:
        this.openOptionalLoadForm()
        break
    }
  }
}
