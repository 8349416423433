import { Injectable, NgZone, TemplateRef } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@jsverse/transloco';
import { Observable, timer } from 'rxjs';
import { AppSnackBarComponent } from '../../../shared/app-snack-bar/app-snack-bar.component';
import { AppConfirmationConfig } from 'app/shared/app-confirmation/app-confirmation.types';
import { AppConfirmationService } from 'app/shared/app-confirmation/app-confirmation.service';

@Injectable({
  providedIn: 'root'
})
export class AppAlertService {
  constructor(
    public snackBar: MatSnackBar,
    private _appConfirmationService: AppConfirmationService,
    private _ngZone: NgZone,
    private _translocoService: TranslocoService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Snack bar types
  // -----------------------------------------------------------------------------------------------------

  /**
   * Show success snack bar
   * @param message
   */
  showSuccess(message: string): void {
    this._openSnackBar(message, 'success');
  }

  /**
   * Show info snack bar
   * @param message
   */
  showInfo(message: string): void {
    this._openSnackBar(message, 'info');
  }

  /**
   * Show warning snack bar
   * @param message
   */
  showWarning(message: string): void {
    this._openSnackBar(message, 'warning');
  }

  /**
   * Show error snack bar
   * @param message
   */
  showError(message: string): void {
    this._openSnackBar(message, 'error');
  }

  /**
   * Close snack bar
   */
  closeSnackBar() {
    timer(1).subscribe((next) => {
      this.snackBar.dismiss();
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Confirmation dialog types with accept and cancel options
  // -----------------------------------------------------------------------------------------------------

  /**
   * Show warning confirm dialog with accept and cancel options
   * @param title
   * @param messages
   * @returns
   */
  showInfoConfirm(title: string, messages: string[], customClass?: string): Observable<any> {
    return this._openConfirmDialog(
      title,
      messages,
      'info',
      true,
      this._translocoService.translate('buttons.labels.ok')
    );
  }

  /**
   * Show warning confirm dialog with accept and cancel options
   * @param title
   * @param messages
   * @returns
   */
  showWarningConfirm(title: string, messages: string[]): Observable<any> {
    return this._openConfirmDialog(
      title,
      messages,
      'warning',
      true,
      this._translocoService.translate('buttons.labels.ok')
    );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Confirmation dialog types with only accept option
  // -----------------------------------------------------------------------------------------------------

  /**
   * Show error confirm dialog
   * @param title
   * @param messages
   * @returns
   */
  showErrorDialog(title: string, messages: string[]): Observable<any> {
    return this._openConfirmDialog(title, messages, 'error');
  }

  /**
   * Show info confirm dialog
   * @param title
   * @param messages
   * @returns
   */
  showInfoDialog(title: string, messages: string[]): Observable<any> {
    return this._openConfirmDialog(title, messages, 'info');
  }

  /**
   * Show warning confirm dialog with accept and cancel options
   * @param title
   * @param messages
   * @param customClass
   * @returns
   */
  customDialog(title: string, messages: string[], panelClass?: string, customTemplate?: any): Observable<any> {
    return this._openConfirmDialog(
      title,
      messages,
      'info',
      true,
      this._translocoService.translate('buttons.labels.ok'),
      this._translocoService.translate('buttons.labels.cancel'),
      panelClass,
      customTemplate
    );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Open app snack bar
   * @param message
   * @param type success | info | warn | error
   * @param duration
   * @param verticalPosition
   * @param horizontalPosition
   */
  private _openSnackBar(
    message: string,
    type: 'success' | 'info' | 'warning' | 'error',
    duration?,
    verticalPosition?,
    horizontalPosition?
  ) {
    this._ngZone.run(() => {
      const _snackType = type !== undefined ? type : 'success';
      this.snackBar.openFromComponent(AppSnackBarComponent, {
        duration: duration || 8000,
        horizontalPosition: horizontalPosition || 'right',
        verticalPosition: verticalPosition || 'top',
        panelClass: ['app-snack-bar'],
        data: { message: message, snackType: _snackType, snackBar: this.snackBar }
      });
    });
  }

  /**
   * Open confirm dialog
   * @param title
   * @param messages
   * @param type error | warning
   * @param confirmLabel
   * @param cancelLabel
   * @returns
   */
  private _openConfirmDialog(
    title: string,
    messages: string[],
    type: 'info' | 'error' | 'warning',
    showCancelButton?: boolean,
    confirmLabel?: string,
    cancelLabel?: string,
    panelClass?: string,
    customTemplate?: TemplateRef<any>
  ): Observable<any> {
    return this._ngZone.run(() => {
      let iconName: string = '';
      let iconColor: 'primary' | 'accent' | 'warn' | 'basic' | 'info' | 'success' | 'warning' | 'error';
      let confirmColor: 'primary' | 'accent' | 'warn' | 'warning' | 'info';

      switch (type) {
        case 'info':
          iconName = 'mat_solid:info';
          iconColor = 'info';
          confirmColor = 'info';
          break;
        case 'error':          
          iconName = 'mat_solid:report_gmailerrorred';
          iconColor = 'error';
          confirmColor = 'warn';
          break;
        case 'warning':
          iconName = 'heroicons_solid:exclamation-triangle';
          iconColor = 'warning';
          confirmColor = 'warning';
          break;
        default:
          iconName = 'mat_solid:info';
          iconColor = 'info';
          confirmColor = 'info';
          break;
      }

      const config: AppConfirmationConfig = {
        title: title,
        message:
          messages.length > 1
            ? '<ul class="list-disc mt-3"><li>'.concat(messages.join('</li><li>'), '</li></ul>')
            : '<div class="mt-3">'.concat(messages[0], '</div>'),
        icon: {
          show: true,
          name: iconName,
          color: iconColor
        },
        actions: {
          confirm: {
            show: true,
            label: confirmLabel ? confirmLabel : this._translocoService.translate('buttons.labels.ok'),
            color: confirmColor
          },
          cancel: {
            show: showCancelButton ? showCancelButton : false,
            label: cancelLabel ? cancelLabel : this._translocoService.translate('buttons.labels.cancel')
          }
        },
        panelClass: panelClass ? panelClass : 'app-modal-panel-small',
        customTemplate: customTemplate ? customTemplate : null
      };

      const dialogRef = this._appConfirmationService.open(config);

      return dialogRef.afterClosed();
    });
  }
}
