import { Component, Input, OnInit } from '@angular/core';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@jsverse/transloco';
import { GenericConstants } from 'app/core/constants/generic.constants';

export const loader = GenericConstants.APP_LANGUAGES.reduce((acc, lang) => {
  acc[lang] = () => import(`./i18n/${lang}.json`);
  return acc;
}, {});

@Component({
  selector: 'app-no-data-found',
  templateUrl: './no-data-found.component.html',
  styleUrls: ['./no-data-found.component.scss'],
  standalone: true,
  imports: [TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'NoDataFoundComponent',
        alias: 'ndfc',
        loader
      },
      multi: true
    }
  ]
})
export class NoDataFoundComponent implements OnInit {
  @Input() title?: string;
  @Input() message?: string;

  constructor() {}

  ngOnInit(): void {}
}
