import { NgFor, NgIf } from '@angular/common'
import { Component, ViewEncapsulation } from '@angular/core'
import { AbstractControl, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatTooltipModule } from '@angular/material/tooltip'
import { FormlyModule } from '@ngx-formly/core'
import { ErrorMessageComponent } from 'app/shared/error-message/error-message.component'
import { BaseCustomFieldComponent } from '../base-custom-field.component'
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { CustomInputNoteComponent } from '../custom-note/custom-note.component'
@Component({
  selector: 'app-custom-date-input',
  standalone: true,
  imports: [
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule,
    MatInputModule,
    MatIconModule,
    MatTooltipModule,
    ErrorMessageComponent,
    NgIf,
    NgxMatTimepickerModule,
    MatDatepickerModule,
    NgFor,
    CustomInputNoteComponent,
  ],
  templateUrl: './custom-date-input.component.html',
  styleUrl: './custom-date-input.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class CustomDateInputComponent extends BaseCustomFieldComponent {
  public showErrorDate(type: string): boolean {
    const control = this.field['fieldGroup'].filter(x => x.props.type == type)[0].formControl
    return control.invalid && control.touched
  }

  errorDateMessage(type: string): string {
    const errors = this.field['fieldGroup'].filter(x => x.props.type == type)[0].formControl.errors
    if (errors) {
      if (errors.required) {
        return this.field['fieldGroup'].filter(x => x.props.type == type)[0].validation?.messages?._required?.toString()
      }
    }
    return ''
  }

  getFormControl(control: AbstractControl): FormControl {
    if (control instanceof FormControl) {
      return control
    } else {
      throw new Error('El control no es una instancia de FormControl')
    }
  }
}
