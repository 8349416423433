import { Component, EventEmitter, Input, Output, SimpleChanges, ViewEncapsulation } from '@angular/core'
import * as mapboxgl from 'mapbox-gl'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import MapboxDraw from '@mapbox/mapbox-gl-draw'
import { LngLatLike } from 'mapbox-gl'
import { AbstractControl, UntypedFormBuilder } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { ErrorUtil } from 'app/core/utils/error.util'
import { GenericUtil } from 'app/core/utils/generic.util'
import { AppAlertService } from 'app/core/services/others/app-alert.service'
import { Ability } from '@casl/ability'
import { FormModes } from 'app/core/enums/form-modes.enum'
import { CommonModule } from '@angular/common'
import { MatIconModule } from '@angular/material/icon'
import { ErrorMessageComponent } from '../error-message/error-message.component'
import { MatFormFieldModule } from '@angular/material/form-field'

@Component({
  selector: 'app-mapBox',
  templateUrl: './mapBox.component.html',
  styleUrls: ['./mapBox.component.scss'],
  standalone: true,
  imports: [CommonModule, MatIconModule, ErrorMessageComponent, MatFormFieldModule],
  encapsulation: ViewEncapsulation.None,
})
export class MapBoxComponent {
  @Input() areaId: number
  @Output() changeStepCompleted: EventEmitter<string> = new EventEmitter()

  @Input() showGeocoder: boolean = true
  @Input() showDrawControl: boolean = true
  @Input() showStyleSwitcherControl: boolean = true
  @Input() showGeolocateControl: boolean = true
  @Input() showNavigationControl: boolean = true
  @Input() showMarkerControl: boolean = false
  @Input() drawnPolygons: any[] = []
  @Input() locationInit: LngLatLike = [-75.23222, 4.43889]
  @Input() formModes: FormModes = FormModes.Create
  @Input() placeholderSearch: string = ''
  @Input() label: string
  @Input() control: AbstractControl

  @Output() drawnPolygon: EventEmitter<any> = new EventEmitter()
  @Output() markerCoordinates: EventEmitter<any> = new EventEmitter()

  map: mapboxgl.Map
  draw: MapboxDraw
  addingMarker = false
  @Input() markerCoordinatesValue: any[] = []
  addingPinter = false

  marker = new mapboxgl.Marker()

  mapImageUrl: string

  constructor(
    protected _formBuilder: UntypedFormBuilder,
    protected _router: Router,
    protected _route: ActivatedRoute,
    public errorUtil: ErrorUtil,
    protected _genericUtil: GenericUtil,
    protected _appAlertService: AppAlertService,
    protected _ability: Ability,
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['formModes']) {
      if (this.formModes === FormModes.Create) {
        navigator.geolocation.getCurrentPosition(
          position => {
            const userLocation = [position.coords.longitude, position.coords.latitude]
            this.initMap(userLocation)
          },
          error => {
            // Set Ibague as initial position on the map
            this.initMap(this.locationInit)
          },
        )
      } else {
        if (this.drawnPolygons.length > 0) {
          this.drawnPolygons = this.drawnPolygons[0].geometry
            ? this.drawnPolygons[0].geometry.coordinates[0]
            : this.drawnPolygons
          this.initMap(this.drawnPolygons.length > 0 ? this.drawnPolygons[0] : this.locationInit)
        }

        if (this.markerCoordinatesValue.length > 0) {
          const location = { lng: this.markerCoordinatesValue[0], lat: this.markerCoordinatesValue[1] }
          this.initMap(location, 17)
        }
        if (!(this.drawnPolygons.length > 0) && !(this.markerCoordinatesValue.length > 0)) {
          this.initMap(this.locationInit, 12)
        }
      }
    }
  }

  initMap(location?, zoom?) {
    ;(mapboxgl as any).accessToken =
      'pk.eyJ1IjoiZGllZ28tdmlsbGVnYXMiLCJhIjoiY2x5N3ZuNmxkMDhiZzJpcG45em10a2MzbCJ9.9PwDgeHaD7A8SA_wHt35qQ'
    const setLocation = location as LngLatLike
    // Set initial config map
    this.map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/satellite-streets-v11', // Style Initial map
      center: setLocation, // Initial position [lng, lat]
      zoom: zoom ? zoom : 10,
    })

    if (this.showGeocoder) {
      // Add Search Control
      this.addGeocoder()
    }

    if (this.showStyleSwitcherControl) {
      this.StyleSwitcherControl()
    }

    if (this.showGeolocateControl) {
      this.map.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true,
          },
          trackUserLocation: true,
        }),
      )
    }

    if (this.showDrawControl) {
      this.addDrawControl()
    }

    if (this.showNavigationControl) {
      // Add zoom and rotation controls
      this.map.addControl(new mapboxgl.NavigationControl(), 'bottom-right')
    }

    if (this.showMarkerControl) {
      this.addMarkerControl(this.marker)
    }
    if (this.markerCoordinatesValue.length > 0) {
      this.setMarker({ lng: this.markerCoordinatesValue[0], lat: this.markerCoordinatesValue[1] })
    }

    this.addExportControl()
  }

  addGeocoder() {
    /* Given a query in the form "lng, lat" or "lat, lng"
     * returns the matching geographic coordinate(s)
     * as search results in carmen geojson format,*/
    const coordinatesGeocoder = query => {
      /*Match anything which looks like
      decimal degrees coordinate pair.*/
      const matches = query.match(/^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i)
      if (!matches) {
        return null
      }

      function coordinateFeature(lng, lat) {
        return {
          center: [lng, lat],
          geometry: {
            type: 'Point',
            coordinates: [lng, lat],
          },
          place_name: 'Lat: ' + lat + ' Lng: ' + lng,
          place_type: ['coordinate'],
          properties: {},
          type: 'Feature',
        }
      }

      const coord1 = Number(matches[1])
      const coord2 = Number(matches[2])
      const geocodes = []

      if (coord1 < -90 || coord1 > 90) {
        // must be lng, lat
        geocodes.push(coordinateFeature(coord1, coord2))
      }

      if (coord2 < -90 || coord2 > 90) {
        // must be lat, lng
        geocodes.push(coordinateFeature(coord2, coord1))
      }

      if (geocodes.length === 0) {
        // else could be either lng, lat or lat, lng
        geocodes.push(coordinateFeature(coord1, coord2))
        geocodes.push(coordinateFeature(coord2, coord1))
      }

      return geocodes
    }

    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl['accessToken'],
      mapboxgl: mapboxgl,
      localGeocoder: coordinatesGeocoder,
      placeholder: this.placeholderSearch,
      marker: false,
    })

    this.map.addControl(geocoder, 'top-left')

    geocoder.on('result', event => {
      const coordinates = event.result.geometry.coordinates
      this.setMarker({ lng: coordinates[0], lat: coordinates[1] })
    })
  }

  addDrawControl() {
    this.draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        trash: true,
      },
      defaultMode: 'draw_polygon',
    })
    this.map.addControl(this.draw)
    if (this.formModes !== FormModes.Create) {
      this.addPolygon(this.drawnPolygons)
    }

    // Handle polygon creation event
    this.map.on('draw.create' as any, event => {
      const polygon = event.features[0]
      this.drawnPolygons.push(polygon)
      this.drawnPolygon.emit(this.drawnPolygons[0].geometry.coordinates[0])
    })
    // Handle polygon creation event
    this.map.on('draw.update' as any, event => {
      this.drawnPolygons = []
      const polygon = event.features[0]
      this.drawnPolygons.push(polygon)
      this.drawnPolygon.emit(this.drawnPolygons[0].geometry.coordinates[0])
    })
    // Handle polygon creation event
    this.map.on('draw.delete' as any, event => {
      const polygon = event.features[0]
      this.drawnPolygons = []
      this.drawnPolygon.emit()
    })

    this.map.on('mousedown' as any, event => {
      // Validate if a polygon is already drawn to lock the draw polygon
      if (this.drawnPolygons[0] && this.draw.getMode() === 'draw_polygon') {
        // Change draw mode to lock draw polygon
        this.draw.changeMode('simple_select')
      }
    })
  }

  addPolygon(areaCoordinates) {
    if (areaCoordinates.length > 0) {
      if (this.formModes === FormModes.Edit) {
        // Add the editable polygon to Mapbox Draw
        this.draw.add({
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [areaCoordinates],
          },
        })
        this.addEditablePolygonAndAdjustZoom(areaCoordinates)
      } else {
        // Add the locked polygon to Mapbox Draw
        this.map.on('load', () => {
          this.map.addSource('polygon', {
            type: 'geojson',
            data: {
              type: 'Feature',
              geometry: {
                type: 'Polygon',
                coordinates: [areaCoordinates],
              },
            } as any,
          })

          // Add a layer to the map to display the polygon
          this.map.addLayer({
            id: 'polygon',
            type: 'fill',
            source: 'polygon',
            layout: {},
            paint: {
              'fill-color': '#91ae24',
              'fill-opacity': 0.8,
            },
          })

          // Add a border to the polygon
          this.map.addLayer({
            id: 'outline',
            type: 'line',
            source: 'polygon',
            layout: {},
            paint: {
              'line-color': '#713f12',
              'line-width': 2,
            },
          })
        })
        this.addEditablePolygonAndAdjustZoom(areaCoordinates)
      }
    }
  }

  addEditablePolygonAndAdjustZoom(areaCoordinates) {
    // Calculate the polygon boundaries
    const bounds = new mapboxgl.LngLatBounds()
    areaCoordinates.forEach(coord => {
      bounds.extend(coord)
    })

    // Adjust the map zoom to fit the polygon
    this.map.fitBounds(bounds, {
      padding: 20 as any,
      maxZoom: 18,
    })
  }

  addMarkerControl(marker: any) {
    const markerControl = {
      onAdd: (map: mapboxgl.Map) => {
        const controlDiv = document.createElement('div')
        controlDiv.className = 'mapboxgl-ctrl mapboxgl-ctrl-group mapboxgl-ctrl-custom'
        controlDiv.innerHTML = '<i class="material-icons" id="marker-icon">add_location</i>'
        controlDiv.onclick = () => {
          this.addingMarker = !this.addingMarker
          const icon = document.getElementById('marker-icon')

          if (this.addingMarker) {
            icon.style.color = '#33b5e5'
            this.map.once('click', e => {
              this.setMarker(e.lngLat, icon)
            })
          } else {
            icon.style.color = ''
          }
        }
        return controlDiv
      },
      onRemove: () => {
        // Cleanup if necessary
      },
    }

    this.map.addControl(markerControl, 'top-right')
  }
  setMarker(coordinates, icon?) {
    this.marker.setLngLat(coordinates).addTo(this.map)
    this.markerCoordinates.emit([coordinates])
    this.markerCoordinatesValue = [coordinates.lng, coordinates.lat]
    this.addingMarker = false
    if (icon) {
      icon.style.color = ''
    }
  }

  addExportControl() {
    const exportControl = {
      onAdd: (map: mapboxgl.Map) => {
        const controlDiv = document.createElement('div')
        controlDiv.className = 'mapboxgl-ctrl mapboxgl-ctrl-group mapboxgl-ctrl-custom'

        const button = document.createElement('button')
        button.className = 'mapboxgl-ctrl-icon'
        button.innerHTML = '<i class="material-icons" id="print-icon">print</i>'

        const menu = document.createElement('div')
        menu.className = 'export-menu'
        menu.style.display = 'none'

        menu.appendChild(this.createButton('CSV', this.exportToCSV.bind(this), menu))
        menu.appendChild(this.createButton('KML', this.exportToKML.bind(this), menu))
        menu.appendChild(this.createButton('PNG', this.exportMap.bind(this), menu))

        controlDiv.appendChild(button)
        controlDiv.appendChild(menu)

        button.onclick = () => {
          controlDiv.style.display = controlDiv.style.display === 'block' ? 'flex' : 'block'
          button.style.display = button.style.display === 'block' ? 'flex' : 'block'
          menu.style.display = menu.style.display === 'none' ? 'block' : 'none'
        }

        return controlDiv
      },
      onRemove: () => {
        // Cleanup if necessary
      },
    }

    this.map.addControl(exportControl, 'top-right')
  }
  createButton(text: string, action: () => void, menu: HTMLElement): HTMLButtonElement {
    const btn = document.createElement('button')
    btn.textContent = text
    btn.onclick = () => {
      menu.style.display = 'none'
      action()
    }
    return btn
  }

  StyleSwitcherControl() {
    const switcherControl = {
      onAdd(map: mapboxgl.Map) {
        this.container = document.createElement('div')
        this.container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group'
        const styles = [
          { label: 'Streets', url: 'mapbox://styles/mapbox/streets-v11' },
          { label: 'Outdoors', url: 'mapbox://styles/mapbox/outdoors-v11' },
          { label: 'Light', url: 'mapbox://styles/mapbox/light-v10' },
          { label: 'Dark', url: 'mapbox://styles/mapbox/dark-v10' },
          { label: 'Satellite', url: 'mapbox://styles/mapbox/satellite-v9' },
          { label: 'Satellite Streets', url: 'mapbox://styles/mapbox/satellite-streets-v11' },
        ]

        const styleSelector = document.createElement('select')
        styleSelector.className = 'style-selector'

        styles.forEach(style => {
          const option = document.createElement('option')
          option.value = style.url
          option.text = style.label
          styleSelector.appendChild(option)

          // Set default value of the selector (5 = Satellite Streets)
          styleSelector.selectedIndex = 5
        })
        styleSelector.value

        styleSelector.addEventListener('change', (event: any) => {
          map.setStyle(event.target.value)
        })

        this.container.appendChild(styleSelector)
        return this.container
      },

      onRemove() {
        this.container.parentNode.removeChild(this.container)
      },
    }
    this.map.addControl(switcherControl, 'top-right')
  }

  exportMap() {
    const center = this.map.getCenter()
    const zoom = this.map.getZoom()
    const style = 'mapbox/satellite-streets-v11'
    const width = 1000
    const height = 500
    const accessToken = (mapboxgl as any).accessToken
    const polygon: any = {
      type: 'FeatureCollection',
      features: [],
    }

    if (this.drawnPolygons.length > 0) {
      polygon.features.push({
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [this.drawnPolygons],
        },
        properties: {
          /* OPTIONAL: default "555555"
          *the color of a line as part of a polygon, polyline, or multigeometry
          *value must follow COLOR RULES */
          stroke: '#713f12',

          /* OPTIONAL: default 1.0
          *the opacity of the line component of a polygon, polyline, or multigeometry
          *value must be a floating point number greater than or equal to
          *zero and less or equal to than one */ 
          'stroke-opacity': 1.0,

         /* OPTIONAL: default 2
          *the width of the line component of a polygon, polyline, or multigeometry
          *value must be a floating point number greater than or equal to 0*/
          'stroke-width': 4,

          /* OPTIONAL: default "555555"
          *the color of the interior of a polygon
          *value must follow COLOR RULES */
          fill: '#91ae24',

          /* OPTIONAL: default 0.6
          the opacity of the interior of a polygon. 
          * Implementations
          * may choose to set this to 0 for line features.
          * value must be a floating point number greater than or equal to
          * zero and less or equal to than one */
          'fill-opacity': 0.9,
        },
      })
    }
    if (this.markerCoordinatesValue.length > 0) {
      polygon.features.push({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: this.markerCoordinatesValue,
        },
        properties: {
          /* OPTIONAL: default "medium"
          specify the size of the marker. sizes
          can be different pixel sizes in different
          implementations
          Value must be one of
          * "small"
          * "medium"
          * "large" */
          'marker-size': 'medium',

          /* OPTIONAL: default "7e7e7e"
          * the marker's color
          * value must follow COLOR RULES*/
          'marker-color': '#33b5e5',
        },
      })
    }
    let geoJson = ''
    if (polygon.features.length > 0) {
      geoJson = encodeURIComponent(JSON.stringify(polygon))
    }

    this.mapImageUrl = `https://api.mapbox.com/styles/v1/${style}/static/${geoJson ? `geojson(${geoJson})/` : ''}${
      center.lng
    },${center.lat},${zoom}/${width}x${height}?access_token=${accessToken}`
    this.downloadImage(this.mapImageUrl, 'map-export.png')
  }

  downloadImage(url: string, filename: string) {
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const downloadUrl = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = downloadUrl
        a.download = filename
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(downloadUrl)
      })
      .catch(() => alert('Failed to download image.'))
  }

  exportToCSV() {
    const data = this.draw.getAll()

    const csvContent = this.convertGeoJSONToCSV(data)
    this.downloadFile(csvContent, 'text/csv;charset=utf-8;', 'map-data.csv')
  }

  convertGeoJSONToCSV(data: any): string {
    let csv = 'type,coordinates\n'
    data.features.forEach(feature => {
      const coordinates = feature.geometry.coordinates[0].map(coord => coord.join(',')).join(' ')
      csv += `${feature.geometry.type},"${coordinates}"\n`
    })
    return csv
  }

  exportToKML() {
    const data = this.draw.getAll()
    const kmlContent = this.convertGeoJSONToKML(data)
    this.downloadFile(kmlContent, 'application/vnd.google-earth.kml+xml;charset=utf-8;', 'map-data.kml')
  }

  convertGeoJSONToKML(data: any): string {
    let kml = `<?xml version="1.0" encoding="UTF-8"?>
        <kml xmlns="http://www.opengis.net/kml/2.2">
        <Document>
            <name>Map Data</name>`

    data.features.forEach(feature => {
      const coordinates = feature.geometry.coordinates[0].map(coord => coord.join(',')).join(' ')
      kml += `
            <Placemark>
            <Polygon>
                <outerBoundaryIs>
                <LinearRing>
                    <coordinates>${coordinates}</coordinates>
                </LinearRing>
                </outerBoundaryIs>
            </Polygon>
            </Placemark>`
    })

    kml += `
        </Document>
        </kml>`
    return kml
  }

  downloadFile(content: string, mimeType: string, filename: string) {
    const blob = new Blob([content], { type: mimeType })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.style.display = 'none'
    a.href = url
    a.download = filename
    document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(url)
    document.body.removeChild(a)
  }
}
