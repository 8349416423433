import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms'
import { BooleanFunction } from '../interfaces/others/boolean-function.interface'
import moment from 'moment'

export class GenericValidators {
  static alphabetical(control: AbstractControl): ValidationErrors | null {
    if (control.value === '') {
      return null
    }

    const pattern: RegExp = new RegExp('^[A-Za-zäÄëËïÏöÖüÜáéíóúáéíóúÁÉÍÓÚñÑ ]*$')
    const isValid: boolean = pattern.test(control.value)

    if (isValid) {
      return null
    } else {
      return { alphabetical: true }
    }
  }

  static alphanumerical(control: AbstractControl): ValidationErrors | null {
    if (control.value === '') {
      return null
    }

    const pattern: RegExp = new RegExp('^[0-9A-Za-zäÄëËïÏöÖüÜáéíóúáéíóúÁÉÍÓÚñÑ ]*$')
    const isValid: boolean = pattern.test(control.value)

    if (isValid) {
      return null
    } else {
      return { alphanumerical: true }
    }
  }

  static numerical(control: AbstractControl): ValidationErrors | null {
    if (control.value === '') {
      return null
    }

    const pattern: RegExp = new RegExp('^[0-9]*$')
    const isValid: boolean = pattern.test(control.value)

    if (isValid) {
      return null
    } else {
      return { numerical: true }
    }
  }

  static password(control: AbstractControl): ValidationErrors | null {
    if (control.value === '') {
      return null
    }

    const pattern: RegExp = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!#$%&\'()*+,-./:;<=>?@\\[\\]^_`{|}~¡¿¢£¥€¬°]).{8,}$')
    const isValid: boolean = pattern.test(control.value)

    if (isValid) {
      return null
    } else {
      return { password: true }
    }
  }

  static phoneNumber(control: AbstractControl): ValidationErrors | null {
    if (control.value === '') {
      return null
    }

    const pattern: RegExp = new RegExp('^(\\+?\\d{1,4}[\\s-])?(?!0+\\s+,?$)\\d{7,10}\\s*,?$')
    const isValid: boolean = pattern.test(control.value)

    if (isValid) {
      return null
    } else {
      return { phonenumber: true }
    }
  }

  /**
   * Validates if the value of a control is an object
   * @param control
   * @returns
   */
  static object(control: AbstractControl): ValidationErrors | null {
    if (typeof control.value === 'object' || control.value === '') {
      return null /* valid option selected */
    } else {
      return { object: true }
    }
  }

  /**
   * Conditional Validation
   * @param predicate Boolean function that indicates whether or not validation should be applied
   * @param validator Validation to apply
   * @returns
   */
  static conditional(predicate: BooleanFunction, validator: ValidatorFn): ValidationErrors | null {
    return formControl => {
      if (!formControl.parent) {
        return null
      }

      let error = null

      if (predicate()) {
        error = validator(formControl)
      }

      /*
            if (errorNamespace && error) {

                const customError = {};
                customError[errorNamespace] = error;
                error = customError
            }
            */

      return error
    }
  }

  static url(control: AbstractControl): ValidationErrors | null {
    if (control.value === '') {
      return null
    }

    const pattern: RegExp = new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/)
    const isValid: boolean = pattern.test(control.value)

    if (isValid) {
      return null
    } else {
      return { url: true }
    }
  }
  static isAdult(control: AbstractControl): ValidationErrors | null {
    if (control.value === '') {
      return null
    }

    const birthday = moment(control.value, 'DD-MM-YYYY')
    var years = moment().diff(birthday, 'years')
    return years < 18 ? { isAdult: true } : null
  }

  static dateRangeValidator(control1: AbstractControl, control2: AbstractControl): ValidationErrors | null {
    if (control1.value === '' || control2.value === '') {
      return null
    }

    const date1Value = moment(control1.value, 'DD-MM-YYYY')
    const date2Value = moment(control2.value, 'DD-MM-YYYY')

    if (control1.value && date1Value < control1.value) {
      return { dateRangeError: true }
    }

    if (control2.value && date2Value > control2.value) {
      return { dateRangeError: true }
    }
  }
  static customMaxValidatorWithDecimal(minFieldControlName: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const maxValue = control.value

      const minControl = control.root.get(minFieldControlName)
      const minValue = minControl ? minControl.value : null

      const numericMaxValue = maxValue ? parseFloat(maxValue.toString().replace(',', '.')) : null
      const numericMinValue = minValue ? parseFloat(minValue.toString().replace(',', '.')) : null

      if (!isNaN(numericMaxValue) && !isNaN(numericMinValue)) {
        let requiredMinValue: number

        requiredMinValue = parseFloat((Math.floor(numericMinValue * 10) / 10 + 0.01).toFixed(2))

        if (numericMaxValue < requiredMinValue) {
          return { min: { min: requiredMinValue, actual: numericMaxValue } }
        }
      }

      return null
    }
  }
}
