<ng-container *transloco="let t">
  <div class="app-modal-container">
    <!-- Title -->
    <ng-container>
      <div class="app-modal-header">
        <div class="app-modal-title">{{ data.title }}</div>
      </div>
    </ng-container>

    <!-- Content -->
    <div class="app-modal-form-container" mat-dialog-content>
      <div class="app-form-field-group">
        <div class="app-radio-group col-span-1 md:col-span-2">
          <div class="flex justify-center mt-5">
            <!-- doc wiewer -->
            @if (showFileViewer) {
            <app-doc-viewer [url]="data.fileUrl.url" viewer="google" style="width: 100%; height: 50vh">
            </app-doc-viewer>
            }

            <!-- image viewer -->
            @if (showImageViewer) {
            <div class="flex justify-center items-center p-4">
              <img [src]="data.fileUrl.url" alt="Imagen" class="max-w-full max-h-full" />
            </div>
            }

            <!-- audio player -->
            @if (showAudioPlayer) {
            <audio autoplay controls [src]="data.fileUrl.url"></audio>
            }

            <!-- video player -->
            @if (showVideoPlayer) {
            <video [src]="data.fileUrl.url" width="480" height="360" controls autoplay>
              Your browser does not support the element <code>video</code>.
            </video>
            }
          </div>
        </div>
      </div>
    </div>

    <!-- Actions -->
    <div class="app-modal-footer">
      <!-- Cancel -->
      <button mat-stroked-button [matDialogClose]="confirmDialogResponses.Cancelled">
        <span class="app-button-text">{{ t('buttons.labels.close') }}</span>
      </button>

      <!-- Download -->
      <button mat-flat-button [color]="'primary'" (click)="saveFile()">
        <span class="app-button-text">{{ t('buttons.labels.download') }}</span>
      </button>
    </div>
  </div>
</ng-container>