export abstract class IdentificatorsConstants {
  /**
   * Generic business unit to access functionalities that do not depend on a business unit.
   */
  static readonly GENERIC_BUSINESS_UNIT_GUID: string = 'ac59da57-3406-499e-a40f-cdfc62973494'

  /**
   * Generic business unit to access functionalities that do not depend on a business unit.
   */
  static readonly EMPTY_GUID: string = '00000000-0000-0000-0000-000000000000'
  static readonly EMPTY_ID: number = -1

  /**
   * Third party type legal id
   */
  static readonly THIRD_PARTY_TYPE_LEGAL: number = 1

  /**
   * Third party type id
   */
  static readonly THIRD_PARTY_TYPE_JURIDICA: number = 1
  static readonly THIRD_PARTY_TYPE_NATURAL: number = 2

  /**
   * Colombia id
   */
  static readonly COUNTRY_ID_COLOMBIA: number = 43

  static readonly clientProviderThirdPartyId: number = 3

  //Magninutes guids
  static readonly MAGNITUDE_MASA_GUID: string = '12e359f7-de57-4b2e-9323-292def942048'
  static readonly MAGNITUDE_LONGITUD_GUID: string = '031685ff-31f7-436c-a09c-8df39ad982ed'
  static readonly MAGNITUDE_SUPERFICIE_GUID: string = 'c098afa3-c00f-4881-9fe2-6783ec747d72'
  static readonly MAGNITUDE_VOLUMEN_GUID: string = 'f1d6eccb-bbf7-4bf6-81b0-bd28d5f2276c'
  static readonly MAGNITUDE_TIEMPO_GUID: string = 'ac6ecd44-f16f-4a64-bb8d-99565c908c98'
  static readonly MAGNITUDE_TIEMPO_DIA_GUID: string = 'a6972925-98a5-4d5a-a7ea-0ca90a5ed47e'
  static readonly MAGNITUDE_UNIDAD_GUID: string = '491ebfe2-8115-4213-8eb4-d61d05ac85ef'
  static readonly MAGNITUDE_UNIDAD_GRAMO_GUID: string = '0ffa03d4-d21d-47db-8569-34348745e289'
  static readonly MAGNITUDE_VOLUMEN_KG_GUID: string = '3d471bd2-62b3-4179-98ab-c2f5a77daac7'
  static readonly MAGNITUDE_CONCENTRATION_GUID: string = '8ff0fe91-aa83-4519-8744-8cf33337dd9b'
  static readonly MAGNITUDE_SUPERFICIE_MT_GUID: string = '3c4e73a6-a1d0-40fa-b2ea-5ad04436ccc4'
  static readonly MAGNITUDE_LONGITUD_MT_GUID: string = '6529f521-557c-4e49-b7bb-7fa8ba360533'
  static readonly MAGNITUDE_VOLUMEN_ML_GUID: string = 'd21ce211-9ddf-4e82-8d44-fbd379954c46'
  static readonly MAGNITUDE_VOLUMEN_L_GUID: string = 'abd6a09b-1760-4fa9-860d-9633a90b2d8a'
  static readonly MAGNITUDE_CAUDAL_GUID: string = 'c19f7dbd-a599-4b3b-a07e-7e518be48144'
  static readonly MAGNITUDE_OTHERS_UNITS_GUID: string = '4736ed65-c1cf-41df-b1a7-f1b922aef874'
  static readonly MAGNITUDE_TEMPERATURE_GUID: string = 'e2ad4bc9-36c3-4b1d-aabe-479c556e33fc'
  static readonly MAGNITUDE_PRECIPITATION_GUID: string = '3cea7655-0ae3-448e-b786-5086074e2c79'

  //Units guid
  static readonly UNIT_PARTS_PER_MILLION_GUID: string = 'bd464650-5b17-4273-ba5e-bc7df461681e'
  static readonly UNIT_HECTARE_GUID: string = 'aed9e90e-7403-490e-bc57-227bdd10f05c'
  static readonly UNIT_SQUARE_METER_GUID: string = '3c4e73a6-a1d0-40fa-b2ea-5ad04436ccc4'
  static readonly UNIT_CUBIC_METER_GUID: string = '17624f2f-c67b-480f-8cde-3e5fad7e8a91'
  static readonly UNIT_DIA_GUID: string = 'a6972925-98a5-4d5a-a7ea-0ca90a5ed47e'
  static readonly UNIT_HORA_GUID: string = 'b365f24f-50ed-44d7-ba47-075d64ce6cb4'
  static readonly UNIT_MINUTOS_GUID: string = '4ad7ceda-fdda-4526-bb3a-7da5731ef227'
  static readonly UNIT_SEGUNDOS_GUID: string = '1b28d549-8d8c-4043-a7f5-52c3b093fa52'
  static readonly UNIT_SEMANA_GUID: string = '3f118c88-b25c-495d-b9dd-bcf8511d54c8'
  static readonly UNIT_MES_GUID: string = '5f5eceb3-8b50-4c83-b05d-16bd5eaf1cca'
  static readonly UNIT_AÑO_GUID: string = 'bc2b620b-8721-4675-8ec9-2dfcfc2a76bc'
  static readonly UNIT_WHEN_DRYING_GUID: string = 'd1a851f7-db1b-4190-a06c-e925880518fd'
  static readonly UNIT_MILLIMETERS_GUID: string = '465a9cd3-775d-4de6-a3f5-f1ac7628e31f'
  static readonly UNIT_INCHES_GUID: string = '58eb3eb5-8fef-4456-bb09-a85360bd317d'

  //Agricultural subsectors guids
  static readonly AGRICULTURAL_SUBSECTOR_AGRICOLA_GUID: string = 'e7cb5cd9-068c-44b7-aac8-09fa82d4114d'
  static readonly AGRICULTURAL_SUBSECTOR_PECUARIO_GUID: string = '8ad55a4c-ee9f-4a0e-ad7c-261ea07d480c'

  //Agricultural subsectors classifications guids
  static readonly AGRICULTURAL_SUBSECTOR_CLASSIFICATION_ALL_GUID: string = '02003700-74e2-4013-a6e6-91d59d049c03'
  static readonly AGRICULTURAL_SUBSECTOR_CLASSIFICATION_MAJOR_SPECIES_GUID: string =
    '8bd83af8-d3c7-4055-990f-c605242809a1'
  static readonly AGRICULTURAL_SUBSECTOR_CLASSIFICATION_MINOR_SPECIES_GUID: string =
    '66e0479a-ef7c-4f32-96d8-45bfec8b1359'
  //static readonly AGRICULTURAL_SUBSECTOR_CLASSIFICATION_PORCINE_GUID: string = '66e0479a-ef7c-4f32-96d8-45bfec8b1359'

  //Agricultural product types
  static readonly AGRICULTURAL_PRODUCT_TYPE_FISHFARM_GUID: string = '30071707-6f91-4115-aaf7-e2b529e17927'
  static readonly AGRICULTURAL_PRODUCT_TYPE_BOVINOES_GUID: string = '0310b62a-8951-47a8-8640-dbce1cf38338'

  //Product types
  static readonly PRODUCT_TYPE_FERTILIZER_GUID: string = 'a271c162-db00-4ed4-ba0c-7575f42ec598'
  static readonly PRODUCT_TYPE_PESTICIDE_GUID: string = 'ddea1613-7e3a-48f3-a207-3788ef01eff9'
  static readonly PRODUCT_TYPE_PROCESSED_PRODUCT_GUID: string = '80b7cfa2-c85c-48d3-9167-e50dd7c8145e'
  static readonly PRODUCT_TYPE_AGRICULTURAL_PRODUCT_GUID: string = '0ca5b0aa-5cdb-4a5e-8b3d-299ed1a9e027'
  static readonly PRODUCT_TYPE_SEED_GUID: string = 'ba2b3332-7bd0-4066-8b09-04d32d342414'
  static readonly PRODUCT_TYPE_OTHER_SUPPLIE_GUID: string = 'aafb347e-94ad-4e1c-b10c-7f3219c7a9f2'
  static readonly PRODUCT_TYPE_MEDICINE_GUID: string = 'cc1b9733-165f-4482-a4ba-f7688ff62002'
  static readonly PRODUCT_TYPE_FOOD_GUID: string = 'be073e50-c215-4e62-a6bf-945a182acbf9'
  static readonly PRODUCT_TYPE_LIVESTOCK_PRODUCT_GUID: string = '83a251b9-3009-4dcd-92b1-a3b42c7137c0'
  static readonly PRODUCT_TYPE_BYPRODUCT_GUID: string = '1f5e109e-606a-4b9d-b800-a2ed3691cc5b'

  // Exploitation types
  static readonly EXPLOITATION_TYPE_FATTENING_GUID: string = 'dab5e7cd-c70d-4784-bd16-2e57944cb684'
  static readonly EXPLOITATION_TYPE_FATTENING_ID: number = 2
  static readonly EXPLOITATION_TYPE_MILK_PRODUCTION_GUID: string = '103d9ea3-e510-4c9e-b372-f6fcc17e5877'
  static readonly EXPLOITATION_TYPE_MILK_PRODUCTION_ID: number = 1

  // Stock document types
  static readonly STOCK_DOCUMENT_TYPE_COMPRA: string = 'c56832b5-f54b-44fd-ab08-ffeea4a7beb9'
  static readonly STOCK_DOCUMENT_TYPE_VENTA: string = '3fd704ca-ad6d-48c5-ab57-c95fc018df9c'

  // Area types
  static readonly AREA_TYPE_WAREHOUSE: string = 'c7dfb1ff-81cb-464e-8f55-9a7bd3dade91'

  // Device types
  static readonly DEVICE_TYPE_ACTUATOR: string = '0161662f-543b-4c8f-8325-41706b0bad02'
  static readonly DEVICE_TYPE_ACTUATOR_SENSOR: string = '94bb6719-bf67-45d8-9620-5ff122e0d0d1'
  static readonly DEVICE_TYPE_SENSOR: string = '380462f4-e32b-42ce-8fc1-f4f6ce03b677'
  static readonly DEVICE_TYPE_CONTROLLER: string = '3ec05800-a1bd-4bd7-a827-5e323400b04e'
  static readonly DEVICE_TYPE_CONTROLLER_ID: number = 5
}
