import { HttpClient, HttpErrorResponse, HttpEvent, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { SystemQueryOptions } from 'app/core/interfaces/others/system-query-options.interface';
import { GenericUtil } from 'app/core/utils/generic.util';
import { BaseService } from 'app/core/base/base.service';
import { Observable } from 'rxjs/internal/Observable';
import { OdataResponse } from 'app/core/interfaces/others/odata-response.interface';
import { BehaviorSubject, map, tap } from 'rxjs';
import { EntityFile } from '../interfaces/entity-file.interface';
import { RequestUploadFile } from '../interfaces/request-upload-file.interface';
import { AppFile } from '../interfaces/app-file.interface';
import { ReasignFile } from '../interfaces/reasign-file.interface';
import { Files } from 'app/core/interfaces/library/file.interface';
import { AppAlertService } from 'app/core/services/others/app-alert.service';

@Injectable({
  providedIn: 'root'
})
export class FilesService extends BaseService<Files> {
  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    protected _genericUtil: GenericUtil,
    protected _appAlertService: AppAlertService
  ) {
    super();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get items
   * @param options
   * @returns
   */
  get(options: SystemQueryOptions): Observable<OdataResponse<Files>> {
    const url = this._genericUtil.generateUrlOdataQuery(environment.apiItagueUrl + 'library/files', options);
    return this._httpClient.get<any>(url).pipe(
      tap((response: OdataResponse<Files>) => {
        this._itemsSubject.next(response.value);
        this._countSubject.next(response['@odata.count']);
      })
    );
  }

  /**
   * Create item
   */
  create(item: Files): Observable<Files> {
    let formData = new FormData();
    formData.append('file', item.file);
    formData.append('name', item.name.toString());
    formData.append('observation', item.observation.toString());
    formData.append('directoryId', item.directoryId.toString());
    const url = environment.apiItagueUrl + 'library/files/manager';
    return this._httpClient.post<any>(url, formData);
  }

  /**
   * Put item
   */
  put(item: Files): Observable<Files> {
    const url = environment.apiItagueUrl + 'library/files/manager';
    return this._httpClient.put<any>(url, item);
  }

  /**
   * Delete item
   */
  delete(item: Files): Observable<OdataResponse<Files>> {
    const url = environment.apiItagueUrl + 'library/files?guid=' + item.guid;

    return this._httpClient.delete<any>(url);
  }

  /**
   * Upload file
   * @param request
   * @returns
   */
  upload(request: RequestUploadFile): Observable<HttpEvent<AppFile>> {
    const url = environment.apiItagueUrl + 'library/files';

    let formData = new FormData();
    formData.append('File', request.file);
    formData.append('EntityFileTypeId', request.entityFileTypeId.toString());
    formData.append('EntityGuid', request.entityGuid);
    formData.append('ExternalEntity', request.externalEntity);
    formData.append('Observation', request.observation);
    formData.append('Temporary', request.temporary.toString());

    return this._httpClient.post<AppFile>(url, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  /**
   * Download file
   * @param fileGuid
   * @returns
   */
  download(fileGuid: string): Observable<HttpResponse<Blob>> {
    const url = environment.apiItagueUrl + 'library/files/download?guid=' + fileGuid;

    return this._httpClient.get<Blob>(url, {
      observe: 'response',
      responseType: 'blob' as 'json'
    });
  }

  /**
   * Reasign item
   */
  reasign(reasignFile: ReasignFile): any {
    const url = environment.apiItagueUrl + 'library/files/reasign';
    return this._httpClient.put<any>(url, reasignFile);
  }

  /**
   * Saving file from Library
   * @param guid Guid file
   * @param filename
   * @param finishMessage
   */
  saveFile(guid: string, filename: string, finishMessage: string) {
    this.download(guid).subscribe({
      next: (response) => {
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        const newBlob = new Blob([response.body], { type: response.body.type });
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(newBlob);
        a.href = objectUrl;
        a.download = filename;
        a.click();
        URL.revokeObjectURL(objectUrl);
        this._appAlertService.showInfo(finishMessage);
      }
    });
  }

  /**
   * Get specific item
   * @param itemId
   * @returns
   */
  getItem(options: SystemQueryOptions): Observable<Files> {
    const url = this._genericUtil.generateUrlOdataQuery(environment.apiItagueUrl + 'library/files', options);
    return this._httpClient.get<any>(url).pipe(
      map((response: OdataResponse<Files>) => {
        return response.value ? response.value[0] : {};
      })
    );
  }
}
