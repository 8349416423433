import { Component, Optional, QueryList, ViewChild, ViewChildren } from '@angular/core'
import { ActivatedRoute, ParamMap } from '@angular/router'
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, NgForm, ValidationErrors } from '@angular/forms'
import { fuseAnimations } from '@fuse/animations'
import { Observable, Subject, take, switchMap, of, ReplaySubject, takeUntil } from 'rxjs'
import { GenericConstants } from '../constants/generic.constants'
import { FuseScrollbarDirective } from '@fuse/directives/scrollbar'
import { TranslocoService } from '@jsverse/transloco'
import { OptionTrueOrNo } from '../interfaces/others/option-true-or-no-interface'
import { AppAlertService } from '../services/others/app-alert.service'
import { Router } from '@angular/router'
import { SubmitNextActions } from '../enums/submit-next-actions.enum'
import { CrudBaseComponent } from './crud-base.component'
import { FormModes } from '../enums/form-modes.enum'
import { v4 as uuidv4 } from 'uuid'
import { CanGoOut } from '../interfaces/others/can-go-out.interface'
import { Status } from '../interfaces/others/status.interface'
import { Genders } from '../enums/genders.enum'
import { GenericUtil } from '../utils/generic.util'
import { ConfirmDialogResponses } from '../enums/confirm-dialog-responses.enum'
import { BaseService } from './base.service'
import { default as _rollupMoment, Moment } from 'moment'
import { MatDatepicker } from '@angular/material/datepicker'
import * as _moment from 'moment'
const moment = _rollupMoment || _moment
/**
 * Base class for page components that have a form
 */
@Component({
  selector: 'app-form-base',
  template: ` <p>form base works!</p> `,
  styles: [],
  animations: fuseAnimations,
})
export abstract class FormBaseComponent<T> extends CrudBaseComponent implements CanGoOut {
  @ViewChild('itemNgForm') itemNgForm: NgForm
  @ViewChildren(FuseScrollbarDirective) protected _fuseScrollbarDirectives: QueryList<FuseScrollbarDirective>

  FORM_MODES = FormModes
  formMode: FormModes = FormModes.View
  itemId: number
  itemGuid: string
  itemForm: UntypedFormGroup
  itemData: T
  postCode: string

  yesOrNoOptions: OptionTrueOrNo[] = GenericConstants.YES_OR_NO
  statusOptions: Status[] = GenericConstants.STATUS
  SUBMIT_NEXT_ACTIONS = SubmitNextActions
  routeParams: ParamMap
  protected _pendindRequiredFiles: number = 0

  protected _unsubscribeAll: Subject<any> = new Subject<any>()

  // Services than must injected in children classes
  protected abstract _formBuilder: UntypedFormBuilder
  protected abstract _translocoService: TranslocoService
  protected abstract _appAlertService: AppAlertService
  protected abstract _route: ActivatedRoute
  protected abstract _router: Router
  protected _genericUtil: GenericUtil

  // -----------------------------------------------------------------------------------------------------
  // @ Getters and Setters
  // -----------------------------------------------------------------------------------------------------

  set pendingRequiredFiles(numberFiles: number) {
    this._pendindRequiredFiles = numberFiles
  }

  get pendingRequiredFiles(): number {
    return this._pendindRequiredFiles
  }

  /**
   * Constructor
   *
   */
  constructor(@Optional() protected _itemsService?: BaseService<T>) {
    super()
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Return to list page
   * @param path
   */
  returnToList(path: string): void {
    // this._router.navigateByUrl(path)
    this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => this._router.navigate([path]))
  }

  /**
   * Submit main form
   */
  abstract submitItem(nextAction: SubmitNextActions): void

  /**
   * Get pending required files of library component
   * @param numberFiles
   */
  getPendingRequiredFiles(numberFiles: number) {
    this.pendingRequiredFiles = numberFiles
  }

  /**
   *
   * @returns
   */
  canGoOut(): Observable<boolean> | Promise<boolean> | boolean {
    //In edit mode and required files are missing
    if (this.formMode == this.FORM_MODES.Edit && this.pendingRequiredFiles > 0) {
      const title = this._translocoService.translate('generic-errors.titles.validation-failed')
      const message = this._translocoService.translate('generic-errors.messages.validation-failed-only-files')
      return this._appAlertService.showErrorDialog(title, [message]).pipe(
        switchMap(response => {
          return of(false)
        }),
      )
    } else {
      return true
    }
  }

  // Get country code for phone input
  public getCountryCode(): string {
    let postCode: string = ''
    if (this.itemForm.get('countryId').value) {
      postCode = this.itemForm.get('countryId').value.postCode
    }

    return postCode
  }

  setMonthAndYear(controlId, normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
    this.itemForm
      .get(controlId)
      .setValue(
        new Date(normalizedMonthAndYear.year(), normalizedMonthAndYear.month(), normalizedMonthAndYear.daysInMonth()),
      )
    datepicker.close()
  }

  /**
   * Transform number in string for input validated with RxwebValidators.numeric
   * @param number Number to transform
   * @param separator Decimal separator, by defect ','
   * @returns
   */
  protected _transformToNumericInput(number: number, separator: string = ','): string {
    let result: string = number !== undefined && number !== null ? number.toString().replace('.', separator) : ''
    return result
  }

  /**
   * Transform string in number for input validated with RxwebValidators.numeric
   * @param number string to tranform in number
   * @param separator Decimal separator, by defect ','
   * @returns
   */
  protected _transformFromNumericInput(number: string, separator: string = ',', decimals: number = 2): number {
    let result: number =
      number !== undefined && number !== null && number !== ''
        ? Number(Number(number?.replace(separator, '.')).toFixed(decimals))
        : undefined
    return result
  }

  /**
   * Determines if all required files have been uploaded to the form
   * @returns true or false
   */
  protected _missingRequiredFiles() {
    if (this.pendingRequiredFiles > 0) {
      return true
    } else {
      return false
    }
  }

  /**
   * Show al form errors in console
   */
  protected _showInConsoleErrorsForm(): void {
    console.warn('Form errors')
    console.table(this._genericUtil.getAllErrors(this.itemForm))
  }

  /**
   * Display option name for autocomplete
   * @param option
   * @returns
   */
  displayAutocomplete(option: any): string {
    return option && option.name ? option.name : ''
  }

  /**
   * Show message for success item save
   */
  showSuccessSave(itemName: string, suffix: string = '') {
    if (!this.itemName) {
      this._translocoService
        .selectTranslate(this.translateAlias + (suffix ? `.${suffix}` : '') + '.item-name')
        .pipe(take(1))
        .subscribe({
          next: translate => {
            this.itemName = translate
            this._appAlertService.showSuccess(
              this._translocoService.translate('generic-messages.messages.success-save', {
                gender: this.itemGenre,
                name: this.itemName.toLowerCase(),
              }),
            )
          },
        })
    } else {
      this._appAlertService.showSuccess(
        this._translocoService.translate('generic-messages.messages.success-save', {
          gender: this.itemGenre,
          name: this.itemName.toLowerCase(),
        }),
      )
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Protected methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Reset form
   */
  protected _resetForm() {
    this.itemNgForm.resetForm()
    this.itemForm.reset(this.itemForm.value)
    ;(Object as any).values(this.itemNgForm.form.controls).forEach((control: UntypedFormControl) => {
      control.setValue('')
      control.setErrors(null)
      control.updateValueAndValidity()
    })

    this._fuseScrollbarDirectives.first.scrollToY(0, 1)

    this.itemGuid = uuidv4()

    this._customResetForm()
  }

  /**
   * Logic for custom reset form
   */
  protected _customResetForm(): void {}

  /**
   * Set default values afted load data
   */
  protected _setDefaultValues(): void {}

  // Methods that must be overridden

  /**
   * Load initial data required for the form
   */
  protected abstract _loadData(): void

  /**
   * Create form with its group of controls
   */
  protected abstract _createItemForm(): void

  /**
   * Set values in the form for edit mode
   */
  protected abstract _editItemForm(): void

  /**
   * Capture default events for form
   */
  protected _captureDefaultFormEvents(): void {}

  /**
   * Sets event emitter for the form controls
   */
  protected abstract _defineEmitEventsForControls(): void

  /**
   *
   * Actions to be executed in the ngOnInit method inside the child component
   * @param translateAlias module alias for transloco provider
   * @param gender item gender
   */
  protected _baseNgOnInit(
    translateAlias: string,
    gender: string = Genders.Other,
    caslSubject: string = this.CASL_SUBJECTS.None,
    paramsInRoute: boolean = true,
  ) {
    this._appAlertService.closeSnackBar()

    // Get all params
    if (this._route && paramsInRoute) {
      this.routeParams = this._route.snapshot.paramMap

      const cipherId: string = this.routeParams.get('id')
      if (cipherId) {
        const decipherId: string = this._genericUtil.decryptUrlParameter(cipherId)

        this.itemId = Number(decipherId)
        if (this._route.snapshot.url.find(u => u.path == 'edit')) {
          this.formMode = FormModes.Edit
        } else {
          this.formMode = FormModes.View
        }
      } else {
        this.formMode = FormModes.Create
        this.itemGuid = uuidv4()
      }
    }

    // Set some values
    this.itemGenre = gender
    this.translateAlias = translateAlias
    this.caslSubject = caslSubject

    // Create item form
    this._createItemForm()

    // Load data
    this._loadData()

    // Set default emit events for form
    this._captureDefaultFormEvents()

    // Sets event emitter for the form controls
    this._defineEmitEventsForControls()

    // Set default values
    this._setDefaultValues()

    // Patch values to form
    if (this.itemData && (this.formMode == this.FORM_MODES.Edit || this.formMode == this.FORM_MODES.View)) {
      this._editItemForm()
    }

    // Disable form if form mode is view
    if (this.formMode == this.FORM_MODES.View) {
      this.itemForm.disable({ emitEvent: false })
    }
  }

  /**
   * Actions to be executed in the ngOnDestroy method inside the child component
   */
  protected _baseNgOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null)
    this._unsubscribeAll.complete()
  }

  /**
   * Scroll to first error
   */
  private scrollToFirstError() {
    // Todo: fix, Scroll but then return to the end of page
    /*
    for (let x in this.itemForm.controls) {
      if (this.itemForm.controls[x].errors) {
        document.querySelector('#' + x).scrollIntoView()
        break
      }
    }
    */
  }

  /**
   * If form is invalid show errors form
   * @returns null
   */
  protected _showErrorsForm() {
    // Mark all controls as touched
    this.itemForm.markAllAsTouched()

    this._translocoService
      .selectTranslateObject('generic-errors')
      .pipe(take(1))
      .subscribe({
        next: genericErrors => {
          if (this.pendingRequiredFiles == 0) {
            this._appAlertService.showErrorDialog(genericErrors['titles']['validation-failed'], [
              genericErrors['messages']['validation-failed'],
            ])
          } else {
            if (this._getNumberFormValidationErrors() > 0) {
              this._appAlertService.showErrorDialog(genericErrors['titles']['validation-failed'], [
                genericErrors['messages']['validation-failed-with-files'],
              ])
            } else {
              this._appAlertService.showErrorDialog(genericErrors['titles']['validation-failed'], [
                genericErrors['messages']['validation-failed-only-files'],
              ])
            }
          }

          this.scrollToFirstError()
        },
      })

    return
  }

  /**
   * Get number of form validation errors
   * @returns
   */
  protected _getNumberFormValidationErrors() {
    let totalErrors = 0

    Object.keys(this.itemForm.controls).forEach(key => {
      const controlErrors: ValidationErrors = this.itemForm.get(key).errors
      if (controlErrors != null) {
        totalErrors++
      }
    })

    return totalErrors
  }

  // Subscriptions to changes in autocompletes' filters
  // -----------------------------------------------------------------------------------------------------

  /**
   * filter values for mat select with filter
   * @param search text to search
   * @param properties array property names in which to search
   * @param source array in which to search
   * @returns resplay subject with array result
   */
  protected filterValuesForMatSelect<T>(search: string, properties: string[], source: T[], multi?): ReplaySubject<T[]> {
    let results: T[] = []
    let results$: ReplaySubject<T[]> = new ReplaySubject<T[]>(1)

    if (!search) {
      results$.next(source)
      return results$
    } else {
      search = search.toLowerCase()
    }

    results = source.filter(s => {
      let text: string = ''
      let value: any = s
      if (multi) {
        const optionsKey = properties[0]
        const nameKey = properties[1]
        const options = value[optionsKey]
        if (options && Array.isArray(options)) {
          for (const option of options) {
            const name = option[nameKey]
            if (name && name.toLowerCase().includes(search)) {
              text = name
              break
            }
          }
        }
      } else {
        for (const key of properties) {
          value = value[key]
          if (value === undefined || value === null) break
          text = text ? text + ' ' + value : value
        }
      }

      return text !== undefined && text !== null ? text.toLowerCase().indexOf(search) > -1 : ''
    })

    results$.next(results)

    return results$
  }

  /**
   * Show delete confirm dialog
   * @param item
   */
  confirmDelete(item: T, path: string) {
    if (this.itemName == '') {
      this._translocoService
        .selectTranslate(this.translateAlias + '.item-name')
        .subscribe(translate => (this.itemName = translate.toLowerCase()))
    }

    this._appAlertService
      .showWarningConfirm(this._translocoService.translate('generic-messages.titles.confirm-action'), [
        this._translocoService.translate('generic-messages.messages.confirm-action', {
          gender: this.itemGenre,
          name: this.itemName,
        }),
      ])
      .pipe(take(1))
      .subscribe({
        next: (response: string) => {
          if (response == ConfirmDialogResponses.Confirmed) {
            this._deleteItem(item, path)
          }
        },
      })
  }

  /**
   * Delete item
   * @param item
   */
  protected _deleteItem(item: T, path: string) {
    this._itemsService
      .delete(item)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: response => {
          this._appAlertService.showSuccess(
            this._translocoService.translate('generic-messages.messages.success-erase', {
              gender: this.itemGenre,
              name: this.itemName,
            }),
          )
          this.returnToList(path)
        },
      })
  }

  /**
   * Transform number in string for input validated with RxwebValidators.numeric
   * @param number Number to transform
   * @param separator Decimal separator, by defect ','
   * @returns
   */
  protected _transformStringToDecimalsInput(number: string, replace: string = ',', separator: string = '.'): string {
    let result: string = number !== undefined && number !== null ? number.toString().replace(replace, separator) : ''
    return result
  }

  protected _transformDecimalsToStringInput(number: string, replace: string = '.', separator: string = ','): string {
    let result: string = number !== undefined && number !== null ? number.toString().replace(replace, separator) : ''
    return result
  }
}
