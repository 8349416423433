<ng-container *transloco="let t">
  <mat-accordion class="accordion-wrapper" multi>
    <mat-expansion-panel hideToggle class="app-mat-expansion-panel">
      <mat-expansion-panel-header>
        <mat-icon>keyboard_arrow_up</mat-icon>
        <mat-panel-title> {{ props['label'] }} </mat-panel-title>
        <div class="badge" [class.badge-complete]="isComplete" [class.badge-incomplete]="!isComplete">
          <p>{{ isComplete ? t('buttons.labels.complete') : t('buttons.labels.incomplete') }}</p>
        </div>
      </mat-expansion-panel-header>
      <p class="description">{{ props['description'] }}</p>
      <ng-container #fieldComponent></ng-container>
    </mat-expansion-panel>
  </mat-accordion>
</ng-container>