import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OptionButtonList } from './option-button-list.interface';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';


@Component({
    selector: 'app-options-button-list',
    templateUrl: './options-button-list.component.html',
    styleUrls: ['./options-button-list.component.scss'],
    standalone: true,
    imports: [MatButtonModule, MatMenuModule, MatIconModule]
})
export class OptionsButtonListComponent {
  @Input('label') label: string;
  @Input('options') options: OptionButtonList[];
  @Output('functionToCall') functionToCall = new EventEmitter<{ functionName: string; args: any[] }>();

  setFunctionToCall(name:string)
  {
    this.functionToCall.emit({ functionName: name, args: [] });
  }
  
}
