import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, tap } from 'rxjs';
import { FileUrl } from '../../interfaces/library/file-url.interface';

@Injectable({
  providedIn: 'root'
})
export class FileUrlService {


  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
  ) { 
  }


  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get avatar url
   * @param options 
   * @returns 
   */
  getUrl(guid: string):Observable<FileUrl[]>
  {
    const url = environment.apiItagueUrl + 'library/files/url?guid=' + guid;
    return this._httpClient.get<FileUrl[]>(url);
  }



}

