import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { AbstractControl } from '@angular/forms'
import { ErrorUtil } from '../../core/utils/error.util'
import { Genders } from '../../core/enums/genders.enum'

import { TRANSLOCO_SCOPE, TranslocoModule } from '@jsverse/transloco'
import { MatInputModule } from '@angular/material/input'
import { GenericConstants } from 'app/core/constants/generic.constants'
export const loader = GenericConstants.APP_LANGUAGES.reduce((acc, lang) => {
  acc[lang] = () => import(`./i18n/${lang}.json`)
  return acc
}, {})

@Component({
  selector: 'error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss'],
  standalone: true,
  imports: [MatInputModule, TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'FormErrorDisplayModule',
        alias: 'fed',
        loader,
      },
      multi: true,
    },
  ],
})
export class ErrorMessageComponent implements OnInit, OnChanges {
  @Input('control') control: AbstractControl
  @Input('showError') showError: boolean = true
  @Input('label') label: string = ''
  @Input('gender') gender: Genders.Female | Genders.Male | Genders.Other = Genders.Other

  constructor(public errorUtil: ErrorUtil) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}

  /**
   * Get first control error
   * @param errors
   * @returns
   */
  getFirstErrorType(errors: any): string {
    let errorTypes = Object.getOwnPropertyNames(errors)

    if (errorTypes && errorTypes.length > 0) {
      return errorTypes[0]
    }

    return ''
  }

  /**
   * Get error parameters
   * @returns
   */
  getErrorParams() {
    return this.errorUtil.getParams(this.label, this.gender, this.control, this.getFirstErrorType(this.control.errors))
  }
}
