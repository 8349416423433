<button mat-icon-button [matMenuTriggerFor]="actionsMenu">
  <mat-icon class="app-menu-button-icon" [svgIcon]="'feather:more-vertical'"></mat-icon>
</button>

<mat-menu #actionsMenu="matMenu">

  @for (action of actions; track trackByFn($index, action)) {
    @if (showButton(action)) {
      <button mat-menu-item (click)="notifyParent(action)">
        <span class="app-mat-menu-button">
          <mat-icon class="app-menu-button-icon" [svgIcon]="action.icon"></mat-icon>
          <span class="app-menu-button-text">{{ action.label }}</span>
        </span>
      </button>
    }
  }


</mat-menu>
