import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog'
import { DomSanitizer } from '@angular/platform-browser'
import { TranslocoService, TranslocoModule, TRANSLOCO_SCOPE } from '@jsverse/transloco'
import { ConfirmDialogResponses } from 'app/core/enums/confirm-dialog-responses.enum'
import Cropper from 'cropperjs'
import { MatIconModule } from '@angular/material/icon'
import { MatButtonModule } from '@angular/material/button'
import { GenericConstants } from 'app/core/constants/generic.constants'
export const loader = GenericConstants.APP_LANGUAGES.reduce((acc, lang) => {
  acc[lang] = () => import(`./i18n/${lang}.json`)
  return acc
}, {})

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss'],
  standalone: true,
  imports: [TranslocoModule, MatDialogModule, MatButtonModule, MatIconModule],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'ImageCropperModule',
        alias: 'icro',
        loader,
      },
      multi: true,
    },
  ],
})
export class ImageCropperComponent implements OnInit, AfterViewInit {
  //@ViewChild('image') image!: ElementRef;
  @ViewChild('image') image!: ElementRef
  sanitizedUrl: any
  cropper!: Cropper
  confirmDialogResponses = ConfirmDialogResponses
  imageLoaded = false
  aspectRatio: any = 1

  /**
   *
   */
  constructor(
    public dialogRef: MatDialogRef<ImageCropperComponent>,
    @Inject(MAT_DIALOG_DATA) public urlImage: string | any,
    private sanitizer: DomSanitizer,
    protected _translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    let image = this.urlImage
    if (this.urlImage?.custom_cropper) {
      image = this.urlImage?.image
      this.aspectRatio = null
    }
    this.sanitizedUrl = this.sanitizer.bypassSecurityTrustUrl(image)
  }

  ngAfterViewInit() {
    //this.initCropper();
  }

  initCropper() {
    const image = document.getElementById('image') as HTMLImageElement
    this.cropper = new Cropper(image, {
      aspectRatio: 1,
      viewMode: 1,
      guides: false,
    })
  }

  // make the crop box rounded

  getRoundedCanvas(sourceCanvas: any) {
    var canvas = document.createElement('canvas')
    var context: any = canvas.getContext('2d')
    var width = sourceCanvas.width
    var height = sourceCanvas.height

    canvas.width = width
    canvas.height = height
    context.imageSmoothingEnabled = true
    context.drawImage(sourceCanvas, 0, 0, width, height)
    context.globalCompositeOperation = 'destination-in'
    context.beginPath()
    context.arc(width / 2, height / 2, Math.min(width, height) / 2, 0, 2 * Math.PI, true)
    context.fill()
    return canvas
  }

  //get the cropped image and closes the dialog
  //returning an url or null if no image

  crop() {
    let croppedCanvas = this.cropper.getCroppedCanvas({
      fillColor: '#fff',
      //imageSmoothingEnabled: false,
      imageSmoothingQuality: 'high',
      width: 250,
      height: 250,
      minWidth: 180,
      minHeight: 180,
      maxWidth: 400,
      maxHeight: 400,
    })
    const roundedCanvas = this.getRoundedCanvas(croppedCanvas)
    let canvas = roundedCanvas
    if (this.urlImage?.custom_cropper) {
      canvas = croppedCanvas
    }

    let roundedImage = document.createElement('img')

    if (roundedImage) {
      this.dialogRef.close(canvas.toDataURL('image/png'))
    } else {
      return this.dialogRef.close(null)
    }
  }

  // resets the cropper
  reset() {
    this.cropper.reset()
    /*
    this.cropper.clear();
    this.cropper.crop();
    */
  }

  rotateRight() {
    this.cropper.rotate(45)
  }

  rotateLeft() {
    this.cropper.rotate(-45)
  }

  move() {
    this.cropper.setDragMode('move')
  }

  zoomIn() {
    this.cropper.zoom(0.1)
  }

  zoomOut() {
    this.cropper.zoom(-0.1)
  }

  flipH() {
    this.cropper.scaleY(this.cropper.getImageData().scaleY)
    this.cropper.scaleX(-this.cropper.getImageData().scaleX)
  }

  flipV() {
    this.cropper.scaleX(this.cropper.getImageData().scaleX)
    this.cropper.scaleY(-this.cropper.getImageData().scaleY)
  }

  onLoad() {
    this.image.nativeElement.addEventListener('ready', () => {
      this.imageLoaded = true
    })

    /*
    this.cropper = new Cropper(this.image.nativeElement, {
      aspectRatio: this.aspectRatio,
      autoCropArea: this.autoCropArea,
      autoCrop: this.autoCrop,
      modal: this.mask, // black mask
      guides: this.guides, // grid
      center: this.centerIndicator, // center indicator
      viewMode: this.viewMode,
      scalable: this.scalable,
      zoomable: this.zoomable,
      background: false,
      cropBoxMovable: this.cropBoxMovable,
      cropBoxResizable: this.cropBoxResizable,
    });
    */

    this.cropper = new Cropper(this.image.nativeElement, {
      aspectRatio: this.aspectRatio,
      viewMode: 1,
      guides: false,
    })
  }
}
