export enum Roles {
  //Generic roles
  SuperAdmin = '3236d3d2-2ee6-4728-96ad-98b095816bfa',
  ExternalAuditor = '78a0b977-8311-4a19-b9f7-72e846b2d20e',
  Registered = '8c1551ef-7173-4682-b879-2075dc792168',
  Administrator = '91c94c14-08f2-4d93-aa92-b81d26b8e1cb',
  Organization = 'a8748efc-1ea6-41e3-8316-dc17a9297275',
  TechnicalAdvisor = 'd68c69f9-87c8-4ac0-95f0-6ec7ff539934',
  Producer = 'f30cd514-adb3-481b-9f3c-6c12ce6167ee',

  //Dependent roles
  AgriculturalWorker = '2156ff37-8146-40c2-98b2-1d66db7f8dfa',
  FarmExternalAuditor = '296411ec-8de1-497a-8e40-080131e7c898',
  AttachedFarmAdministrator = '2a932cdf-ec89-4ed4-86b4-45852d52593a',
  OrganizationAdministrator = '3c37671e-dabe-4782-be51-4389b2488c59',
  FarmAdministrator = '86a3f72f-caa5-4c93-b8e4-d94eeb5836a2',
  FarmTechnicalAdvisor = 'f0201aaf-a751-4d99-a635-7cdd144ace82'
}
