import { HttpClient, HttpErrorResponse, HttpEvent, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { SystemQueryOptions } from 'app/core/interfaces/others/system-query-options.interface';
import { GenericUtil } from 'app/core/utils/generic.util';
import { BaseService } from 'app/core/base/base.service';
import { Observable } from 'rxjs/internal/Observable';
import { OdataResponse } from 'app/core/interfaces/others/odata-response.interface';
import { BehaviorSubject, tap } from 'rxjs';
import { EntityFile } from '../interfaces/entity-file.interface';

@Injectable({
  providedIn: 'root'
})
export class EntityFilesService extends BaseService<EntityFile> {
  protected _optionalItemsSubject = new BehaviorSubject<EntityFile[]>([]);
  protected _optionalCountSubject = new BehaviorSubject<number>(0);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient, protected _genericUtil: GenericUtil) {
    super();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for count optional items
   */
  get optionalCount$(): Observable<Number> {
    return this._countSubject.asObservable();
  }

  /**
   * Getter for optional items
   */
  get optionalItems$(): Observable<EntityFile[]> {
    return this._optionalItemsSubject.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get items
   * @param options
   * @returns
   */
  get(options: SystemQueryOptions): Observable<OdataResponse<EntityFile>> {
    const url = this._genericUtil.generateUrlOdataQuery(environment.apiItagueUrl + 'library/entity-files', options);
    return this._httpClient.get<any>(url).pipe(
      tap((response: OdataResponse<EntityFile>) => {
        this._itemsSubject.next(response.value);
        this._countSubject.next(response['@odata.count']);
      })
    );
  }

  /**
   * Get items
   * @param options
   * @returns
   */
  getOptionals(options: SystemQueryOptions): Observable<OdataResponse<EntityFile>> {
    const url = this._genericUtil.generateUrlOdataQuery(environment.apiItagueUrl + 'library/entity-files', options);
    return this._httpClient.get<any>(url).pipe(
      tap((response: OdataResponse<EntityFile>) => {
        this._optionalItemsSubject.next(response.value);
        this._optionalCountSubject.next(response['@odata.count']);
      })
    );
  }

  /**
   * Get items
   * @param options
   * @returns
   */
  getPendingRequired(options: SystemQueryOptions): Observable<OdataResponse<EntityFile>> {
    const url = this._genericUtil.generateUrlOdataQuery(environment.apiItagueUrl + 'library/entity-files', options);
    return this._httpClient.get<any>(url);
  }
}
