import { inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseConfirmationDialogComponent } from '@fuse/services/confirmation/dialog/dialog.component';
import { merge } from 'lodash-es';
import { AppConfirmationConfig } from './app-confirmation.types';
import { AppConfirmationDialogComponent } from './dialog/app-dialog.component';

@Injectable({ providedIn: 'root' })
export class AppConfirmationService {
  private _matDialog: MatDialog = inject(MatDialog);
  private _defaultConfig: AppConfirmationConfig = {
    title: 'Confirm action',
    message: 'Are you sure you want to confirm this action?',
    icon: {
      show: true,
      name: 'heroicons_solid:exclamation-triangle',
      color: 'warn'
    },
    actions: {
      confirm: {
        show: true,
        label: 'Confirm',
        color: 'warn'
      },
      cancel: {
        show: true,
        label: 'Cancel'
      }
    },
    dismissible: false
  };

  /**
   * Constructor
   */
  constructor() {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  open(config: AppConfirmationConfig = {}): MatDialogRef<AppConfirmationDialogComponent> {
    // Merge the user config with the default config
    const userConfig = merge({}, this._defaultConfig, config);

    // Open the dialog
    return this._matDialog.open(AppConfirmationDialogComponent, {
      autoFocus: false,
      disableClose: !userConfig.dismissible,
      data: userConfig,
      panelClass: config.panelClass ? config.panelClass : 'app-modal-panel-small'
    });
  }
}
