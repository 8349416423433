import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { UserService } from './core/services/identity/user.service';
import { UserSettingsService } from './core/services/identity/user-settings.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet]
})
export class AppComponent implements OnInit {
  /**
   * Constructor
   */
  constructor(private _userSettingsService: UserSettingsService) {}

  ngOnInit(): void {
    //todo: The automatic mode of the loading bar for http requests is disabled since errors appear in some moments, it is time to implement our own service for loading issues
    //this._fuseLoadingService.setAutoMode(false);

    this._userSettingsService.setLanguage();
    this._userSettingsService.setScheme();
  }
}
