import { Component, Inject, Input, OnInit } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { MatButtonModule } from '@angular/material/button'
import { SharedModule } from '../shared.module'
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog'

interface ConfigButtons {
  show: boolean
  isDisabled: boolean
  action: any
}

@Component({
  selector: 'app-modal',
  standalone: true,
  imports: [RouterModule, CommonModule, MatButtonModule, SharedModule, MatDialogModule],
  templateUrl: './app-modal.component.html',
  styleUrls: ['./app-modal.component.scss'],
})
export class AppModalComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<AppModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  @Input() title: string
  @Input() configSaveButton: ConfigButtons = {} as ConfigButtons
  @Input() configContinueButton: ConfigButtons = {} as ConfigButtons
  @Input() configCloseButton: ConfigButtons = {} as ConfigButtons

  ngOnInit(): void {
    if (this.data) {
      this.title = this.data.title
      this.configCloseButton.show = true
    }
  }
}
