import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { TroubleHttpResponse } from '../../interfaces/others/trouble-http-response.interface';
import { TranslocoService } from '@jsverse/transloco';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  constructor(private _translocoService: TranslocoService) {}

  getClientTitle(error: Error): string {
    let title: string = '';

    if (!navigator.onLine) {
      title = 'generic-errors.titles.offline';
    } else {
      title = 'generic-errors.titles.something-is-not-working';
    }

    return this._translocoService.translate(title);
  }

  getClientMessages(error: Error): string[] {
    let messages: string[] = [];

    if (!navigator.onLine) {
      messages.push(this._translocoService.translate('generic-errors.messages.offline'));
    } else {
      messages.push(error.message ? error.message : error.toString());
    }

    return messages;
  }

  getClientStack(error: Error): string {
    return error.stack;
  }

  getServerTitle(error: HttpErrorResponse): string {
    let title: string;

    switch (error.status) {
      case 404:
        title = 'generic-errors.titles.not-found';
        break;
      case 400:
        title = 'generic-errors.titles.bad-request';
        break;
      case 500:
        title = 'generic-errors.titles.server-error';
        break;
      default:
        title = 'generic-errors.titles.other-error';
        break;
    }

    return this._translocoService.translate(title);
  }

  getServerMessages(error: HttpErrorResponse): string[] {
    let messages: string[] = [];

    switch (error.status) {
      case 404:
        messages.push(this._translocoService.translate('generic-errors.messages.not-found'));
        break;
      case 400:
        if (this.determineIfIsTroubleHttpResponse(error.error)) {
          if (error.error instanceof Blob) {
          }

          const trouble: TroubleHttpResponse = error.error;

          let keys = Object.keys(trouble.errors);

          // Return only first error returned
          for (var key of keys) {
            messages.push(trouble.errors[key][0]);
          }
        } else {
          messages.push(this._translocoService.translate('generic-errors.messages.bad-request'));
        }
        break;
      case 500:
        messages.push(this._translocoService.translate('generic-errors.messages.server-error'));
        break;
      default:
        messages.push(this._translocoService.translate('generic-errors.messages.other-error'));
        break;
    }

    return messages;
  }

  getServerStack(error: HttpErrorResponse): string {
    // handle stack trace
    return 'stack';
  }

  /**
   * Determine if a object is a type of TroubleHttpResponse
   * @param toBeDetermined
   * @returns
   */
  determineIfIsTroubleHttpResponse(toBeDetermined: any): toBeDetermined is TroubleHttpResponse {
    if ((toBeDetermined as TroubleHttpResponse).type) {
      return true;
    }
    return false;
  }
}
