import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatTooltipModule } from '@angular/material/tooltip'
import { FormlyModule } from '@ngx-formly/core'
import { ErrorMessageComponent } from 'app/shared/error-message/error-message.component'
import { BaseCustomFieldComponent } from '../base-custom-field.component'
import { NgIf } from '@angular/common'
import { TRANSLOCO_SCOPE, TranslocoModule, TranslocoService } from '@jsverse/transloco'
import { GenericConstants } from 'app/core/constants/generic.constants'
import { MatButtonModule } from '@angular/material/button'
import { BlockScrollStrategy, Overlay } from '@angular/cdk/overlay'
import { MatDialog } from '@angular/material/dialog'
import { Observable } from 'rxjs'
import { ImageCropperComponent } from 'app/shared/image-cropper/image-cropper.component'

// export const loader = GenericConstants.APP_LANGUAGES.reduce((acc, lang) => {
//   acc[lang] = () => import(`../../../../assets/i18n/${lang}.json`)
//   return acc
// }, {})

@Component({
  selector: 'app-custom-note',
  standalone: true,
  imports: [
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatTooltipModule,
    ErrorMessageComponent,
    NgIf,
    TranslocoModule,
    MatButtonModule,
  ],
  templateUrl: './custom-note.component.html',
  styleUrl: './custom-note.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class CustomInputNoteComponent implements OnInit {
  @Output() sendText = new EventEmitter<any>()
  @Output() sendImage = new EventEmitter<any>()
  @Input() textValue: string
  @Input() formDisable: any
  showNote: boolean
  acceptFiles = 'image/png, image/jpeg'
  maxFileSize = 5
  files: any[] = []
  valueFiles: any
  private _overlay: Overlay
  private _scrollStrategy: BlockScrollStrategy
  constructor(
    protected _translocoService: TranslocoService,
    private _matDialog: MatDialog,
    private cd: ChangeDetectorRef,
  ) {}
  ngOnInit(): void {
    if (this.textValue) {
      this.onNote('save')
    }
  }
  onNote(type: string) {
    if (type === 'open') {
      this.showNote = true
    } else if (type === 'save') {
      this.showNote = false
      this.sendText.emit(this.textValue)
    } else {
      this.textValue = ''
      this.showNote = false
    }
  }

  removeNote() {
    this.textValue = ''
    this.sendText.emit(this.textValue)
  }

  removeImage() {
    this.files = []
    this.valueFiles = null
    this.sendImage.emit(this.valueFiles)
  }

  onChange = (fileUrl: any[]) => {}

  onFileChange(event: any) {
    const files = event.target.files as FileList
    const validTypes = this.acceptFiles
    if (!validTypes.includes(files[0].type)) {
      // this.showFileError.emit(true)
      this.valueFiles = null
      return
    }

    if (files.length > 0) {
      const _file = URL.createObjectURL(files[0])
      this.openItemEditor(_file).subscribe(result => {
        if (result) {
          if (result.size > this.maxFileSize * 1024 * 1024) {
            // this.errorMessage = `The file size exceeds the maximum allowed size of ${this.maxFileSize / (1024 * 1024)} MB.`;
            alert('max MB')
            return
          }
          let file = files[0]
          file['image'] = result
          this.files.push(file)
          this.onChange(this.files)
          this.valueFiles = this.files.map(item => item.image)
          this.sendImage.emit(this.valueFiles[0])
          this.cd.detectChanges()
        }
      })
    }
  }

  openItemEditor(image: string): Observable<any> {
    let data = {
      image,
      custom_cropper: true,
    }
    this._scrollStrategy = this._overlay?.scrollStrategies.block()
    const dialogRef = this._matDialog.open(ImageCropperComponent, {
      autoFocus: false,
      disableClose: true,
      data: data,
      panelClass: ['app-modal-panel-full', 'custom-cropper'],
      scrollStrategy: this._scrollStrategy,
    })

    return dialogRef.afterClosed()
  }
}
