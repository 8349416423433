<ng-container *transloco="let t">
  <!-- Button -->
  <button mat-button class="border-solid border border-slate-300" [matMenuTriggerFor]="userActions">
    <span class="inline-flex items-center">
      <span class="mr-3 text-sm">{{ currentBusinessUnit?.name }}</span>
      <span class="relative">
        @if (showAvatar && user?.avatar) {
        <img class="w-7 h-7 rounded-full" [src]="user?.avatar" />
        } @if (!showAvatar || !user?.avatar) {
        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        }
        <span
          class="absolute right-0 bottom-0 w-2 h-2 rounded-full"
          [ngClass]="{
            'mr-px mb-px': !showAvatar || !user?.avatar,
            'bg-green-500': user?.status === 'online',
            'bg-amber-500': user?.status === 'away',
            'bg-red-500': user?.status === 'busy',
            'bg-gray-400': user?.status === 'not-visible'
          }"
        ></span>
      </span>
    </span>
  </button>

  <mat-menu [xPosition]="'before'" #userActions="matMenu" class="user-actions">
    <button mat-menu-item>
      <span class="flex flex-col leading-none">
        <span>{{ t('m.signed-in-as') }}</span>
        <span class="mt-1.5 text-md font-medium">{{ user.userName }}</span>
      </span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <mat-form-field class="px-4 w-full" (click)="stopPropagation($event)">
      <mat-select id="businessUnitGuid" [(ngModel)]="businessUnitGuid" (selectionChange)="onBusinessUnitChange($event)">
        @if(this.userHasGenericRoles) {
        <mat-option [value]="genericBusinessUnitGuid">{{ t('m.manage-all') }}</mat-option>
        } @for (item of businessUnits$ | async; track item) {
        <mat-option [value]="item.guid">{{ item.name ? item.name : item.socialReason }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-divider class="my-2"></mat-divider>

    <button mat-menu-item [routerLink]="['/my-account']">
      <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
      <span>{{ t('m.my-account') }}</span>
    </button>
    <button mat-menu-item [routerLink]="['/settings']">
      <mat-icon [svgIcon]="'mat_outline:settings'"></mat-icon>
      <span>{{ t('m.settings') }}</span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item (click)="signOut()">
      <mat-icon [svgIcon]="'mat_outline:logout'"></mat-icon>
      <span>{{ t('m.sign-out') }}</span>
    </button>
  </mat-menu>
</ng-container>
