<ng-container *transloco="let t">
  <div class="app-modal-container">

    <!-- Title -->
    @if (data.title) {
    <div class="app-modal-header">
      <div class="app-modal-title">{{ t('libfil.form.load') + ' ' + (data.title | lowercase)}}</div>
    </div>
    }

    <!-- Content -->
    <div class="app-modal-form-container">

      <!-- Form -->
      <form [formGroup]="itemForm" class="w-full" #itemNgForm="ngForm">

        <div class="app-form-field-group">

          <!-- Entity file type id field -->
          @if (requireEntityFileType) {
          @if ('entityFileTypeId'; as controlId) {
          <div class="app-single-field col-span-2">
            <mat-form-field>
              <mat-label>{{ t('libfil.form.labels.' + controlId) }}</mat-label>
              <mat-select [id]="controlId" [formControlName]="controlId">
                @for (option of optionalEntityFiles$ | async; track option) {
                <mat-option [value]="option">
                  {{ option.fileTypeName }}
                </mat-option>
                }
              </mat-select>
              <mat-icon class="app-form-icon-info" [svgIcon]="'mat_outline:info'"
                matTooltip="{{ t('libfil.form.info.' + controlId) }}" matSuffix></mat-icon>
              <mat-error>
                <error-message [control]="itemForm.get(controlId)"
                  [label]="t('libfil.form.labels.' + controlId)"></error-message>
              </mat-error>
            </mat-form-field>
          </div>
          }
          }

          <!-- File field -->
          @if ('file'; as controlId) {
          @if (this.requireEntityFileType == false || itemForm.get('entityFileTypeId').value) {
          <div class="form-field-with-hint col-span-2" @zoomIn>
            <div class="app-single-field">
              <mat-form-field>
                <mat-label>{{ t('libfil.form.labels.' + controlId) }}</mat-label>
                <!-- Readonly Input to show File names -->
                <input matInput id="fileName" formControlName="fileName" readonly>
                <input type="file" [id]="controlId" [formControlName]="controlId" class="hidden"
                  (change)="onChange($event)" name="files[]" [accept]=formatsAllowed #fileInput />
                <mat-icon class="app-form-icon-info" [svgIcon]="'mat_outline:info'" matTooltip="{{ data.help }}"
                  matSuffix></mat-icon>
                <!-- Browse Button -->
                <button type="button" mat-icon-button color="primary" aria-label="Show file browse"
                  (click)="showFileBrowseDialog()" matSuffix>
                  <mat-icon svgIcon="heroicons_outline:folder-open"></mat-icon>
                </button>
                <!--
                    <mat-hint class="flex">
                      <div class="flex-auto pb-2 text-blue-600">{{ t('libfil.form.hints.formats') + ': ' + formatsAllowedToShow }}</div>
                      <div class="flex-auto pb-2 text-right text-blue-600">{{ t('libfil.form.hints.maxSize') + ': ' + maxSizeToShow }}</div>
                    </mat-hint>
                    -->
                <mat-error>
                  <error-message [control]="itemForm.get(controlId)"
                    [label]="t('libfil.form.labels.' + controlId)"></error-message>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="custom-hint">
              <div class="flex-auto pb-2">{{ t('libfil.form.hints.formats') + ': ' + formatsAllowedToShow }}</div>
              <div class="flex-auto pb-2 text-right">{{ t('libfil.form.hints.maxSize') + ': ' + maxSizeToShow }}</div>
            </div>
          </div>
          }
          }

          <!-- Progress bar -->
          @if (progressBarShow) {
          <div class="progress col-xs-3 padMarg afu-progress-bar col-span-2">
            <mat-progress-bar mode="determinate" [value]="uploadPercent">{{uploadPercent}}%</mat-progress-bar>
          </div>
          }

          <!-- Observation field -->
          @if ('observation'; as controlId) {
          <div class="app-single-field col-span-2">
            <mat-form-field>
              <mat-label>{{ t('libfil.form.labels.' + controlId) }}</mat-label>
              <textarea [id]="controlId" [formControlName]="controlId" matInput [rows]="3"></textarea>
              <mat-icon class="app-form-icon-info" [svgIcon]="'mat_outline:info'"
                matTooltip="{{ t('libfil.form.info.' + controlId) }}" matSuffix></mat-icon>
              <mat-error>
                <error-message [control]="itemForm.get(controlId)"
                  [label]="t('libfil.form.labels.' + controlId)"></error-message>
              </mat-error>
            </mat-form-field>
          </div>
          }

        </div>



      </form>

    </div>

    <!-- Actions -->
    <div class="app-modal-footer">

      <!-- Cancel -->
      <button mat-stroked-button [matDialogClose]="confirmDialogResponses.Cancelled">
        <span class="app-button-text">{{ t('buttons.labels.close')}}</span>
      </button>

      <!-- Confirm -->
      <button mat-flat-button [color]="'primary'" (click)="submitItem(SUBMIT_NEXT_ACTIONS.SaveAndClose)"
        [disabled]="itemForm.disabled">
        <span class="app-button-text">{{ t('buttons.labels.save')}}</span>
      </button>

    </div>


  </div>
</ng-container>