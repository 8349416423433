import { NgFor, NgIf } from '@angular/common'
import { Component, ElementRef, ViewEncapsulation } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatTooltipModule } from '@angular/material/tooltip'
import { FormlyModule } from '@ngx-formly/core'
import { ErrorMessageComponent } from 'app/shared/error-message/error-message.component'
import { BaseCustomFieldComponent } from '../base-custom-field.component'
import { CustomInputNoteComponent } from '../custom-note/custom-note.component'

@Component({
  selector: 'app-custom-selection-button',
  standalone: true,
  imports: [
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule,
    MatButtonToggleModule,
    MatIconModule,
    MatTooltipModule,
    ErrorMessageComponent,
    NgIf,
    NgFor,
    CustomInputNoteComponent,
  ],
  templateUrl: './custom-selection-button.component.html',
  styleUrl: './custom-selection-button.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class CustomSelectionButtonComponent extends BaseCustomFieldComponent {
  constructor(private elRef: ElementRef) {
    super()
  }
  toggleActiveStyle(event: any, color): void {
    setTimeout(() => {
      const toggleButtons = this.elRef.nativeElement.querySelectorAll('.mat-button-toggle')
      if (this.props['multiple']) {
        if (event.source.checked) {
          event.source._elementRef.nativeElement.style.backgroundColor = color
        } else {
          event.source._elementRef.nativeElement.style.backgroundColor = ''
        }
      } else {
        toggleButtons.forEach(button => {
          if (button === event.source._elementRef.nativeElement) {
            button.style.backgroundColor = color
          } else if (!button.classList.contains('mat-button-toggle-checked')) {
            button.style.backgroundColor = ''
          }
        })
      }
    })
  }
}
