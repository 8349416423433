export enum CaslSubjects {
  //Menues for all business units
  MenuAOrganizations = 'menu.a_organizations',
  MenuAFarms = 'menu.a_farms',
  MenuAAuditingCompanies = 'menu.a_auditing_companies',
  MenuAAssistanceCompanies = 'menu.a_assistance_companies',
  MenuACatalogs = 'menu.a_catalogs',

  //Menues for a specific business unit
  MenuPlanning = 'menu.planning',
  MenuInventory = 'menu.inventory',
  MenuAdministration = 'menu.administration',
  MenuPersonal = 'menu.personal',
  MenuAnalytics = 'menu.analytics',
  MenuAssistance = 'menu.assistance',
  MenuIoT = 'menu.iot',
  MenuCertifications = 'menu.certifications',

  //Submenues
  SubmenuAgriculturalCycle = 'submenu.agricultural-cycle',
  SubmenuBatcheOfBovineAndPig = 'submenu.batche-of-bovine-and-pig',
  SubmenuActivities = 'submenu.activities',
  SubmenuInputs = 'submenu.inputs',
  SubmenuOutputs = 'submenu.outputs',
  SubmenuStock = 'submenu.stock',
  SubmenuAttachedProducers = 'submenu.attached-producers',
  SubmenuFarms = 'submenu.farms',
  SubmenuReports = 'submenu.reports',
  SubmenuIndicadores = 'submenu.indicators',
  SubmenuSimulations = 'submenu.simulation',
  SubmenuCertificationManagement = 'submenu.certification-management',
  SubmenuDevices = 'submenu.devices',

  //Widgets dashboard
  widgetHectares = 'widget-hectares',
  widgetFarms = 'widget-farms',
  widgetCollaborators = 'widget-collaborators',
  widgetComplementaryAreas = 'widget-complementary-areas',
  widgetActivitiesSummary = 'widget-activity-summary',
  widgetProductiveCycles = 'widget-productive-cycles',
  widgetSupplies = 'widget-supplies',
  widgetDistributionActivityTypes = 'widget-distribution-activity-types',
  widgetSchedule = 'widget-schedule',

  //Features
  None = 'none',
  AttachedFarms = 'attached-farms',
  AttachedProducers = 'attached-producers',
  ProductionAreas = 'production-areas',
  ComplementaryAreas = 'complementary-areas',
  WaterSources = 'water-sources',
  Foods = 'foods',
  Meds = 'meds',
  Fertilizers = 'fertilizers',
  Organizations = 'organizations',
  ProducerFarms = 'producer-farms',
  Profile = 'profile',
  Security = 'security',
  Options = 'options',
  PlanBilling = 'planBilling',
  Pesticide = 'pesticide',
  OtherSupplies = 'other-supplies',
  Processedproducts = 'processed-products',
  SeedsSeedlingsTrees = 'seeds-seedlings-trees',
  Customers = 'customers',
  Supplier = 'supplier',
  Positions = 'positions',
  Activitytypes = 'activity-types',
  Bovinoes = 'bovinoes',
  Pigs = 'pigs',
  AgriculturalCycles = 'agricultural-cycles',
  AreaAnalysis = 'area-analysis',
  AreaPermission = 'area-permissions',
  BatchesOfBovinoesAndPigs = 'batches-of-bovinoes-and-pigs',
  MachineryEquipment = 'machinery-equipments',
  MaintenanceCalibrations = 'maintenance-calibrations',
  HarvestProducts = 'harvest-products',
  AgriculturalCycleHarvest = 'harvest',
  ProductQuality = 'product-quality',
  AnimalProducts = 'animal-products',
  LeftoverProducts = 'leftover-products',
  Inputs = 'inputs',
  Outputs = 'outputs',
  Stock = 'stock',
  Indicator = 'indicator',
  FarmAnimals = 'farm-animals',
  Collaborators = 'collaborators',
  Studies = 'person-studies',
  PersonBackgrounds = 'person-backgrounds',
  ByProducts = 'byproducts',
  AgriculturalCyclePhenological = 'agricultural-cycle-phenological',
  Activities = 'activities',
  ViewIndicators = 'view-indicators',

  //Reports
  Reports = 'reports',
  FarmResume = 'farm-resume',
  CollaboratorResume = 'collaborator-resume',
  LifeSheetOfMachinesEquipmentAndTools = 'life-sheet-of-machines-equipment-and-tools',
  PurchaseHistory = 'purchase-history',
  SalesHistory = 'sales-history',
  CropHistory = 'crop-history',
  HistoryOfBatchesOfAnimals = 'History-of-batches-of-animals',
  FileManager = 'FileManager',
  SubmenuIndicators = 'SubmenuIndicators',
  activityReport = 'activity-report',
  attachedProducerReport = 'attached-producer-report',

  MinimumInventory = 'minimum-inventory',
  sellingusedproducts = 'selling-used-products',

  //Certifications
  Simulations = 'simulations',
  CertificationManagement = 'certification-management',

  //IoT
  Devices = 'devices',
  DashboardIOT = 'dashboard-iot',
  DeviceVariables = 'device-variables',
  Alerts = 'alerts',
  Measurements = 'measurements',
  AlertSettings = 'alert-settings',
  AlertConditions = 'alert-conditions',
}
