import { Component, ViewEncapsulation } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatTooltipModule } from '@angular/material/tooltip'
import { FormlyModule } from '@ngx-formly/core'
import { ErrorMessageComponent } from 'app/shared/error-message/error-message.component'
import { BaseCustomFieldComponent } from '../base-custom-field.component'
import { NgIf } from '@angular/common'
import { CustomInputNoteComponent } from '../custom-note/custom-note.component'

@Component({
  selector: 'app-custom-input-text',
  standalone: true,
  imports: [
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule,
    MatInputModule,
    MatIconModule,
    MatTooltipModule,
    ErrorMessageComponent,
    NgIf,
    CustomInputNoteComponent,
  ],
  templateUrl: './custom-input-text.component.html',
  styleUrl: './custom-input-text.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class CustomInputTextComponent extends BaseCustomFieldComponent {}
