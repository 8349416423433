import { NavigationMockApi } from 'app/mock-api/common/navigation/api';
import { ShortcutsMockApi } from 'app/mock-api/common/shortcuts/api';
import { PermissionsMockApi } from 'app/mock-api/common/permissions/api';
import { DashboardMockApi } from './common/dashboard/api';
import { BelongingTypeMockApi } from './common/belonging-types/api';
import { FileManagerMockApi } from './common/file-manager/api';
import { IndexCategoriesMockApi } from './common/index-categories/api';
import { MaintenanceCalibrationsMockApi } from './common/maintenance-calibrations-types/api';
import { NotificationsMockApi } from './common/notifications/api';
import { SeedSeedlingTreeMockApi } from './common/seed-seedling-tree-types/api';

export const mockApiServices = [
    NavigationMockApi,
    NotificationsMockApi,
    ShortcutsMockApi,
    PermissionsMockApi,
    BelongingTypeMockApi,
    DashboardMockApi,
    IndexCategoriesMockApi,
    MaintenanceCalibrationsMockApi,
    FileManagerMockApi,
    SeedSeedlingTreeMockApi
];
