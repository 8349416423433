import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BreadcrumbComponent, BreadcrumbItemDirective } from 'xng-breadcrumb';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  selector: 'app-breadcrumbs',
  standalone: true,
  imports: [RouterModule, BreadcrumbComponent, BreadcrumbItemDirective, TranslocoModule, CommonModule],
  templateUrl: './app-breadcrumbs.component.html',
  styleUrls: ['./app-breadcrumbs.component.scss']
})
export class AppBreadcrumbsComponent {
  @Input() translateAlias: string;
}
