import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { OdataResponse } from '../interfaces/others/odata-response.interface';
import { SystemQueryOptions } from '../interfaces/others/system-query-options.interface';

/**
 * Base class for services that consume rest api
 */
@Injectable()
export abstract class BaseService<T> {
  // Protected
  protected _itemsSubject = new BehaviorSubject<T[]>([]);
  protected _countSubject = new BehaviorSubject<number>(0);

  /**
   * Constructor
   */
  constructor() {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for count items
   */
  get count$(): Observable<Number> {
    return this._countSubject.asObservable();
  }

  /**
   * Getter for items
   */
  get items$(): Observable<T[]> {
    return this._itemsSubject.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Http request for get items
   * Behaviors that must be implemented
   * @param options
   * @returns
   */
  abstract get(options: SystemQueryOptions): Observable<OdataResponse<T>>;

  /**
   * Http request for get one item
   * Behaviors that must be implemented
   * @param options
   * @returns
   */
  getItem(options: SystemQueryOptions): Observable<T> {
    throw new Error('Method not implemented.');
  }

  /**
   * Http request for delete item
   * Behaviors that must be implemented
   * @param options
   * @returns
   */
  delete(item: T): Observable<OdataResponse<T>> {
    throw new Error('Method not implemented.');
  }

  /**
   * Resets the subjects by clearing the items and count.
   */
  resetSubjects(): void {
    this._itemsSubject.next([]);
    this._countSubject.next(0);
  }
}
