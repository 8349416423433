import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { OdataResponse } from '../../../core/interfaces/others/odata-response.interface';
import { SeedSeedingTreeType } from 'app/core/interfaces/others/seed-seedling-tree-type.interface';
import { seedsSeedlingsTreesType } from './data';

@Injectable({
    providedIn: 'root'
})
export class SeedSeedlingTreeMockApi
{
    private readonly _defaultValue: SeedSeedingTreeType[] = seedsSeedlingsTreesType;

    /**
     * Constructor
     */
    constructor(private _fuseMockApiService: FuseMockApiService)
    {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void
    {
        // -----------------------------------------------------------------------------------------------------
        // @ Navigation - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
        .onGet('api/common/seed-seedling-tree-types')
        .reply(() => {

            let response: OdataResponse<SeedSeedingTreeType>;
            let value: SeedSeedingTreeType[] = cloneDeep(this._defaultValue);
            
            response = {
                "@odata.count": value.length,
                value: value
            };

            // Return the response
            return [
                200,
                response
            ];
        });
    }
}
