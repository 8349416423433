import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
//import * as Sentry from '@sentry/browser'
import { environment } from 'environments/environment';


@Injectable({
    providedIn: 'root'
})
export class ErrorLoggingService {

  constructor(private _injector: Injector,){
    /*
    Sentry.init({
        dsn: environment.dsnSentry,
        environment: environment.name
      })
      */
  }

  logError(error: any)
  {
    if(environment.production)
    {
      const router = this._injector.get(Router);

      const reportOject = {
          status: (error.status) ? error.status : null,
          name: error.name,
          message: error.message,
          httpErrorCode: (error.httpErrorCode) ? error.httpErrorCode : error.httpErrorCode,
          stack: (error.stack) ? error.stack : null,
          url: location.href,
          route: router.url,
      };

      /*
      // todo: define user data a other tags to send to sentry
      Sentry.configureScope(scope => {
          scope.setUser({
            id: '1',
            username: 'diego.villegas@tramasoft.com',
            email: 'diego.villegas@tramasoft.com',
          })
      })

      Sentry.captureException(reportOject);
      */
    }
    else
    {
        console.error(error);
    }

  }







}