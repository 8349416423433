import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, tap } from 'rxjs';
import { GenericUtil } from 'app/core/utils/generic.util';
import { SystemQueryOptions } from 'app/core/interfaces/others/system-query-options.interface';
import { OdataResponse } from 'app/core/interfaces/others/odata-response.interface';
import { BaseService } from 'app/core/base/base.service';
import { UserSettings } from 'app/core/interfaces/identity/user-settings.interface';

import { getBrowserLang, TranslocoService } from '@jsverse/transloco';
import { L10n, setCulture, loadCldr } from '@syncfusion/ej2-base';
import es from '@syncfusion/ej2-locale/src/es.json';
import en from '@syncfusion/ej2-locale/src/en-US.json';
import * as numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import * as gregorianEs from 'cldr-data/main/es/ca-gregorian.json';
import * as numbersEs from 'cldr-data/main/es/numbers.json';
import * as timeZoneNamesEs from 'cldr-data/main/es/timeZoneNames.json';
import * as gregorianEn from 'cldr-data/main/en/ca-gregorian.json';
import * as numbersEn from 'cldr-data/main/en/numbers.json';
import * as timeZoneNamesEn from 'cldr-data/main/en/timeZoneNames.json';
import { FuseConfigService } from '@fuse/services/config';
import { GenericConstants } from 'app/core/constants/generic.constants';
import { Languages } from 'app/core/enums/languages.enum';
import { Themes } from 'app/core/enums/themes.enum';
import { User } from 'app/core/interfaces/identity/user.interface';
import { AuthService } from 'app/core/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserSettingsService extends BaseService<UserSettings> {
  private _user: User = null;

  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    protected _genericUtil: GenericUtil,
    private _translocoService: TranslocoService,
    private _fuseConfigService: FuseConfigService,
    private _authService: AuthService
  ) {
    super();

    this._authService.user$.subscribe((user) => {
      if (user) {
        // Manejar los datos del usuario
        this._user = user;

        // Set preferred language
        if (user.userSettings && user.userSettings.preferredLanguage) {
          this.changeLanguage(user.userSettings.preferredLanguage);
        }

        // Set preferred theme
        if (user.userSettings && user.userSettings.preferredTheme) {
          this.changeScheme(user.userSettings.preferredTheme);
        }
      }
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get countries
   * @param options
   * @returns
   */
  get(options: SystemQueryOptions): Observable<OdataResponse<UserSettings>> {
    const url = this._genericUtil.generateUrlOdataQuery(
      environment.apiItagueUrl + 'identity/accounts/user-settings',
      options
    );
    return this._httpClient.get<any>(url).pipe(
      tap((response: OdataResponse<UserSettings>) => {
        this._itemsSubject.next(response.value);
        this._countSubject.next(response['@odata.count']);
      })
    );
  }

  /**
   * Put item
   */
  put(item: any): Observable<UserSettings> {
    const url = environment.apiItagueUrl + 'identity/accounts/user-settings';
    return this._httpClient.put<UserSettings>(url, item).pipe(
      tap((response: UserSettings) => {
        let user: User = this._authService.user;
        user.userSettings = response;
        this._authService.user = user;
      })
    );
  }

  /**
   * Set application language settings based on user preferences or browser language
   */
  public setLanguage(): void {
    const browserLang = getBrowserLang();

    let user: User = this._authService.user;
    const userLang = user?.userSettings?.preferredLanguage;

    if (userLang) {
      if (GenericConstants.APP_LANGUAGES.find((x) => x === userLang)) {
        this.changeLanguage(userLang);
      } else {
        if (GenericConstants.APP_LANGUAGES.find((x) => x === browserLang)) {
          this.changeLanguage(browserLang);
        } else {
          this.changeLanguage(GenericConstants.DEFAULT_LANGUAGE);
        }
      }
    } else {
      if (GenericConstants.APP_LANGUAGES.find((x) => x === browserLang)) {
        this.changeLanguage(browserLang);
      } else {
        this.changeLanguage(GenericConstants.DEFAULT_LANGUAGE);
      }
    }
  }

  /**
   * Change language using transloco service subscriber
   * @param language
   */
  public changeLanguage(language: string): void {
    if (language == Languages.EsCo) {
      language = Languages.Es;
    }

    this._translocoService.setActiveLang(language);
    let lang = language === Languages.Es ? es : en;
    setCulture(language);
    L10n.load(lang);

    // Angular CLI 8.0 and above versions
    if (language == Languages.Es) {
      loadCldr(numberingSystems['default'], gregorianEs['default'], numbersEs['default'], timeZoneNamesEs['default']);
    } else {
      loadCldr(numberingSystems['default'], gregorianEn['default'], numbersEn['default'], timeZoneNamesEn['default']);
    }
  }

  /**
   * Set application scheme settings based on user preferences
   */
  public setScheme() {
    let user: User = this._authService.user;
    const userTheme = user?.userSettings?.preferredTheme ?? '';

    if (userTheme) {
      if (Object.values(Themes).includes(userTheme as Themes)) {
        this.changeScheme(userTheme);
      } else {
        this.changeScheme(Themes.Auto);
      }
    } else {
      this.changeScheme(Themes.Auto);
    }
  }

  /**
   * Change scheme using fuse config service subscriber
   * @param scheme
   */
  public changeScheme(scheme: string) {
    this._fuseConfigService.config = { scheme };
  }
}
