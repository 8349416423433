import { Component, EventEmitter, Output } from '@angular/core';
import { AppAlertService } from '../services/others/app-alert.service';
import { TranslocoService } from '@jsverse/transloco';
import { CaslActions } from '../enums/casl-actions.enum';
import { CaslSubjects } from '../enums/casl-subjects.enum';
import { Genders } from '../enums/genders.enum';
import { GenericConstants } from '../constants/generic.constants';
import { OptionButtonList } from 'app/shared/options-button-list/option-button-list.interface';

/**
 * Base class for crud operations (List and form base components)
 */
@Component({
  selector: 'app-crud-base',
  template: ` <p>base works!</p> `,
  styles: []
})
export abstract class CrudBaseComponent {
  protected _appAlertService: AppAlertService;
  protected _translocoService: TranslocoService;

  public itemName: string = '';
  public itemGenre: string = '';
  public translateAlias: string = '';
  public caslSubject: string = '';
  public CASL_ACTIONS = CaslActions;
  public CASL_SUBJECTS = CaslSubjects;
  public GENDERS = Genders;
  public DATE_FORMAT = GenericConstants.MY_DATE_FORMAT.display.dateInput;
  protected _itemsForOptionButton: OptionButtonList[] = [];

  constructor() {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Protected methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Set permissions
   */
  protected _setPermissions(): any {}

  /**
   * Call a function with a name and arguments
   */
  protected _handleActionRequested(event: { functionName: string; args: any[] }) {
    if (typeof this[event.functionName] === 'function') {
      this[event.functionName](...event.args);
    } else {
      this._appAlertService.showError('The function named ' + name + ' must be implemented in the child class.');
    }
  }

  /**
   * Method used to configure required aspects in the class
   */
  protected _configure() {}
}
