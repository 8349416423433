<div class="app-modal-container">

  <!-- Title -->
  <div class="app-modal-header">
    <div class="app-modal-title">
      <ng-container *transloco="let t">
        {{ t('icro.title') }}
      </ng-container>
    </div>
  </div>

  <!-- Content -->
  <div class="app-modal-form-container" mat-dialog-content>
    <img id="image" #image loading="lazy" [src]="sanitizedUrl" (load)="onLoad()" crossorigin="anonymous" />
  </div>

  <!-- Actions -->
  <div class="flex items-center justify-between px-6 py-4 bg-gray-50 dark:bg-black dark:bg-opacity-10;">

    <ng-container *transloco="let t">

      <div class="hidden sm:block space-x-2">
        <button mat-stroked-button (click)="rotateRight()">
          <mat-icon>rotate_right</mat-icon>
        </button>

        <button mat-stroked-button (click)="rotateLeft()">
          <mat-icon>rotate_left</mat-icon>
        </button>

        <button mat-stroked-button (click)="zoomIn()">
          <mat-icon>zoom_in</mat-icon>
        </button>

        <button mat-stroked-button (click)="zoomOut()">
          <mat-icon>zoom_out</mat-icon>
        </button>

        <!-- Reset -->
        <button mat-stroked-button (click)="flipH()" matTooltip="Reset">
          <mat-icon matTooltip="carreta del tooltip">swap_horiz</mat-icon>
        </button>

        <!-- Reset -->
        <button mat-stroked-button (click)="flipV()" matTooltip="Reset">
          <mat-icon matTooltip="carreta del tooltip">swap_vert</mat-icon>
        </button>


        <!-- Reset -->
        <button mat-stroked-button (click)="reset()" matTooltip="Reset">
          <mat-icon matTooltip="carreta del tooltip">refresh</mat-icon>
        </button>
      </div>


      <div class="justify-center sm:justify-end space-x-3">

        <!-- Cancel -->
        <button mat-stroked-button [matDialogClose]="confirmDialogResponses.Cancelled">
          <span class="app-button-text">{{ t('buttons.labels.close') }}</span>
        </button>

        <!-- Confirm -->
        <button mat-flat-button [color]="'primary'" (click)="crop()">
          <span class="app-button-text">{{ t('buttons.labels.save') }}</span>
        </button>
      </div>

    </ng-container>
  </div>
</div>