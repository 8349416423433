<div class="wrapper">
  <div class="custom-signature">
    <mat-label>{{ props['label'] }}</mat-label>
    <p class="custom-description">{{ props['description'] }}</p>
    <div class="custom-signature__wrapper">
      <div class="flex gap-3">
        <button (click)="inputFile.click()" color="primary" mat-flat-button type="button">
          <mat-icon class="app-button-icon" [svgIcon]="'cloud_upload'"></mat-icon>
          <span class="app-button-text">{{ props['button']['label_upload'] }}</span>
        </button>
        <button (click)="onDraw()" color="primary" mat-flat-button type="button">
          <mat-icon class="app-button-icon" [svgIcon]="'draw'"></mat-icon>
          <span class="app-button-text">{{ props['button']['label_draw'] }}</span>
        </button>
      </div>

      <input
        (change)="onFileChange($event)"
        class="custom-signature__input"
        #inputFile
        type="file"
        [formlyAttributes]="field"
        [accept]="props['filesTypes']" />
    </div>
    <div class="custom-signature__wrapper-image">
      <img [src]="file?.image" width="150" />
    </div>
    <div>
      @if(draw){
      <div id="signature-control">
        <div class="e-sign-heading"></div>
        <canvas ejs-signature #signature id="signature" (change)="change($event)"></canvas>
        <div class="mt-3 text-right">
          <button (click)="clear()" color="primary" mat-flat-button type="button">
            <mat-icon class="app-button-icon" [svgIcon]="'delete'"></mat-icon>
            <!-- <span class="app-button-text">{{ props['button']['label_draw'] }}</span> -->
          </button>
        </div>
      </div>
      }
    </div>
    <div></div>
  </div>
</div>
