import { ErrorHandler, Injectable, Injector } from "@angular/core";
import { AppAlertService } from "../others/app-alert.service";
import { ErrorService } from './error-service';
import { ErrorLoggingService } from './error-logging.service';
import { HttpErrorResponse } from "@angular/common/http";


@Injectable({
  providedIn: 'root'
})
export class AppErrorHandlerService extends ErrorHandler {

    lastErrorMessage: string = undefined;

    constructor(private _errorService: ErrorService, 
              private _errorLoggingService: ErrorLoggingService, 
              private _appAlertService: AppAlertService) 
    {
      super();
    }

  
    handleError(error: any): void 
    {

      let messages: string[];
      let title;

      if (error instanceof HttpErrorResponse) 
      {
        // Server Error
        title = this._errorService.getServerTitle(error);
        messages = this._errorService.getServerMessages(error);
        this._appAlertService.showErrorDialog(title, messages);
      } 
      else 
      {
          // Client Error
          if(!this.omitShowError(error))
          {
            title = this._errorService.getClientTitle(error);
            messages = this._errorService.getClientMessages(error);
            this._appAlertService.showErrorDialog(title, messages);
          }
      }

      // Always log errors
      this._errorLoggingService.logError(error);
    }

    omitShowError(error):boolean
    {
      const message: string = error.message ? error.message : error.toString();

      if(message.includes('eaflet_pos'))
      {
        return true;
      }
      else if(message == this.lastErrorMessage)
      {
        return true;
      }
      else
      {
        this.lastErrorMessage = error.message ? error.message : error.toString();
      }

      return false;
    }
  }