<div class="wrapper">
  @if(type === 'date'){
  <mat-form-field>
    <mat-label>{{ props['label'] }}</mat-label>
    <input matInput [matDatepicker]="datePicker" [id]="keyField" [formControl]="formControl" [formlyAttributes]="field"
      [min]="props['minDate']" [max]="props['maxDate']" [placeholder]="props['dateFormat']"
      [required]="props['required']" />
    <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
    <mat-datepicker #datePicker></mat-datepicker>
    <mat-error *ngIf="showError">{{ errorMessage }}</mat-error>
  </mat-form-field>
  <p class="custom-description">{{ props['description'] }}</p>
  }@else if(type === 'time'){
  <mat-form-field>
    <mat-label>{{ props['label'] }}</mat-label>
    <input matInput [id]="keyField" [formControl]="formControl" [formlyAttributes]="field" [min]="props['minStartTime']"
      [max]="props['maxEndTime']" [placeholder]="props['dateFormat']" [required]="props['required']"
      [ngxMatTimepicker]="startTime" />
    <ngx-mat-timepicker #startTime></ngx-mat-timepicker>
    <mat-icon matSuffix (click)="startTime.open()">watch_later</mat-icon>
    <mat-error *ngIf="showError">{{ errorMessage }}</mat-error>
  </mat-form-field>
  <p class="custom-description">{{ props['description'] }}</p>
  }@else if(type === 'dateTime'){
  <div class="grid grid-cols-1 gap-1 info-datetime">
    <mat-label>{{ props['label'] }}</mat-label>
    <mat-hint class="custom-description">{{ props['description'] }}</mat-hint>
  </div>
  <div class="grid grid-cols-2 gap-1">
    <ng-container *ngFor="let customField of field['fieldGroup']">
      @if(customField.props['type'] == 'date'){
      <mat-form-field>
        <mat-label>{{ customField.props['date']['label'] }}</mat-label>
        <input matInput [matDatepicker]="datePicker" [id]="customField.key.toString()"
          [formControl]="getFormControl(customField.formControl)" [formlyAttributes]="customField"
          [placeholder]="customField.props['date']['dateFormat']" [required]="customField.props['required']" />
        <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
        <mat-datepicker #datePicker></mat-datepicker>
        <mat-icon class="app-form-icon-info" [svgIcon]="'mat_outline:info'"
          [matTooltip]="customField.props['date']['tooltip']" matSuffix></mat-icon>
        <mat-error *ngIf="showErrorDate('date')">{{ errorDateMessage('date') }}</mat-error>
      </mat-form-field>
      } @if(customField.props['type'] == 'time'){
      <mat-form-field>
        <mat-label>{{ customField.props['time']['label'] }}</mat-label>
        <input matInput [id]="customField.key.toString()" [formControl]="getFormControl(customField.formControl)"
          [formlyAttributes]="customField" [placeholder]="customField.props['time']['dateFormat']"
          [required]="customField.props['required']" [ngxMatTimepicker]="startTime" />
        <ngx-mat-timepicker #startTime></ngx-mat-timepicker>
        <mat-icon matSuffix (click)="startTime.open()">watch_later</mat-icon>
        <mat-icon class="app-form-icon-info" [svgIcon]="'mat_outline:info'"
          [matTooltip]="customField.props['time']['tooltip']" matSuffix></mat-icon>
        <mat-error *ngIf="showErrorDate('time')">{{ errorDateMessage('time') }}</mat-error>
      </mat-form-field>
      }
    </ng-container>
  </div>
  }
  <app-custom-note (sendText)="getNote($event)" [textValue]="props['note']" (sendImage)="getImage($event)"
    [formDisable]="formControl"></app-custom-note>
</div>