import { Injectable } from '@angular/core';
import { GenericConstants } from 'app/core/constants/generic.constants';
import * as CryptoJS from 'crypto-js';  


@Injectable({
  providedIn: 'root',
})
export class CryptService {
  genericConstans = GenericConstants;

  constructor(

  ) {}

  /**
   * Encripta texto basado en un salt establecido en contanstes genericas
   * @param plainText
   * @returns
   */
  encrypt(plainText: string): string {
    
    //return '';
    return CryptoJS.DES.encrypt(
      plainText,
      this.genericConstans.SALT_ENCRYPTION
    ).toString();
  }


  /**
   * Desencriptar mensaje basado en un salt
   * @param encryptedText
   * @returns
   */
  decrypt(encryptedText: string): string {
    return CryptoJS.AES.decrypt(
      encryptedText,
      this.genericConstans.SALT_ENCRYPTION
    ).toString(CryptoJS.enc.Utf8);
  }


  /**
   * Cipher text with simple algorithm
   * @param plainText 
   * @returns 
   */
  public cipher(plainText: string): string {

    const modifyText:string = this.genericConstans.PREFIX_ENCRYPTION + plainText + this.genericConstans.SUFIX_ENCRYPTION;

    let array = Array.from(modifyText);
    let secretArray:string[] = Array.from(this.genericConstans.SALT_ENCRYPTION);
    let secretLength: number = this.genericConstans.SALT_ENCRYPTION.length;

    for (let i: number = 0; (i < modifyText.length); i++) 
    {
        array[i] = (array[i] + secretArray[(i % secretLength)]);
    }

    return array.join('');
  }


  /**
   * Dechiper text with simple algorithm
   * @param cipherText 
   * @returns 
   */
  public deCipher(cipherText:string):string|null
  {
    let decipherTextArray: string[] = [];
    let decipherSaltArray: string[] = [];
    let secretArray:string[] = Array.from(this.genericConstans.SALT_ENCRYPTION);
    let secretLength: number = this.genericConstans.SALT_ENCRYPTION.length;

    for (let i: number = 0; (i < cipherText.length); i++)
    {
      if(i % 2 == 0)
      {
        decipherTextArray.push(cipherText[i])
      }
      else
      {
        decipherSaltArray.push(cipherText[i])
      }
    }

    for (let i: number = 0; (i < decipherSaltArray.length); i++)
    {
      if(decipherSaltArray[i] != secretArray[(i % secretLength)])
      {
        return null;
      }
    }

    const modifyText:string = decipherTextArray.join('');

    if(!modifyText.includes(this.genericConstans.PREFIX_ENCRYPTION) || !modifyText.includes(this.genericConstans.SUFIX_ENCRYPTION))
    {
      return null;
    }

    const decipherText:string = decipherTextArray.join('').replace(this.genericConstans.PREFIX_ENCRYPTION, '').replace(this.genericConstans.SUFIX_ENCRYPTION, '');

    return decipherText;
      
  }

}
