import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { BelongingType } from 'app/core/interfaces/management/belonging-type.interface';
import { maintenanceCalibrationsTypes } from './data';
import { OdataResponse } from '../../../core/interfaces/others/odata-response.interface';
import { MaintenanceCalibrationsType } from 'app/core/interfaces/others/maintenance-calibrations-type.interface';

@Injectable({
    providedIn: 'root'
})
export class MaintenanceCalibrationsMockApi
{
    private readonly _defaultValue: MaintenanceCalibrationsType[] = maintenanceCalibrationsTypes;

    /**
     * Constructor
     */
    constructor(private _fuseMockApiService: FuseMockApiService)
    {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void
    {
        // -----------------------------------------------------------------------------------------------------
        // @ Navigation - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
        .onGet('api/common/maintenance-calibrations')
        .reply(() => {

            let response: OdataResponse<MaintenanceCalibrationsType>;
            let value: MaintenanceCalibrationsType[] = cloneDeep(this._defaultValue);
            
            response = {
                "@odata.count": value.length,
                value: value
            };

            // Return the response
            return [
                200,
                response
            ];
        });
    }
}
