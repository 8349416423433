import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import moment from 'moment';
import { ValidationParams } from '../interfaces/others/validation-params.interface';
import { GenericConstants } from '../constants/generic.constants';

@Injectable({
  providedIn: 'root'
})
export class ErrorUtil {
  /**
   * Allows you to set the parameters for the translation according to the type of error
   * @param name Control name for translation
   * @param gender Control name gender
   * @param control Form control
   * @param type Type of validation
   * @returns ValidationParams object with params for translation
   */
  getParams(name: string, gender: string, control: AbstractControl, type: string): ValidationParams {
    let minDate: string = '';
    let minActual: string = '';
    let maxDate: string = '';
    let maxActual: string = '';

    if (control.hasError(type)) {
      switch (type) {
        case 'min':
          return { name: name, gender: gender, min: control.errors.min.min, actual: control.errors.min.actual };

        case 'max':
          return { name: name, gender: gender, max: control.errors.max.max, actual: control.errors.max.actual };

        case 'minlength':
          return {
            name: name,
            gender: gender,
            requiredLength: control.errors.minlength.requiredLength,
            actualLength: control.errors.minlength.actualLength
          };

        case 'maxlength':
          return {
            name: name,
            gender: gender,
            requiredLength: control.errors.maxlength.requiredLength,
            actualLength: control.errors.maxlength.actualLength
          };

        case 'pattern':
          return {
            name: name,
            gender: gender,
            requiredPattern: control.errors.pattern.requiredPattern,
            actualValue: control.errors.pattern.actualValue
          };

        case 'fileformat':
          return { name: name, gender: gender, currentFileExt: control.errors.currentFileExt };

        case 'filesize':
          return { name: name, gender: gender, fileSize: control.errors.fileSize };

        case 'matDatepickerMin':
          minDate = moment(control.errors.matDatepickerMin.min).format(
            GenericConstants.MY_DATE_FORMAT.display.dateInput
          );
          minActual = moment(control.errors.matDatepickerMin.actual).format(
            GenericConstants.MY_DATE_FORMAT.display.dateInput
          );
          return { name: name, gender: gender, min: minDate, actual: minActual };

        case 'matDatepickerMax':
          maxDate = moment(control.errors.matDatepickerMax.max).format(
            GenericConstants.MY_DATE_FORMAT.display.dateInput
          );
          maxActual = moment(control.errors.matDatepickerMax.actual).format(
            GenericConstants.MY_DATE_FORMAT.display.dateInput
          );
          return { name: name, gender: gender, max: maxDate, actual: maxActual };

        case 'matDatetimePickerMin':
          minDate = moment(control.errors.matDatetimePickerMin.min).format(
            GenericConstants.MY_DATE_FORMAT.display.dateInput
          );
          minActual = moment(control.errors.matDatetimePickerMin.actual).format(
            GenericConstants.MY_DATE_FORMAT.display.dateInput
          );
          return { name: name, gender: gender, min: minDate, actual: minActual };

        case 'matDatetimePickerMax':
          maxDate = moment(control.errors.matDatetimePickerMax.max).format(
            GenericConstants.MY_DATE_FORMAT.display.dateInput
          );
          maxActual = moment(control.errors.matDatetimePickerMax.actual).format(
            GenericConstants.MY_DATE_FORMAT.display.dateInput
          );
          return { name: name, gender: gender, max: maxDate, actual: maxActual };

        default:
          return { name: name, gender: gender };
      }
    }
  }

  /**
   * Indicates whether the error message should be displayed or not. Only the first error message that has control is displayed
   * @param control Form control
   * @param type Type of validation
   * @returns True o False
   */
  public showErrorMessage(control: AbstractControl, type: string): boolean {
    if (control.errors !== null && (control.touched || control.dirty)) {
      let index = Object.keys(control.errors).indexOf(type);
      if (index === 0) {
        return true;
      }
    } else {
      return false;
    }
  }

  /**
   * Indicates if control has an invalid state
   * @param control Form control
   * @returns True o False
   */
  public isInvalid(control: AbstractControl): boolean {
    return control.invalid && (control.dirty || control.touched);
  }
}
