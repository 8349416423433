import { APP_INITIALIZER, EnvironmentProviders, importProvidersFrom, inject, LOCALE_ID, Provider } from '@angular/core';
import {
  TRANSLOCO_CONFIG,
  TRANSLOCO_FALLBACK_STRATEGY,
  TRANSLOCO_INTERCEPTOR,
  TRANSLOCO_LOADER,
  TRANSLOCO_MISSING_HANDLER,
  translocoConfig,
  TranslocoModule,
  TranslocoService
} from '@jsverse/transloco';
import { TranslocoHttpLoader } from 'app/core/transloco/transloco.http-loader';
import { provideTranslocoMessageformat } from '@jsverse/transloco-messageformat';
import { GenericConstants } from '../constants/generic.constants';
import { environment } from 'environments/environment';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorTranslatorService } from '../services/others/mat-paginator-translator.service';
import localesCo from '@angular/common/locales/es-CO';
import { registerLocaleData } from '@angular/common';
import { TranslocoCustomHandler } from './transloco.custom-handler';
import { TranslocoCustomInterceptor } from './transloco.custom-interceptor';
import { TranslocoCustomFallbackStrategy } from './transloco.custom-fallback-strategy';

registerLocaleData(localesCo);

export const provideTransloco = (): Array<Provider | EnvironmentProviders> => {
  return [
    importProvidersFrom(TranslocoModule),
    {
      // Provide the default Transloco configuration
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: [
          { id: 'en', label: 'English' },
          { id: 'es', label: 'Español' }
        ],
        defaultLang: GenericConstants.DEFAULT_LANGUAGE,
        fallbackLang: GenericConstants.DEFAULT_LANGUAGE,
        reRenderOnLangChange: true,
        prodMode: environment.production
      })
    },
    {
      // Provide the default Transloco loader
      provide: TRANSLOCO_LOADER,
      useClass: TranslocoHttpLoader
    },

    {
      // Preload the default language before the app starts to prevent empty/jumping content
      provide: APP_INITIALIZER,
      useFactory: () => {
        const translocoService = inject(TranslocoService);
        const defaultLang = translocoService.getDefaultLang();
        translocoService.setActiveLang(defaultLang);

        return () => translocoService.load(defaultLang).toPromise();
      },
      multi: true
    },
    {
      provide: TRANSLOCO_MISSING_HANDLER,
      useClass: TranslocoCustomHandler
    },
    {
      provide: TRANSLOCO_INTERCEPTOR,
      useClass: TranslocoCustomInterceptor
    },
    {
      provide: TRANSLOCO_FALLBACK_STRATEGY,
      useClass: TranslocoCustomFallbackStrategy
    },
    provideTranslocoMessageformat(),
    { provide: MatPaginatorIntl, useClass: MatPaginatorTranslatorService },
    { provide: LOCALE_ID, useValue: 'es-CO' }
  ];
};
