/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation'
import { CaslSubjects } from 'app/core/enums/casl-subjects.enum'

export const defaultNavigation: FuseNavigationItem[] = [
  {
    id: CaslSubjects.MenuAOrganizations,
    title: 'Organizaciones',
    type: 'basic',
    icon: 'mat_solid:business',
    link: '/organizations',
  },
  { id: CaslSubjects.MenuAFarms, title: 'Fincas', type: 'basic', icon: 'mat_outline:cottage', link: '/producer-farms' },
  {
    id: CaslSubjects.MenuAAuditingCompanies,
    title: 'Empresas de auditoría',
    type: 'basic',
    icon: 'check_circle_outline',
    link: '/dashboard',
  },
  {
    id: CaslSubjects.MenuAAssistanceCompanies,
    title: 'Empresas de asistencia',
    type: 'basic',
    icon: 'mat_outline:agriculture',
    link: '/dashboard',
  },
  {
    id: CaslSubjects.MenuACatalogs,
    title: 'Catálogos',
    type: 'basic',
    icon: 'mat_outline:format_list_numbered',
    link: '/catalogs',
  },
]

export const defaultNavigationEn: FuseNavigationItem[] = [
  {
    id: CaslSubjects.MenuAOrganizations,
    title: 'Organizations',
    type: 'basic',
    icon: 'mat_solid:business',
    link: '/organizations',
  },
  { id: CaslSubjects.MenuAFarms, title: 'Farms', type: 'basic', icon: 'mat_outline:cottage', link: '/producer-farms' },
  {
    id: CaslSubjects.MenuAAuditingCompanies,
    title: 'Auditing companies',
    type: 'basic',
    icon: 'check_circle_outline',
    link: '/dashboard',
  },
  {
    id: CaslSubjects.MenuAAssistanceCompanies,
    title: 'Assistance companies',
    type: 'basic',
    icon: 'mat_outline:agriculture',
    link: '/dashboard',
  },
  {
    id: CaslSubjects.MenuACatalogs,
    title: 'Catalogs',
    type: 'basic',
    icon: 'mat_outline:format_list_numbered',
    link: '/catalogs',
  },
]

export const dependentNavigation: FuseNavigationItem[] = [
  {
    id: CaslSubjects.MenuAdministration,
    title: 'Administración',
    type: 'group',
    icon: 'mat_outline:business',
    children: [
      {
        id: CaslSubjects.SubmenuAttachedProducers,
        title: 'Productores',
        type: 'basic',
        icon: 'heroicons_outline:user-group',
        link: '/management/attached-producers',
      },
      {
        id: CaslSubjects.SubmenuFarms,
        title: 'Fincas',
        type: 'basic',
        icon: 'mat_outline:cottage',
        link: '/management/attached-farms',
      },
    ],
  },
  {
    id: CaslSubjects.MenuPlanning,
    title: 'Planeación',
    type: 'group',
    icon: 'calendar_today',
    children: [
      {
        id: CaslSubjects.SubmenuAgriculturalCycle,
        title: 'Ciclos agrícolas',
        type: 'basic',
        icon: 'mat_outline:wb_sunny',
        link: '/planning/agricultural-cycles',
      },
      /*
      {
        id: CaslSubjects.SubmenuBatcheOfBovineAndPig,
        title: 'Lotes de animales',
        type: 'basic',
        icon: 'mat_outline:grid_on',
        link: '/planning/batches-of-bovinoes-and-pigs',
      },
      */
      {
        id: CaslSubjects.SubmenuActivities,
        title: 'Labores',
        type: 'basic',
        icon: 'heroicons_outline:check-circle',
        link: '/planning/activities',
      },
    ],
  },
  {
    id: CaslSubjects.MenuInventory,
    title: 'Inventario',
    type: 'group',
    icon: 'mat_outline:inventory_2',
    children: [
      {
        id: CaslSubjects.SubmenuInputs,
        title: 'Entradas',
        type: 'basic',
        icon: 'mat_outline:shopping_cart',
        link: '/inventory/inputs',
      },
      {
        id: CaslSubjects.SubmenuOutputs,
        title: 'Salidas',
        type: 'basic',
        icon: 'heroicons_outline:shopping-bag',
        link: '/inventory/outputs',
      },
      {
        id: CaslSubjects.SubmenuStock,
        title: 'Existencias',
        type: 'basic',
        icon: 'mat_outline:analytics',
        link: '/inventory/stock',
      },
    ],
  },
  {
    id: CaslSubjects.MenuPersonal,
    title: 'Colaboradores',
    type: 'group',
    icon: 'mat_outline:people_alt',
    children: [
      {
        id: CaslSubjects.Collaborators,
        title: 'Gestionar',
        type: 'basic',
        icon: 'manage_accounts',
        link: '/staff/manage',
      },
    ],
  },
  {
    id: CaslSubjects.MenuAssistance,
    title: 'Asistencia',
    type: 'basic',
    icon: 'mat_outline:agriculture',
    link: '/dashboard',
  },
  {
    id: CaslSubjects.MenuCertifications,
    title: 'Certificaciones',
    type: 'group',
    icon: 'mat_outline:inventory',
    children: [
      {
        id: CaslSubjects.Simulations,
        title: 'Simulaciones',
        type: 'basic',
        icon: 'mat_outline:bar_chart',
        link: '/certifications/simulations',
      },
      {
        id: CaslSubjects.CertificationManagement,
        title: 'Gestión de certificaciones',
        type: 'basic',
        icon: 'mat_outline:backup_table',
        link: '/certifications/certification-management',
      },
    ],
  },
  {
    id: CaslSubjects.MenuIoT,
    title: 'IoT',
    type: 'group',
    icon: 'wifi_tethering',
    children: [
      {
        id: CaslSubjects.DashboardIOT,
        title: 'Dashboard IoT',
        type: 'basic',
        icon: 'mat_outline:dashboard',
        link: '/iot/dashboard-iot',
      },
      {
        id: CaslSubjects.Alerts,
        title: 'Alertas',
        type: 'basic',
        icon: 'mat_outline:devices',
        link: '/iot/alerts',
      },
    ],
  },
  {
    id: CaslSubjects.MenuAnalytics,
    title: 'Analítica',
    type: 'group',
    icon: 'mat_outline:area_chart',
    children: [
      {
        id: CaslSubjects.SubmenuReports,
        title: 'Reportes',
        type: 'basic',
        icon: 'mat_outline:assessment',
        link: '/analytics/reports',
      },
      {
        id: CaslSubjects.SubmenuIndicators,
        title: 'Indicadores',
        type: 'basic',
        icon: 'mat_outline:traffic',
        link: '/dashboard',
      },
    ],
  },
]

export const dependentNavigationEn: FuseNavigationItem[] = [
  {
    id: CaslSubjects.MenuAdministration,
    title: 'Administration',
    type: 'group',
    icon: 'mat_outline:business',
    children: [
      {
        id: CaslSubjects.SubmenuAttachedProducers,
        title: 'Producers',
        type: 'basic',
        icon: 'heroicons_outline:user-group',
        link: '/management/attached-producers',
      },
      {
        id: CaslSubjects.SubmenuFarms,
        title: 'Farms',
        type: 'basic',
        icon: 'mat_outline:cottage',
        link: '/management/attached-farms',
      },
    ],
  },
  {
    id: CaslSubjects.MenuPlanning,
    title: 'Planning',
    type: 'group',
    icon: 'calendar_today',
    children: [
      {
        id: CaslSubjects.SubmenuAgriculturalCycle,
        title: 'Agricultural cycles',
        type: 'basic',
        icon: 'mat_outline:wb_sunny',
        link: '/planning/agricultural-cycles',
      },
      /*
      {
        id: CaslSubjects.SubmenuBatcheOfBovineAndPig,
        title: 'Animal batches',
        type: 'basic',
        icon: 'mat_outline:grid_on',
        link: 'planning/batches-of-bovinoes-and-pigs',
      },
      */
      {
        id: CaslSubjects.SubmenuActivities,
        title: 'Activities',
        type: 'basic',
        icon: 'heroicons_outline:check-circle',
        link: '/planning/activities',
      },
    ],
  },
  {
    id: CaslSubjects.MenuInventory,
    title: 'Inventory',
    type: 'group',
    icon: 'mat_outline:inventory_2',
    children: [
      {
        id: CaslSubjects.SubmenuInputs,
        title: 'Receipts',
        type: 'basic',
        icon: 'mat_outline:shopping_cart',
        link: '/inventory/inputs',
      },
      {
        id: CaslSubjects.SubmenuOutputs,
        title: 'Outputs',
        type: 'basic',
        icon: 'heroicons_outline:shopping-bag',
        link: '/inventory/outputs',
      },
      {
        id: CaslSubjects.SubmenuStock,
        title: 'Stock',
        type: 'basic',
        icon: 'mat_outline:analytics',
        link: '/inventory/stock',
      },
    ],
  },
  {
    id: CaslSubjects.MenuPersonal,
    title: 'Staff',
    type: 'group',
    icon: 'mat_outline:people_alt',
    children: [
      {
        id: CaslSubjects.Collaborators,
        title: 'Manage',
        type: 'basic',
        icon: 'manage_accounts',
        link: '/staff/manage',
      },
    ],
  },
  {
    id: CaslSubjects.MenuAssistance,
    title: 'Assistance',
    type: 'basic',
    icon: 'mat_outline:agriculture',
    link: '/dashboard',
  },
  {
    id: CaslSubjects.MenuCertifications,
    title: 'Certifications',
    type: 'group',
    icon: 'mat_outline:inventory',
    children: [
      {
        id: CaslSubjects.Simulations,
        title: 'Simulations',
        type: 'basic',
        icon: 'manage_accounts',
        link: '/certifications/simulations',
      },
      {
        id: CaslSubjects.CertificationManagement,
        title: 'Certification management',
        type: 'basic',
        icon: 'manage_accounts',
        link: '/certifications/certification-management',
      },
    ],
  },
  {
    id: CaslSubjects.MenuIoT,
    title: 'IoT',
    type: 'group',
    icon: 'calendar_today',
    children: [
      {
        id: CaslSubjects.DashboardIOT,
        title: 'Dashboard IoT',
        type: 'basic',
        icon: 'mat_outline:dashboard',
        link: '/iot/dashboard-iot',
      },
      {
        id: CaslSubjects.Alerts,
        title: 'Alerts',
        type: 'basic',
        icon: 'mat_outline:wb_sunny',
        link: '/iot/alerts',
      },
    ],
  },
  {
    id: CaslSubjects.MenuAnalytics,
    title: 'Analytics',
    type: 'group',
    icon: 'mat_outline:area_chart',
    children: [
      {
        id: CaslSubjects.SubmenuReports,
        title: 'Reports',
        type: 'basic',
        icon: 'mat_outline:assessment',
        link: '/analytics/reports',
      },
      {
        id: CaslSubjects.SubmenuIndicators,
        title: 'Indicators',
        type: 'basic',
        icon: 'mat_outline:traffic',
        link: '/dashboard',
      },
    ],
  },
]
