import { Component, ViewChild } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { Observable, Subject, takeUntil, tap, merge, take } from 'rxjs'
import { GenericConstants } from '../constants/generic.constants'
import { SystemQueryOptions } from '../interfaces/others/system-query-options.interface'
import { BaseService } from './base.service'
import { ConfigList } from '../interfaces/others/config-list.interface'
import { ConfirmDialogResponses } from '../enums/confirm-dialog-responses.enum'
import { CrudBaseComponent } from './crud-base.component'
import { Genders } from '../enums/genders.enum'
import { MatDrawer } from '@angular/material/sidenav'
import { ActionButton } from 'app/shared/actions-button-list/action-button.interface'
import { Ability } from '@casl/ability'
import { ActivatedRoute, Router } from '@angular/router'
import { ActionButtonCondition } from '../../shared/actions-button-list/action-button-data-condition.interface'

/**
 * Base class for page components that list information
 */
@Component({
  selector: 'app-list-base',
  template: ` <p>base works!</p> `,
  styles: [],
})
export abstract class ListBaseComponent<T> extends CrudBaseComponent {
  private _configList: ConfigList

  protected _sort: MatSort = new MatSort()
  protected _paginator: MatPaginator
  protected _lastSortUsed: MatSort = new MatSort()
  protected _lastPaginatorUsed: MatPaginator
  protected _unsubscribeAll: Subject<any> = new Subject<any>()
  protected _router: Router
  protected _route: ActivatedRoute
  protected _ability: Ability
  protected _listActions: ActionButton[] = []

  public numberItems: number
  public searchValue: string = ''
  public items$: Observable<T[]>
  public _matDrawer: MatDrawer

  /** Setters */

  @ViewChild(MatSort, { static: false }) set sort(val: MatSort) {
    if (val) {
      this._sort = val
      this._onSortOrPaginatorChanges()
    }
  }

  @ViewChild(MatPaginator, { static: false }) set paginator(val: MatPaginator) {
    if (val) {
      this._paginator = val
      this._onSortOrPaginatorChanges()
    }
  }
  @ViewChild('matDrawer', { static: false }) set matDrawer(val: MatDrawer) {
    if (val) {
      this._matDrawer = val
    }
  }

  /**
   * Constructor
   * @param _itemsService
   */
  constructor(protected _itemsService: BaseService<T>) {
    super()
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Show delete confirm dialog
   * @param item
   */
  confirmDelete(item: T) {
    if (this.itemName == '') {
      this._translocoService
        .selectTranslate(this.translateAlias + '.item-name')
        .subscribe(translate => (this.itemName = translate.toLowerCase()))
    }

    this._appAlertService
      .showWarningConfirm(this._translocoService.translate('generic-messages.titles.confirm-action'), [
        this._translocoService.translate('generic-messages.messages.confirm-action', {
          gender: this.itemGenre,
          name: this.itemName,
        }),
      ])
      .pipe(take(1))
      .subscribe({
        next: (response: string) => {
          if (response == ConfirmDialogResponses.Confirmed) {
            this._deleteItem(item)
          }
        },
      })
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Protected methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Actions to be executed in the ngOnInit method inside the child component
   * @param configList Config list
   * @param translateAlias  Alias of module translations
   * @param genre Item genre
   */
  protected baseNgOnInit(
    configList: ConfigList,
    translateAlias: string,
    gender: string = Genders.Other,
    caslSubject: string = this.CASL_SUBJECTS.None,
  ) {
    this.items$ = this._itemsService.items$
    this._configList = configList

    // Translates for module
    this.itemGenre = gender
    this.translateAlias = translateAlias
    this.caslSubject = caslSubject
    this._loadData()
    this._setPermissions()
    this._setDefaultConfiguration()
    this._configure()

    this._loadItemsPage()
  }

  /**
   * Actions to be executed in the ngOnDestroy method inside the child component
   */
  protected baseNgOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null)
    this._unsubscribeAll.complete()
  }

  /**
   * Method that sets the value of the search box
   * @param stringValue
   */
  protected _changeInputSearchValue(searchValue: string) {
    this.searchValue = searchValue
    if (this._paginator) {
      this._paginator.pageIndex = 0
    }
    this._loadItemsPage()
  }

  /**
   * Method that is responsible for opening a modal window with the search criteria advanced
   * @param open
   */
  protected _openAdvancedSearch(open: boolean) {
    this._appAlertService.showError('The method must be implemented in the child class.')
  }

  /**
   * Load initial data required for the list
   */
  protected _loadData(): void {}

  /**
   * Load items for an especific page
   * @param top number of items to show
   * @param skip number of items to skip
   * @param orderBy order by column
   * @param orderDirection order direction
   */
  protected _loadItemsPage(pageSize?: number, pageIndex?: number, orderBy?: string, orderDirection?: string) {
    if (this._configList) {
      if (this._paginator && !pageSize) {
        pageSize = this._paginator.pageSize
      }

      let filter: string = ''
      if (this._configList.obligatoryFilters) {
        filter = this._configList.obligatoryFilters
      }

      if (this.searchValue) {
        filter = (filter ? filter + ' and ' : '') + this._getFilterString()
      }

      const queryOptions: SystemQueryOptions = {
        select: this._configList.columns.join(','),
        expand: this._configList.expands ? this._configList.expands.join(',') : null,
        count: true,
        top: pageSize ? pageSize : GenericConstants.PAGE_SIZE_DEFAULT,
        skip: pageIndex ? pageIndex * pageSize : 0,
        filter: filter,
        orderby:
          orderBy && orderDirection
            ? `${orderBy} ${orderDirection}`
            : `${this._configList.defaultSort} ${this._configList.defaultOrder}`,
        otherParams: this._configList.otherParams ? this._configList.otherParams.join('&') : null,
      }

      this._itemsService.get(queryOptions).pipe(takeUntil(this._unsubscribeAll)).subscribe()

      this._itemsService.count$.pipe(takeUntil(this._unsubscribeAll)).subscribe({
        next: (response: number) => {
          this.numberItems = response ? response : 0
        },
      })
    }
  }

  /**
   * Delete item
   * @param item
   */
  protected _deleteItem(item: T) {
    this._itemsService
      .delete(item)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: response => {
          this._appAlertService.showSuccess(
            this._translocoService.translate('generic-messages.messages.success-erase', {
              gender: this.itemGenre,
              name: this.itemName,
            }),
          )
          this._loadItemsPage()
        },
      })
  }

  /**
   * Get filter string
   * @returns
   */
  protected _getFilterString(): string {
    let filterString: string = '('
    const filterLength: number = this._configList.filters.length

    for (var _i = 0; _i < filterLength; _i++) {
      filterString = filterString.concat(
        `indexof(tolower(${this._configList.filters[_i]}), tolower('${this.searchValue}')) gt -1`,
      )

      if (_i < filterLength - 1) {
        filterString = filterString.concat(' or ')
      }
    }

    filterString = filterString.concat(')')

    return filterString
  }

  /**
   * Navigate to a relative route with encrypted id
   */
  protected _navigateTo(target: string, id: string) {
    this._router.navigate([target, id], { relativeTo: this._route })
  }

  /** Add new action to list actions */
  protected _addNewAction(action: ActionButton) {
    this._listActions.push(action)
  }

  /**
   * Remove an element from list actions
   */
  protected _removeFromListActions(id: string) {
    const index = this._listActions.findIndex(ac => ac.id === id)
    this._listActions.splice(index, 1)
  }

  /**
   * Add conditions to element from list actions
   */
  protected _addConditionsToListAction(id: string, conditions: ActionButtonCondition[]) {
    const index = this._listActions.findIndex(ac => ac.id === id)
    this._listActions[index].dataConditions = conditions
  }

  /**
   * Set default configuration for lists
   */
  protected _setDefaultConfiguration() {
    if (this._ability.can(this.CASL_ACTIONS.View, this.caslSubject)) {
      this._listActions.push({
        id: this.CASL_ACTIONS.View,
        icon: 'mat_outline:remove_red_eye',
        label: this._translocoService.translate('buttons.labels.view'),
        function: { name: '_navigateTo', params: [{ name: 'view' }, { name: 'id', fromItem: true, encrypted: true }] },
      })
    }

    if (this._ability.can(this.CASL_ACTIONS.Update, this.caslSubject)) {
      this._listActions.push({
        id: this.CASL_ACTIONS.Update,
        icon: 'mat_outline:edit',
        label: this._translocoService.translate('buttons.labels.edit'),
        function: { name: '_navigateTo', params: [{ name: 'edit' }, { name: 'id', fromItem: true, encrypted: true }] },
      })
    }

    if (this._ability.can(this.CASL_ACTIONS.Delete, this.caslSubject)) {
      this._listActions.push({
        id: this.CASL_ACTIONS.Delete,
        icon: 'mat_outline:delete',
        label: this._translocoService.translate('buttons.labels.delete'),
        function: { name: 'confirmDelete', params: [{ name: 'data' }] },
      })
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Subscribe to Sort or Paginator changes
   */
  private _onSortOrPaginatorChanges() {
    if (
      this._sort &&
      this._paginator &&
      this._sort != this._lastSortUsed &&
      this._paginator != this._lastPaginatorUsed
    ) {
      this._lastSortUsed = this._sort
      this._lastPaginatorUsed = this._paginator

      // Reset paginator when sort change
      this._sort.sortChange.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => (this._paginator.pageIndex = 0))

      // on sort or paginate events, load a new page
      merge(this._sort.sortChange, this._paginator.page)
        .pipe(
          takeUntil(this._unsubscribeAll),
          tap(() => {
            this._loadItemsPage(
              this._paginator.pageSize,
              this._paginator.pageIndex,
              this._sort.active,
              this._sort.direction,
            )
          }),
        )
        .subscribe()
    }
  }
}
