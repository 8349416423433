import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, tap, BehaviorSubject } from 'rxjs';
import { User } from 'app/core/interfaces/identity/user.interface';
import { environment } from 'environments/environment';
import { SystemQueryOptions } from '../../interfaces/others/system-query-options.interface';
import { GenericUtil } from '../../utils/generic.util';
import { OdataResponse } from '../../interfaces/others/odata-response.interface';
import { AuthService } from '../../auth/auth.service';
import { BusinessUnit } from '../../interfaces/management/business-unit.interface';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { IdentificatorsConstants } from 'app/core/constants/identificator.constants';
import { RoleTypes } from 'app/core/enums/role-types.enum';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private _user: User = null;
  protected _businessUnitsSubject = new BehaviorSubject<BusinessUnit[]>([]);
  private _roleType: string = null;

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient, protected _genericUtil: GenericUtil, private _authService: AuthService) {
    this._authService.user$.subscribe((user) => {
      if (user) {
        this._user = user;
      }
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for business units
   */
  get businessUnits$(): Observable<BusinessUnit[]> {
    return this._businessUnitsSubject.asObservable();
  }

  /**
   * Getter for user
   */
  get user(): User {
    return this._user ? this._user : null;
  }

  /**
   * Getter for access email
   */
  get email(): string {
    return this._user ? this._user.email : null;
  }

  /**
   * getter for user Id
   */
  get userId(): string {
    return this._user ? this._user.id : null;
  }

  /**
   * getter for business unit Id
   */
  get businessUnitId(): string {
    return AuthUtils.getTokenBusinessUnitId(this._authService.accessToken);
  }

  /**
   * getter for business unit type
   */
  get businessUnitType(): string {
    return AuthUtils.getTokenBusinessUnitType(this._authService.accessToken);
  }

  /**
   * getter for userName
   */
  get userName(): string {
    return this._user ? this._user.userName : null;
  }

  /**
   * getter for role type
   */
  get roleType(): string {
    if (this._roleType === null) {
      const businessUnitGuid = this.businessUnitId;

      if (businessUnitGuid == IdentificatorsConstants.GENERIC_BUSINESS_UNIT_GUID) {
        this._roleType = RoleTypes.Generic;
      } else if (businessUnitGuid != IdentificatorsConstants.EMPTY_GUID) {
        this._roleType = RoleTypes.Dependent;
      } else {
        this._roleType = '';
      }
    }

    return this._roleType;
  }

  /**
   * getter for user roles
   */
  getRoles(): string[] {
    return this._user ? this._user.dependentRoles : null;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Validate if exists a user with the email
   * @param email
   * @returns
   */
  existsUserWithEmail(email: string) {
    return this._httpClient.get<any>(environment.apiItagueUrl + 'identity/accounts/user/by-email/' + email);
  }

  /**
   * Get user's business units
   * @param userId
   * @param options
   * @returns
   */
  getBusinessUnits() {
    const options: SystemQueryOptions = {
      select: 'guid,name,socialReason,type',
      orderby: 'name,socialReason'
    };

    const url = this._genericUtil.generateUrlOdataQuery(
      environment.apiItagueUrl + 'management/business-units',
      options
    );
    return this._httpClient.get<any>(url).pipe(
      tap((response: OdataResponse<BusinessUnit>) => {
        this._businessUnitsSubject.next(response.value);
      })
    );
  }

  /**
   * Change user password
   * @param item
   * @returns
   */
  changePassword(item: any): Observable<any> {
    const url = environment.apiItagueUrl + 'identity/accounts/change-password';
    return this._httpClient.put<any>(url, item);
  }

  signOut() {
    this._user = null;
    this._authService.signOut();
  }

  /**
   * Check if the user has the role
   * @param role
   * @returns
   */
  hasRole(role: string, roleType: string = RoleTypes.Dependent): boolean {
    if (roleType === RoleTypes.Generic) {
      return this._user.genericRoles.includes(role);
    } else {
      return this._user.dependentRoles.includes(role);
    }
  }

  /**
   * set user in storage
   * @param user
   */
  setStorageUser(user: User) {
    this._authService.user = user;
  }

  /**
   * get user from storage
   * @returns
   */
  getStorageUser(): User {
    return this._authService.user;
  }
}
