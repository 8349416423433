import { CaslSubjects } from 'app/core/enums/casl-subjects.enum'
import { Roles } from 'app/core/enums/roles.enum'
import { Permission } from 'app/core/interfaces/identity/permission.interface'
import { CaslActions } from '../../../core/enums/casl-actions.enum'

// Menu permissions
export const Permissions: Permission[] = [
  //Permissions for all business units menu
  { action: CaslActions.View, subject: CaslSubjects.MenuAOrganizations, roles: [Roles.Organization] },
  { action: CaslActions.View, subject: CaslSubjects.MenuAFarms, roles: [Roles.Producer] },
  { action: CaslActions.View, subject: CaslSubjects.MenuAAssistanceCompanies, roles: [Roles.TechnicalAdvisor] },
  { action: CaslActions.View, subject: CaslSubjects.MenuAAuditingCompanies, roles: [Roles.ExternalAuditor] },
  { action: CaslActions.View, subject: CaslSubjects.MenuACatalogs, roles: [Roles.SuperAdmin, Roles.Administrator] },

  //Permissions for a especific business unit menu
  {
    action: CaslActions.View,
    subject: CaslSubjects.MenuPlanning,
    roles: [
      Roles.FarmAdministrator,
      Roles.AttachedFarmAdministrator,
      Roles.OrganizationAdministrator,
      Roles.AgriculturalWorker,
    ],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.MenuInventory,
    roles: [
      Roles.FarmAdministrator,
      Roles.AttachedFarmAdministrator,
      Roles.OrganizationAdministrator,
      Roles.AgriculturalWorker,
    ],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.MenuPersonal,
    roles: [Roles.FarmAdministrator, Roles.AttachedFarmAdministrator, Roles.OrganizationAdministrator],
  },
  { action: CaslActions.View, subject: CaslSubjects.MenuAssistance, roles: [] },
  {
    action: CaslActions.View,
    subject: CaslSubjects.MenuCertifications,
    roles: [
      Roles.AgriculturalWorker,
      Roles.FarmAdministrator,
      Roles.OrganizationAdministrator,
      Roles.AttachedFarmAdministrator,
    ],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.MenuAnalytics,
    roles: [
      Roles.FarmAdministrator,
      Roles.AttachedFarmAdministrator,
      Roles.OrganizationAdministrator,
      Roles.AgriculturalWorker,
    ],
  },
  { action: CaslActions.View, subject: CaslSubjects.MenuAdministration, roles: [Roles.OrganizationAdministrator] },

  //Permissions for a especific business unit submenus
  {
    action: CaslActions.View,
    subject: CaslSubjects.SubmenuAgriculturalCycle,
    roles: [Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  { action: CaslActions.View, subject: CaslSubjects.SubmenuBatcheOfBovineAndPig, roles: [] },
  {
    action: CaslActions.View,
    subject: CaslSubjects.SubmenuActivities,
    roles: [
      Roles.FarmAdministrator,
      Roles.AttachedFarmAdministrator,
      Roles.OrganizationAdministrator,
      Roles.AgriculturalWorker,
    ],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.SubmenuInputs,
    roles: [Roles.FarmAdministrator, Roles.AttachedFarmAdministrator, Roles.OrganizationAdministrator],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.SubmenuOutputs,
    roles: [Roles.FarmAdministrator, Roles.AttachedFarmAdministrator, Roles.OrganizationAdministrator],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.SubmenuStock,
    roles: [
      Roles.FarmAdministrator,
      Roles.AttachedFarmAdministrator,
      Roles.OrganizationAdministrator,
      Roles.AgriculturalWorker,
    ],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.SubmenuAttachedProducers,
    roles: [Roles.OrganizationAdministrator],
  },
  { action: CaslActions.View, subject: CaslSubjects.SubmenuFarms, roles: [Roles.OrganizationAdministrator] },
  {
    action: CaslActions.View,
    subject: CaslSubjects.SubmenuReports,
    roles: [
      Roles.FarmAdministrator,
      Roles.AttachedFarmAdministrator,
      Roles.OrganizationAdministrator,
      Roles.AgriculturalWorker,
    ],
  },
  { action: CaslActions.View, subject: CaslSubjects.SubmenuIndicadores, roles: [] },

  //Permission for dashboards
  {
    action: CaslActions.View,
    subject: CaslSubjects.widgetCollaborators,
    roles: [
      Roles.Organization,
      Roles.Producer,
      Roles.OrganizationAdministrator,
      Roles.FarmAdministrator,
      Roles.AttachedFarmAdministrator,
    ],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.widgetComplementaryAreas,
    roles: [Roles.Organization, Roles.OrganizationAdministrator],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.widgetDistributionActivityTypes,
    roles: [Roles.AgriculturalWorker],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.widgetFarms,
    roles: [Roles.Organization, Roles.OrganizationAdministrator],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.widgetHectares,
    roles: [
      Roles.Organization,
      Roles.Producer,
      Roles.OrganizationAdministrator,
      Roles.FarmAdministrator,
      Roles.AttachedFarmAdministrator,
    ],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.widgetProductiveCycles,
    roles: [Roles.Producer, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  { action: CaslActions.View, subject: CaslSubjects.widgetSchedule, roles: [Roles.AgriculturalWorker] },
  {
    action: CaslActions.View,
    subject: CaslSubjects.widgetSupplies,
    roles: [Roles.Producer, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.widgetActivitiesSummary,
    roles: [
      Roles.Organization,
      Roles.Producer,
      Roles.OrganizationAdministrator,
      Roles.FarmAdministrator,
      Roles.AttachedFarmAdministrator,
    ],
  },

  //Permission for attached farms
  { action: CaslActions.Create, subject: CaslSubjects.AttachedFarms, roles: [Roles.OrganizationAdministrator] },
  { action: CaslActions.View, subject: CaslSubjects.AttachedFarms, roles: [Roles.OrganizationAdministrator] },
  { action: CaslActions.Update, subject: CaslSubjects.AttachedFarms, roles: [Roles.OrganizationAdministrator] },
  { action: CaslActions.Delete, subject: CaslSubjects.AttachedFarms, roles: [Roles.OrganizationAdministrator] },

  //Permission for attached producers
  { action: CaslActions.Create, subject: CaslSubjects.AttachedProducers, roles: [Roles.OrganizationAdministrator] },
  { action: CaslActions.View, subject: CaslSubjects.AttachedProducers, roles: [Roles.OrganizationAdministrator] },
  { action: CaslActions.Update, subject: CaslSubjects.AttachedProducers, roles: [Roles.OrganizationAdministrator] },
  { action: CaslActions.Delete, subject: CaslSubjects.AttachedProducers, roles: [Roles.OrganizationAdministrator] },

  //Permission for production areas
  {
    action: CaslActions.Create,
    subject: CaslSubjects.ProductionAreas,
    roles: [Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.ProductionAreas,
    roles: [Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.Update,
    subject: CaslSubjects.ProductionAreas,
    roles: [Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.Delete,
    subject: CaslSubjects.ProductionAreas,
    roles: [Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },

  //Permission for complementary areas
  {
    action: CaslActions.Create,
    subject: CaslSubjects.ComplementaryAreas,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.ComplementaryAreas,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.Update,
    subject: CaslSubjects.ComplementaryAreas,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.Delete,
    subject: CaslSubjects.ComplementaryAreas,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },

  //Permission for water sources
  {
    action: CaslActions.Create,
    subject: CaslSubjects.WaterSources,
    roles: [Roles.FarmAdministrator, Roles.AttachedFarmAdministrator, Roles.OrganizationAdministrator],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.WaterSources,
    roles: [Roles.FarmAdministrator, Roles.AttachedFarmAdministrator, Roles.OrganizationAdministrator],
  },
  {
    action: CaslActions.Update,
    subject: CaslSubjects.WaterSources,
    roles: [Roles.FarmAdministrator, Roles.AttachedFarmAdministrator, Roles.OrganizationAdministrator],
  },
  {
    action: CaslActions.Delete,
    subject: CaslSubjects.WaterSources,
    roles: [Roles.FarmAdministrator, Roles.AttachedFarmAdministrator, Roles.OrganizationAdministrator],
  },

  //Permission for foods
  {
    action: CaslActions.Create,
    subject: CaslSubjects.Foods,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.Foods,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.Update,
    subject: CaslSubjects.Foods,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.Delete,
    subject: CaslSubjects.Foods,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },

  //Permission for meds
  {
    action: CaslActions.Create,
    subject: CaslSubjects.Meds,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.Meds,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.Update,
    subject: CaslSubjects.Meds,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.Delete,
    subject: CaslSubjects.Meds,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },

  //Permission for pesticide
  {
    action: CaslActions.Create,
    subject: CaslSubjects.Pesticide,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.Pesticide,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.Update,
    subject: CaslSubjects.Pesticide,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.Delete,
    subject: CaslSubjects.Pesticide,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },

  //Permission for Processed products
  {
    action: CaslActions.Create,
    subject: CaslSubjects.Processedproducts,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.Processedproducts,
    roles: [
      Roles.OrganizationAdministrator,
      Roles.FarmAdministrator,
      Roles.AttachedFarmAdministrator,
      Roles.AgriculturalWorker,
      Roles.FarmExternalAuditor,
      Roles.FarmTechnicalAdvisor,
    ],
  },
  {
    action: CaslActions.Update,
    subject: CaslSubjects.Processedproducts,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.Delete,
    subject: CaslSubjects.Processedproducts,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },

  //Permission for customers
  {
    action: CaslActions.Create,
    subject: CaslSubjects.Customers,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.Customers,
    roles: [
      Roles.OrganizationAdministrator,
      Roles.FarmAdministrator,
      Roles.AttachedFarmAdministrator,
      Roles.AgriculturalWorker,
    ],
  },
  {
    action: CaslActions.Update,
    subject: CaslSubjects.Customers,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.Delete,
    subject: CaslSubjects.Customers,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },

  //Permission for suppliers
  {
    action: CaslActions.Create,
    subject: CaslSubjects.Supplier,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.Supplier,
    roles: [
      Roles.OrganizationAdministrator,
      Roles.FarmAdministrator,
      Roles.AttachedFarmAdministrator,
      Roles.AgriculturalWorker,
    ],
  },
  {
    action: CaslActions.Update,
    subject: CaslSubjects.Supplier,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.Delete,
    subject: CaslSubjects.Supplier,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },

  //Permission for organizations
  { action: CaslActions.Create, subject: CaslSubjects.Organizations, roles: [Roles.Organization] },
  { action: CaslActions.View, subject: CaslSubjects.Organizations, roles: [Roles.Organization] },
  { action: CaslActions.Update, subject: CaslSubjects.Organizations, roles: [Roles.Organization] },
  { action: CaslActions.Delete, subject: CaslSubjects.Organizations, roles: [Roles.Organization] },

  //Permission for producer farms
  { action: CaslActions.Create, subject: CaslSubjects.ProducerFarms, roles: [Roles.Producer] },
  { action: CaslActions.View, subject: CaslSubjects.ProducerFarms, roles: [Roles.Producer] },
  { action: CaslActions.Update, subject: CaslSubjects.ProducerFarms, roles: [Roles.Producer] },
  { action: CaslActions.Delete, subject: CaslSubjects.ProducerFarms, roles: [Roles.Producer] },

  //Permission for profile
  { action: CaslActions.View, subject: CaslSubjects.Profile, roles: [Roles.Registered, Roles.AgriculturalWorker] },
  { action: CaslActions.Update, subject: CaslSubjects.Profile, roles: [Roles.Registered, Roles.AgriculturalWorker] },

  //Permission for security
  { action: CaslActions.View, subject: CaslSubjects.Security, roles: [Roles.Registered, Roles.AgriculturalWorker] },
  { action: CaslActions.Update, subject: CaslSubjects.Security, roles: [Roles.Registered, Roles.AgriculturalWorker] },

  //Permission for options
  { action: CaslActions.View, subject: CaslSubjects.Options, roles: [Roles.Registered, Roles.AgriculturalWorker] },
  { action: CaslActions.Update, subject: CaslSubjects.Options, roles: [Roles.Registered, Roles.AgriculturalWorker] },

  //Permission for planBilling
  {
    action: CaslActions.View,
    subject: CaslSubjects.PlanBilling,
    roles: [
      Roles.FarmAdministrator,
      Roles.AttachedFarmAdministrator,
      Roles.OrganizationAdministrator,
      Roles.Organization,
      Roles.Producer,
    ],
  },
  {
    action: CaslActions.Update,
    subject: CaslSubjects.PlanBilling,
    roles: [
      Roles.Registered,
      Roles.FarmAdministrator,
      Roles.AttachedFarmAdministrator,
      Roles.OrganizationAdministrator,
      Roles.Organization,
      Roles.Producer,
    ],
  },

  //Permission for fertilizers
  {
    action: CaslActions.Create,
    subject: CaslSubjects.Fertilizers,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.Fertilizers,
    roles: [
      Roles.AgriculturalWorker,
      Roles.FarmExternalAuditor,
      Roles.OrganizationAdministrator,
      Roles.FarmAdministrator,
      Roles.FarmTechnicalAdvisor,
      Roles.AttachedFarmAdministrator,
    ],
  },
  {
    action: CaslActions.Update,
    subject: CaslSubjects.Fertilizers,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.Delete,
    subject: CaslSubjects.Fertilizers,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },

  //Permission for other supplies
  {
    action: CaslActions.Create,
    subject: CaslSubjects.OtherSupplies,
    roles: [Roles.FarmAdministrator, Roles.OrganizationAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.OtherSupplies,
    roles: [
      Roles.FarmAdministrator,
      Roles.OrganizationAdministrator,
      Roles.AttachedFarmAdministrator,
      Roles.AgriculturalWorker,
      Roles.FarmExternalAuditor,
      Roles.FarmTechnicalAdvisor,
    ],
  },
  {
    action: CaslActions.Update,
    subject: CaslSubjects.OtherSupplies,
    roles: [Roles.FarmAdministrator, Roles.OrganizationAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.Delete,
    subject: CaslSubjects.OtherSupplies,
    roles: [Roles.FarmAdministrator, Roles.OrganizationAdministrator, Roles.AttachedFarmAdministrator],
  },

  //Permission for seeds, seedlings and trees
  {
    action: CaslActions.Create,
    subject: CaslSubjects.SeedsSeedlingsTrees,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.SeedsSeedlingsTrees,
    roles: [
      Roles.AgriculturalWorker,
      Roles.FarmExternalAuditor,
      Roles.OrganizationAdministrator,
      Roles.FarmAdministrator,
      Roles.FarmTechnicalAdvisor,
      Roles.AttachedFarmAdministrator,
    ],
  },
  {
    action: CaslActions.Update,
    subject: CaslSubjects.SeedsSeedlingsTrees,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.Delete,
    subject: CaslSubjects.SeedsSeedlingsTrees,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },

  //Permission for positions
  {
    action: CaslActions.Create,
    subject: CaslSubjects.Positions,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.Positions,
    roles: [
      Roles.OrganizationAdministrator,
      Roles.FarmAdministrator,
      Roles.AttachedFarmAdministrator,
      Roles.AgriculturalWorker,
      Roles.FarmExternalAuditor,
      Roles.FarmTechnicalAdvisor,
    ],
  },
  {
    action: CaslActions.Update,
    subject: CaslSubjects.Positions,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.Delete,
    subject: CaslSubjects.Positions,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },

  //Permission for Activity types
  {
    action: CaslActions.Create,
    subject: CaslSubjects.Activitytypes,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.Activitytypes,
    roles: [
      Roles.OrganizationAdministrator,
      Roles.FarmAdministrator,
      Roles.AttachedFarmAdministrator,
      Roles.AgriculturalWorker,
      Roles.FarmExternalAuditor,
      Roles.FarmTechnicalAdvisor,
    ],
  },
  {
    action: CaslActions.Update,
    subject: CaslSubjects.Activitytypes,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.Delete,
    subject: CaslSubjects.Activitytypes,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },

  //Permission for bovinoes
  {
    action: CaslActions.Create,
    subject: CaslSubjects.Bovinoes,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.Bovinoes,
    roles: [
      Roles.OrganizationAdministrator,
      Roles.FarmAdministrator,
      Roles.AttachedFarmAdministrator,
      Roles.AgriculturalWorker,
      Roles.SuperAdmin,
      Roles.ExternalAuditor,
      Roles.Administrator,
      Roles.Organization,
    ],
  },
  {
    action: CaslActions.Update,
    subject: CaslSubjects.Bovinoes,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.Delete,
    subject: CaslSubjects.Bovinoes,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },

  //Permission for pigs
  {
    action: CaslActions.Create,
    subject: CaslSubjects.Pigs,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.Pigs,
    roles: [
      Roles.OrganizationAdministrator,
      Roles.FarmAdministrator,
      Roles.AttachedFarmAdministrator,
      Roles.AgriculturalWorker,
      Roles.SuperAdmin,
      Roles.ExternalAuditor,
      Roles.Administrator,
      Roles.Organization,
    ],
  },
  {
    action: CaslActions.Update,
    subject: CaslSubjects.Pigs,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.Delete,
    subject: CaslSubjects.Pigs,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },

  //Permission for Agricultural Cycles
  {
    action: CaslActions.Create,
    subject: CaslSubjects.AgriculturalCycles,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.AgriculturalCycles,
    roles: [
      Roles.OrganizationAdministrator,
      Roles.FarmAdministrator,
      Roles.AttachedFarmAdministrator,
      Roles.AgriculturalWorker,
      Roles.FarmExternalAuditor,
      Roles.FarmTechnicalAdvisor,
    ],
  },
  {
    action: CaslActions.Update,
    subject: CaslSubjects.AgriculturalCycles,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.Delete,
    subject: CaslSubjects.AgriculturalCycles,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },

  //Permission for area analysis
  {
    action: CaslActions.Create,
    subject: CaslSubjects.AreaAnalysis,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.AreaAnalysis,
    roles: [
      Roles.AgriculturalWorker,
      Roles.FarmExternalAuditor,
      Roles.OrganizationAdministrator,
      Roles.FarmAdministrator,
      Roles.FarmTechnicalAdvisor,
      Roles.AttachedFarmAdministrator,
    ],
  },
  {
    action: CaslActions.Update,
    subject: CaslSubjects.AreaAnalysis,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.Delete,
    subject: CaslSubjects.AreaAnalysis,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },

  //Permission for Machinery equipments
  {
    action: CaslActions.Create,
    subject: CaslSubjects.MachineryEquipment,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.MachineryEquipment,
    roles: [
      Roles.OrganizationAdministrator,
      Roles.FarmAdministrator,
      Roles.AttachedFarmAdministrator,
      Roles.AgriculturalWorker,
      Roles.FarmExternalAuditor,
      Roles.FarmTechnicalAdvisor,
    ],
  },
  {
    action: CaslActions.Update,
    subject: CaslSubjects.MachineryEquipment,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.Delete,
    subject: CaslSubjects.MachineryEquipment,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },

  //Permission for Maintenance calibrations
  {
    action: CaslActions.Create,
    subject: CaslSubjects.MaintenanceCalibrations,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.MaintenanceCalibrations,
    roles: [
      Roles.OrganizationAdministrator,
      Roles.FarmAdministrator,
      Roles.AttachedFarmAdministrator,
      Roles.AgriculturalWorker,
      Roles.FarmExternalAuditor,
      Roles.FarmTechnicalAdvisor,
    ],
  },
  {
    action: CaslActions.Update,
    subject: CaslSubjects.MaintenanceCalibrations,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.Delete,
    subject: CaslSubjects.MaintenanceCalibrations,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },

  //Permission for Batches Of Bovinoes And Pigs
  {
    action: CaslActions.Create,
    subject: CaslSubjects.BatchesOfBovinoesAndPigs,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.BatchesOfBovinoesAndPigs,
    roles: [
      Roles.OrganizationAdministrator,
      Roles.FarmAdministrator,
      Roles.AttachedFarmAdministrator,
      Roles.AgriculturalWorker,
      Roles.FarmExternalAuditor,
      Roles.FarmTechnicalAdvisor,
    ],
  },
  {
    action: CaslActions.Update,
    subject: CaslSubjects.BatchesOfBovinoesAndPigs,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.Delete,
    subject: CaslSubjects.BatchesOfBovinoesAndPigs,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },

  //Permission for harvest products
  {
    action: CaslActions.Create,
    subject: CaslSubjects.HarvestProducts,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.HarvestProducts,
    roles: [
      Roles.OrganizationAdministrator,
      Roles.FarmAdministrator,
      Roles.AttachedFarmAdministrator,
      Roles.AgriculturalWorker,
      Roles.FarmExternalAuditor,
      Roles.FarmTechnicalAdvisor,
    ],
  },
  {
    action: CaslActions.Update,
    subject: CaslSubjects.HarvestProducts,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.Delete,
    subject: CaslSubjects.HarvestProducts,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },

  //Permission for product quality
  {
    action: CaslActions.Create,
    subject: CaslSubjects.ProductQuality,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.ProductQuality,
    roles: [
      Roles.OrganizationAdministrator,
      Roles.FarmAdministrator,
      Roles.AttachedFarmAdministrator,
      Roles.AgriculturalWorker,
      Roles.FarmExternalAuditor,
      Roles.FarmTechnicalAdvisor,
    ],
  },
  {
    action: CaslActions.Update,
    subject: CaslSubjects.ProductQuality,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.Delete,
    subject: CaslSubjects.ProductQuality,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  //Permission for animal products
  {
    action: CaslActions.Create,
    subject: CaslSubjects.AnimalProducts,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.AnimalProducts,
    roles: [
      Roles.OrganizationAdministrator,
      Roles.FarmAdministrator,
      Roles.AttachedFarmAdministrator,
      Roles.AgriculturalWorker,
      Roles.FarmExternalAuditor,
      Roles.FarmTechnicalAdvisor,
    ],
  },
  {
    action: CaslActions.Update,
    subject: CaslSubjects.AnimalProducts,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.Delete,
    subject: CaslSubjects.AnimalProducts,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },

  //Permission for leftover products
  {
    action: CaslActions.Create,
    subject: CaslSubjects.LeftoverProducts,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.LeftoverProducts,
    roles: [
      Roles.OrganizationAdministrator,
      Roles.FarmAdministrator,
      Roles.AttachedFarmAdministrator,
      Roles.AgriculturalWorker,
      Roles.FarmExternalAuditor,
      Roles.FarmTechnicalAdvisor,
    ],
  },
  {
    action: CaslActions.Update,
    subject: CaslSubjects.LeftoverProducts,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.Delete,
    subject: CaslSubjects.LeftoverProducts,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },

  //Managment inputs
  {
    action: CaslActions.Create,
    subject: CaslSubjects.Inputs,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.Inputs,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.Update,
    subject: CaslSubjects.Inputs,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.Delete,
    subject: CaslSubjects.Inputs,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },

  //Managment outputs
  {
    action: CaslActions.Create,
    subject: CaslSubjects.Outputs,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.Outputs,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.Update,
    subject: CaslSubjects.Outputs,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.Delete,
    subject: CaslSubjects.Outputs,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },

  //Managment indicator
  {
    action: CaslActions.Create,
    subject: CaslSubjects.Indicator,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.Indicator,
    roles: [
      Roles.OrganizationAdministrator,
      Roles.FarmAdministrator,
      Roles.AttachedFarmAdministrator,
      Roles.FarmTechnicalAdvisor,
      Roles.FarmExternalAuditor,
      Roles.AgriculturalWorker,
    ],
  },
  {
    action: CaslActions.Update,
    subject: CaslSubjects.Indicator,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.Delete,
    subject: CaslSubjects.Indicator,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },

  //Managment estate
  {
    action: CaslActions.Create,
    subject: CaslSubjects.FarmAnimals,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.FarmAnimals,
    roles: [
      Roles.OrganizationAdministrator,
      Roles.FarmAdministrator,
      Roles.AttachedFarmAdministrator,
      Roles.FarmExternalAuditor,
      Roles.AgriculturalWorker,
    ],
  },
  {
    action: CaslActions.Update,
    subject: CaslSubjects.FarmAnimals,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.Delete,
    subject: CaslSubjects.FarmAnimals,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },

  //Managment collaborators
  {
    action: CaslActions.Create,
    subject: CaslSubjects.Collaborators,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.Collaborators,
    roles: [
      Roles.OrganizationAdministrator,
      Roles.FarmAdministrator,
      Roles.AttachedFarmAdministrator,
      Roles.FarmExternalAuditor,
      Roles.AgriculturalWorker,
    ],
  },
  {
    action: CaslActions.Update,
    subject: CaslSubjects.Collaborators,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.Delete,
    subject: CaslSubjects.Collaborators,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },

  //Managment studies
  {
    action: CaslActions.Create,
    subject: CaslSubjects.Studies,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.Studies,
    roles: [
      Roles.OrganizationAdministrator,
      Roles.FarmAdministrator,
      Roles.AttachedFarmAdministrator,
      Roles.FarmExternalAuditor,
      Roles.AgriculturalWorker,
    ],
  },
  {
    action: CaslActions.Update,
    subject: CaslSubjects.Studies,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.Delete,
    subject: CaslSubjects.Studies,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },

  //Permission for byproducts
  {
    action: CaslActions.Create,
    subject: CaslSubjects.ByProducts,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.ByProducts,
    roles: [
      Roles.OrganizationAdministrator,
      Roles.FarmAdministrator,
      Roles.AttachedFarmAdministrator,
      Roles.AgriculturalWorker,
      Roles.FarmExternalAuditor,
      Roles.FarmTechnicalAdvisor,
    ],
  },
  {
    action: CaslActions.Update,
    subject: CaslSubjects.ByProducts,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.Delete,
    subject: CaslSubjects.ByProducts,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },

  //Permission for AgriculturalCycleHarvest
  {
    action: CaslActions.Create,
    subject: CaslSubjects.AgriculturalCycleHarvest,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.AgriculturalCycleHarvest,
    roles: [
      Roles.AgriculturalWorker,
      Roles.FarmExternalAuditor,
      Roles.OrganizationAdministrator,
      Roles.FarmAdministrator,
      Roles.FarmTechnicalAdvisor,
      Roles.AttachedFarmAdministrator,
    ],
  },
  {
    action: CaslActions.Update,
    subject: CaslSubjects.AgriculturalCycleHarvest,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.Delete,
    subject: CaslSubjects.AgriculturalCycleHarvest,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },

  //Permission for AgriculturalCyclePhenological
  {
    action: CaslActions.Create,
    subject: CaslSubjects.AgriculturalCyclePhenological,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.AgriculturalCyclePhenological,
    roles: [
      Roles.AgriculturalWorker,
      Roles.FarmExternalAuditor,
      Roles.OrganizationAdministrator,
      Roles.FarmAdministrator,
      Roles.FarmTechnicalAdvisor,
      Roles.AttachedFarmAdministrator,
    ],
  },
  {
    action: CaslActions.Update,
    subject: CaslSubjects.AgriculturalCyclePhenological,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.Delete,
    subject: CaslSubjects.AgriculturalCyclePhenological,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },

  //Permission for activities
  {
    action: CaslActions.Create,
    subject: CaslSubjects.Activities,
    roles: [
      Roles.OrganizationAdministrator,
      Roles.FarmAdministrator,
      Roles.AttachedFarmAdministrator,
      Roles.AgriculturalWorker,
    ],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.Activities,
    roles: [
      Roles.OrganizationAdministrator,
      Roles.FarmAdministrator,
      Roles.AttachedFarmAdministrator,
      Roles.AgriculturalWorker,
      Roles.FarmExternalAuditor,
      Roles.FarmTechnicalAdvisor,
    ],
  },
  {
    action: CaslActions.Update,
    subject: CaslSubjects.Activities,
    roles: [
      Roles.OrganizationAdministrator,
      Roles.FarmAdministrator,
      Roles.AttachedFarmAdministrator,
      Roles.AgriculturalWorker,
    ],
  },
  {
    action: CaslActions.Delete,
    subject: CaslSubjects.Activities,
    roles: [
      Roles.OrganizationAdministrator,
      Roles.FarmAdministrator,
      Roles.AttachedFarmAdministrator,
      Roles.AgriculturalWorker,
    ],
  },

  //Stock
  {
    action: CaslActions.Create,
    subject: CaslSubjects.Stock,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.Stock,
    roles: [
      Roles.OrganizationAdministrator,
      Roles.FarmAdministrator,
      Roles.AttachedFarmAdministrator,
      Roles.FarmExternalAuditor,
      Roles.AgriculturalWorker,
    ],
  },
  {
    action: CaslActions.Update,
    subject: CaslSubjects.Stock,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.Delete,
    subject: CaslSubjects.Stock,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },

  //Managment analytics
  {
    action: CaslActions.View,
    subject: CaslSubjects.Reports,
    roles: [
      Roles.OrganizationAdministrator,
      Roles.FarmAdministrator,
      Roles.AttachedFarmAdministrator,
      Roles.FarmExternalAuditor,
      Roles.AgriculturalWorker,
    ],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.ViewIndicators,
    roles: [
      Roles.OrganizationAdministrator,
      Roles.FarmAdministrator,
      Roles.AttachedFarmAdministrator,
      Roles.FarmExternalAuditor,
      Roles.AgriculturalWorker,
    ],
  },

  // Permission reports
  {
    action: CaslActions.View,
    subject: CaslSubjects.FarmResume,
    roles: [
      Roles.OrganizationAdministrator,
      Roles.FarmAdministrator,
      Roles.AttachedFarmAdministrator,
      Roles.FarmExternalAuditor,
      Roles.AgriculturalWorker,
      Roles.FarmTechnicalAdvisor,
    ],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.CollaboratorResume,
    roles: [
      Roles.OrganizationAdministrator,
      Roles.FarmAdministrator,
      Roles.AttachedFarmAdministrator,
      Roles.FarmExternalAuditor,
      Roles.AgriculturalWorker,
      Roles.FarmTechnicalAdvisor,
    ],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.LifeSheetOfMachinesEquipmentAndTools,
    roles: [
      Roles.OrganizationAdministrator,
      Roles.FarmAdministrator,
      Roles.AttachedFarmAdministrator,
      Roles.FarmExternalAuditor,
      Roles.AgriculturalWorker,
      Roles.FarmTechnicalAdvisor,
    ],
  },

  {
    action: CaslActions.View,
    subject: CaslSubjects.PurchaseHistory,
    roles: [
      Roles.OrganizationAdministrator,
      Roles.FarmAdministrator,
      Roles.AttachedFarmAdministrator,
      Roles.FarmExternalAuditor,
      Roles.AgriculturalWorker,
      Roles.FarmTechnicalAdvisor,
    ],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.SalesHistory,
    roles: [
      Roles.OrganizationAdministrator,
      Roles.FarmAdministrator,
      Roles.AttachedFarmAdministrator,
      Roles.FarmExternalAuditor,
      Roles.AgriculturalWorker,
      Roles.FarmTechnicalAdvisor,
    ],
  },

  {
    action: CaslActions.View,
    subject: CaslSubjects.CropHistory,
    roles: [
      Roles.OrganizationAdministrator,
      Roles.FarmAdministrator,
      Roles.AttachedFarmAdministrator,
      Roles.FarmExternalAuditor,
      Roles.AgriculturalWorker,
      Roles.FarmTechnicalAdvisor,
    ],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.HistoryOfBatchesOfAnimals,
    roles: [
      Roles.OrganizationAdministrator,
      Roles.FarmAdministrator,
      Roles.AttachedFarmAdministrator,
      Roles.FarmExternalAuditor,
      Roles.AgriculturalWorker,
      Roles.FarmTechnicalAdvisor,
    ],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.activityReport,
    roles: [
      Roles.OrganizationAdministrator,
      Roles.FarmAdministrator,
      Roles.AttachedFarmAdministrator,
      Roles.FarmExternalAuditor,
      Roles.AgriculturalWorker,
      Roles.FarmTechnicalAdvisor,
    ],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.attachedProducerReport,
    roles: [Roles.OrganizationAdministrator],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.MinimumInventory,
    roles: [
      Roles.OrganizationAdministrator,
      Roles.FarmAdministrator,
      Roles.AttachedFarmAdministrator,
      Roles.FarmExternalAuditor,
    ],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.sellingusedproducts,
    roles: [
      Roles.OrganizationAdministrator,
      Roles.FarmAdministrator,
      Roles.AttachedFarmAdministrator,
      Roles.FarmExternalAuditor,
    ],
  },

  //File manager
  {
    action: CaslActions.Create,
    subject: CaslSubjects.FileManager,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.FileManager,
    roles: [
      Roles.OrganizationAdministrator,
      Roles.FarmAdministrator,
      Roles.AttachedFarmAdministrator,
      Roles.FarmExternalAuditor,
      Roles.AgriculturalWorker,
    ],
  },
  {
    action: CaslActions.Update,
    subject: CaslSubjects.FileManager,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },
  {
    action: CaslActions.Delete,
    subject: CaslSubjects.FileManager,
    roles: [Roles.OrganizationAdministrator, Roles.FarmAdministrator, Roles.AttachedFarmAdministrator],
  },

  //Simulations
  {
    action: CaslActions.Create,
    subject: CaslSubjects.Simulations,
    roles: [
      Roles.AgriculturalWorker,
      Roles.FarmAdministrator,
      Roles.OrganizationAdministrator,
      Roles.AttachedFarmAdministrator,
    ],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.Simulations,
    roles: [
      Roles.AgriculturalWorker,
      Roles.FarmAdministrator,
      Roles.OrganizationAdministrator,
      Roles.AttachedFarmAdministrator,
    ],
  },
  {
    action: CaslActions.Update,
    subject: CaslSubjects.Simulations,
    roles: [
      Roles.AgriculturalWorker,
      Roles.FarmAdministrator,
      Roles.OrganizationAdministrator,
      Roles.AttachedFarmAdministrator,
    ],
  },
  {
    action: CaslActions.Delete,
    subject: CaslSubjects.Simulations,
    roles: [
      Roles.AgriculturalWorker,
      Roles.FarmAdministrator,
      Roles.OrganizationAdministrator,
      Roles.AttachedFarmAdministrator,
    ],
  },

  //Certification Management
  {
    action: CaslActions.Create,
    subject: CaslSubjects.CertificationManagement,
    roles: [
      Roles.AgriculturalWorker,
      Roles.FarmAdministrator,
      Roles.OrganizationAdministrator,
      Roles.AttachedFarmAdministrator,
    ],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.CertificationManagement,
    roles: [
      Roles.AgriculturalWorker,
      Roles.FarmAdministrator,
      Roles.OrganizationAdministrator,
      Roles.AttachedFarmAdministrator,
    ],
  },
  {
    action: CaslActions.Update,
    subject: CaslSubjects.CertificationManagement,
    roles: [
      Roles.AgriculturalWorker,
      Roles.FarmAdministrator,
      Roles.OrganizationAdministrator,
      Roles.AttachedFarmAdministrator,
    ],
  },
  {
    action: CaslActions.Delete,
    subject: CaslSubjects.CertificationManagement,
    roles: [
      Roles.AgriculturalWorker,
      Roles.FarmAdministrator,
      Roles.OrganizationAdministrator,
      Roles.AttachedFarmAdministrator,
    ],
  },

  // IoT Devices
  {
    action: CaslActions.View,
    subject: CaslSubjects.Devices,
    roles: [
      Roles.Administrator,
      Roles.AgriculturalWorker,
      Roles.FarmAdministrator,
      Roles.OrganizationAdministrator,
      Roles.AttachedFarmAdministrator,
    ],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.MenuIoT,
    roles: [
      Roles.Administrator,
      Roles.AgriculturalWorker,
      Roles.FarmAdministrator,
      Roles.OrganizationAdministrator,
      Roles.AttachedFarmAdministrator,
    ],
  },
  {
    action: CaslActions.Create,
    subject: CaslSubjects.Devices,
    roles: [
      Roles.Administrator,
      Roles.AgriculturalWorker,
      Roles.FarmAdministrator,
      Roles.OrganizationAdministrator,
      Roles.AttachedFarmAdministrator,
    ],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.Devices,
    roles: [
      Roles.Administrator,
      Roles.AgriculturalWorker,
      Roles.FarmAdministrator,
      Roles.OrganizationAdministrator,
      Roles.AttachedFarmAdministrator,
    ],
  },
  {
    action: CaslActions.Update,
    subject: CaslSubjects.Devices,
    roles: [
      Roles.Administrator,
      Roles.AgriculturalWorker,
      Roles.FarmAdministrator,
      Roles.OrganizationAdministrator,
      Roles.AttachedFarmAdministrator,
    ],
  },
  {
    action: CaslActions.Delete,
    subject: CaslSubjects.Devices,
    roles: [
      Roles.Administrator,
      Roles.AgriculturalWorker,
      Roles.FarmAdministrator,
      Roles.OrganizationAdministrator,
      Roles.AttachedFarmAdministrator,
    ],
  },

  // IoT Device Variables
  {
    action: CaslActions.View,
    subject: CaslSubjects.DeviceVariables,
    roles: [
      Roles.Administrator,
      Roles.AgriculturalWorker,
      Roles.FarmAdministrator,
      Roles.OrganizationAdministrator,
      Roles.AttachedFarmAdministrator,
    ],
  },
  {
    action: CaslActions.Update,
    subject: CaslSubjects.DeviceVariables,
    roles: [
      Roles.Administrator,
      Roles.AgriculturalWorker,
      Roles.FarmAdministrator,
      Roles.OrganizationAdministrator,
      Roles.AttachedFarmAdministrator,
    ],
  },

  // Alert Settings

  {
    action: CaslActions.Create,
    subject: CaslSubjects.AlertSettings,
    roles: [
      Roles.Administrator,
      Roles.AgriculturalWorker,
      Roles.FarmAdministrator,
      Roles.OrganizationAdministrator,
      Roles.AttachedFarmAdministrator,
    ],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.AlertSettings,
    roles: [
      Roles.Administrator,
      Roles.AgriculturalWorker,
      Roles.FarmAdministrator,
      Roles.OrganizationAdministrator,
      Roles.AttachedFarmAdministrator,
    ],
  },
  {
    action: CaslActions.Update,
    subject: CaslSubjects.AlertSettings,
    roles: [
      Roles.Administrator,
      Roles.AgriculturalWorker,
      Roles.FarmAdministrator,
      Roles.OrganizationAdministrator,
      Roles.AttachedFarmAdministrator,
    ],
  },
  {
    action: CaslActions.Delete,
    subject: CaslSubjects.AlertSettings,
    roles: [
      Roles.Administrator,
      Roles.AgriculturalWorker,
      Roles.FarmAdministrator,
      Roles.OrganizationAdministrator,
      Roles.AttachedFarmAdministrator,
    ],
  },

  // Alert conditions
  {
    action: CaslActions.Create,
    subject: CaslSubjects.AlertConditions,
    roles: [
      Roles.Administrator,
      Roles.AgriculturalWorker,
      Roles.FarmAdministrator,
      Roles.OrganizationAdministrator,
      Roles.AttachedFarmAdministrator,
    ],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.AlertConditions,
    roles: [
      Roles.Administrator,
      Roles.AgriculturalWorker,
      Roles.FarmAdministrator,
      Roles.OrganizationAdministrator,
      Roles.AttachedFarmAdministrator,
    ],
  },
  {
    action: CaslActions.Update,
    subject: CaslSubjects.AlertConditions,
    roles: [
      Roles.Administrator,
      Roles.AgriculturalWorker,
      Roles.FarmAdministrator,
      Roles.OrganizationAdministrator,
      Roles.AttachedFarmAdministrator,
    ],
  },
  {
    action: CaslActions.Delete,
    subject: CaslSubjects.AlertConditions,
    roles: [
      Roles.Administrator,
      Roles.AgriculturalWorker,
      Roles.FarmAdministrator,
      Roles.OrganizationAdministrator,
      Roles.AttachedFarmAdministrator,
    ],
  },

  // Area permission
  {
    action: CaslActions.Create,
    subject: CaslSubjects.AreaPermission,
    roles: [
      Roles.Administrator,
      Roles.AgriculturalWorker,
      Roles.FarmAdministrator,
      Roles.OrganizationAdministrator,
      Roles.AttachedFarmAdministrator,
    ],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.AreaPermission,
    roles: [
      Roles.Administrator,
      Roles.AgriculturalWorker,
      Roles.FarmAdministrator,
      Roles.OrganizationAdministrator,
      Roles.AttachedFarmAdministrator,
    ],
  },
  {
    action: CaslActions.Update,
    subject: CaslSubjects.AreaPermission,
    roles: [
      Roles.Administrator,
      Roles.AgriculturalWorker,
      Roles.FarmAdministrator,
      Roles.OrganizationAdministrator,
      Roles.AttachedFarmAdministrator,
    ],
  },
  {
    action: CaslActions.Delete,
    subject: CaslSubjects.AreaPermission,
    roles: [
      Roles.Administrator,
      Roles.AgriculturalWorker,
      Roles.FarmAdministrator,
      Roles.OrganizationAdministrator,
      Roles.AttachedFarmAdministrator,
    ],
  },

  // Alerts
  {
    action: CaslActions.View,
    subject: CaslSubjects.Alerts,
    roles: [
      Roles.Administrator,
      Roles.AgriculturalWorker,
      Roles.FarmAdministrator,
      Roles.OrganizationAdministrator,
      Roles.AttachedFarmAdministrator,
    ],
  },
  {
    action: CaslActions.Update,
    subject: CaslSubjects.Alerts,
    roles: [
      Roles.Administrator,
      Roles.AgriculturalWorker,
      Roles.FarmAdministrator,
      Roles.OrganizationAdministrator,
      Roles.AttachedFarmAdministrator,
    ],
  },

  // Dashboard IoT

  {
    action: CaslActions.Create,
    subject: CaslSubjects.DashboardIOT,
    roles: [
      Roles.Administrator,
      Roles.AgriculturalWorker,
      Roles.FarmAdministrator,
      Roles.OrganizationAdministrator,
      Roles.AttachedFarmAdministrator,
    ],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.DashboardIOT,
    roles: [
      Roles.Administrator,
      Roles.AgriculturalWorker,
      Roles.FarmAdministrator,
      Roles.OrganizationAdministrator,
      Roles.AttachedFarmAdministrator,
    ],
  },
  {
    action: CaslActions.Update,
    subject: CaslSubjects.DashboardIOT,
    roles: [
      Roles.Administrator,
      Roles.AgriculturalWorker,
      Roles.FarmAdministrator,
      Roles.OrganizationAdministrator,
      Roles.AttachedFarmAdministrator,
    ],
  },
  {
    action: CaslActions.Delete,
    subject: CaslSubjects.DashboardIOT,
    roles: [
      Roles.Administrator,
      Roles.AgriculturalWorker,
      Roles.FarmAdministrator,
      Roles.OrganizationAdministrator,
      Roles.AttachedFarmAdministrator,
    ],
  },
  {
    action: CaslActions.View,
    subject: CaslSubjects.Measurements,
    roles: [
      Roles.Administrator,
      Roles.AgriculturalWorker,
      Roles.FarmAdministrator,
      Roles.OrganizationAdministrator,
      Roles.AttachedFarmAdministrator,
    ],
  },
]

export const RegisteredPermissions: Permission[] = []
