import { Injectable, OnDestroy } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslocoService } from '@jsverse/transloco';
import { Paginator } from 'app/core/interfaces/others/paginator.interface';
import { Subject, take, takeUntil } from 'rxjs';

@Injectable()
export class MatPaginatorTranslatorService extends MatPaginatorIntl implements OnDestroy {
  private _unsubscribeAll: Subject<void> = new Subject<void>();

  itemsPerPageLabel: string;
  nextPageLabel: string;
  previousPageLabel: string;
  firstPageLabel: string;
  lastPageLabel: string;
  of: string;

  constructor(private _translocoService: TranslocoService) {
    super();

    this._translocoService.langChanges$.pipe(takeUntil(this._unsubscribeAll)).subscribe({
      next: () => {
        this.getTranslations();
      }
    });
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  getRangeLabel = (page: number, pageSize: number, length: number) => {
    return page * pageSize + 1 + ' - ' + (page * pageSize + pageSize) + ' ' + this.of + ' ' + length;
  };

  private getTranslations() {
    this._translocoService
      .selectTranslateObject('paginator')
      .pipe(take(1), takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (translateObject: Paginator) => {
          this.itemsPerPageLabel = translateObject['items-per-page'];
          this.nextPageLabel = translateObject['next-page'];
          this.previousPageLabel = translateObject['previous-page'];
          this.firstPageLabel = translateObject['first-page'];
          this.lastPageLabel = translateObject['last-page'];
          this.of = translateObject.of;
        }
      });
  }
}
