import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { catchError, Observable, throwError } from 'rxjs';
import { Languages } from '../enums/languages.enum';

/**
 * Intercept
 *
 * @param req
 * @param next
 */
export const authInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
  const authService = inject(AuthService);
  const translocoService = inject(TranslocoService);

  // Clone the request object
  let newReq = req.clone();

  // Request
  //
  // If the access token didn't expire, add the Authorization header.
  // We won't add the Authorization header if the access token expired.
  // This will force the server to return a "401 Unauthorized" response
  // for the protected API routes which our response interceptor will
  // catch and delete the access token from the local storage while logging
  // the user out from the app.
  if (authService.accessToken && !AuthUtils.isTokenExpired(authService.accessToken)) {
    newReq = req.clone({
      headers: req.headers.set('Authorization', 'Bearer ' + authService.accessToken)
    });
  }

  // Add Accept-Language header
  //
  // The Spanish language (es) is transformed into Colombian Spanish (es-CO), which for now is the only one in the backend.
  let lang = translocoService.getActiveLang();
  if (lang === Languages.Es) {
    lang = Languages.EsCo;
  }
  newReq = newReq.clone({
    headers: newReq.headers.append('Accept-Language', lang)
  });

  // Response
  return next(newReq).pipe(
    catchError((error) => {
      if (error instanceof HttpErrorResponse) {
        // A client-side or network error occurred
        if (error.status === 0) {
          console.error('A client-side or network error occurred.  Description:', error.error);
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong.

          // Catch "401 Unauthorized" responses
          if (error.status === 401) {
            // Sign out
            authService.signOut();

            // Reload the app
            location.reload();
          }
        }

        // Log error
        // Todo
        return throwError(() => new HttpErrorResponse(error));
      } else {
        // Log error
        // Todo
        return throwError(() => new Error(error));
      }
    })
  );
};
