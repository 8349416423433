import { Component, EventEmitter, Input, Output, OnInit, OnDestroy } from '@angular/core'
import { UntypedFormControl, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { takeUntil, debounceTime, tap, Subject } from 'rxjs'
import { MatButtonModule } from '@angular/material/button'

import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators'
import { MatInputModule } from '@angular/material/input'
import { MatIconModule } from '@angular/material/icon'
import { MatFormFieldModule } from '@angular/material/form-field'

@Component({
  selector: 'app-search-list',
  templateUrl: './search-list.component.html',
  styleUrls: ['./search-list.component.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    RxReactiveFormsModule,
    MatButtonModule,
  ],
})
export class SearchListComponent implements OnInit, OnDestroy {
  @Input('placeholder') placeholder: string
  @Input('showAdvacendSearch') showAdvacendSearch: boolean = false
  @Output('changeValue') changeValue = new EventEmitter<string>()
  @Output('openAdvancedSearch') openAdvancedSearch = new EventEmitter<boolean>()

  @Input() public searchInputControl: UntypedFormControl = new UntypedFormControl()
  private _unsubscribeAll: Subject<any> = new Subject<any>()

  ngOnInit(): void {
    // Subscribe to search input field value changes
    this.searchInputControl.valueChanges
      .pipe(
        takeUntil(this._unsubscribeAll),
        debounceTime(500),
        tap((value: string) => {
          this.changeValue.emit(value)
        }),
      )
      .subscribe()
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null)
    this._unsubscribeAll.complete()
  }

  openAdvancedSearchWindow() {
    this.openAdvancedSearch.emit(true)
  }

  cleanValue() {
    this.searchInputControl.setValue('')
  }
}
