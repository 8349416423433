<div class="wrapper">
  <mat-form-field>
    <mat-label>{{ props['label'] }}</mat-label>

    <input matInput type="input" [formControl]="formControl" [formlyAttributes]="field" [id]="keyField"
      [required]="props['required']" />
    <mat-error *ngIf="showError">{{ errorMessage }}</mat-error>
  </mat-form-field>
  <p class="custom-description">{{ props['description'] }}</p>
  <app-custom-note (sendText)="getNote($event)" [textValue]="props['note']" (sendImage)="getImage($event)"
    [formDisable]="formControl"></app-custom-note>
</div>