<!-- Search -->
<mat-form-field class="app-header-list-items-search fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded">
  <mat-icon class="icon-size-5" matPrefix [svgIcon]="'mat_outline:search'"></mat-icon>
  <input
    matInput
    [formControl]="searchInputControl"
    [autocomplete]="'off'"
    [placeholder]="placeholder" />

    @if (searchInputControl.value) {
      <button mat-icon-button (click)="cleanValue()">
        <mat-icon class="app-button-icon" [svgIcon]="'mat_outline:close'"></mat-icon>
      </button>
    }

    @if (showAdvacendSearch) {
      <button mat-icon-button (click)="openAdvancedSearchWindow()">
        <mat-icon class="app-button-icon" [svgIcon]="'tune'"></mat-icon>
      </button>
    }
  </mat-form-field>
