/**
 * Entities guid for library component
 */
export abstract class LibraryEntitiesConstants {
  static readonly MANAGEMENT_PERSON_ADMINBASICUSERINFORMATION: string = 'bcf3254e-0d13-4739-b56f-3e7ed71bd9f6'
  static readonly MANAGEMENT_THIRDPARTY_IMAGE: string = 'b57a82c4-fbed-499a-aee1-24c7f24fb4c3'
  static readonly MANAGEMENT_FARM_ADMINFARM: string = 'dd4f6fc5-52b7-4c6d-93f6-9c2af26bc097'
  static readonly MANAGEMENT_ORGANIZATION_ADMINORGANIZATION: string = 'd5508e75-46b1-480a-8457-28fac69c1d68'
  static readonly PRODUCTION_MACHINERYEQUIPMENT_ADMINMCHINERYEQUIPMENT: string = '3539f180-a2d2-4bc8-9a09-fe85ca389aed'
  static readonly MANAGEMENT_ACTIVITYTYPE_ADMINACTIVITYTYPE: string = '665da71d-94a6-4a58-9de8-f398eb4c3c52'
  static readonly PRODUCTION_PRODUCT_ADMINFERTILIZER: string = '1b5aaf75-8443-4b65-89e0-2fc74f0f2125'
  static readonly PRODUCTION_PRODUCT_ADMINPESTICIDE: string = '5ffcf90a-0b9a-4e75-81dd-0399f0459a08'
  static readonly PRODUCTION_PRODUCT_ADMINSEEDSEEDINGTREE: string = 'ed30e5b6-1009-483a-91b8-9e441a4757c5'
  static readonly PRODUCTION_PRODUCT_ADMINOTHERSUPPLY: string = 'ccc7048f-5116-449b-902b-609a3482afec'
  static readonly PRODUCTION_PROCESSED_PRODUCT: string = '98e0564e-c413-4566-ae00-261c65995818'
  static readonly PRODUCTION_STOCKDOCUMENT_ADMININPUT: string = '2a5c232e-8864-4bce-86d9-78275ab7977a'
  static readonly PRODUCTION_STOCKDOCUMENT_ADMINOUTPUT: string = '46758178-4b25-4055-817c-402ded5a9983'
  static readonly MANAGEMENT_PERSON_ADMINBASICCOLLABORATORINFORMATION: string = '2c2b3104-cf8d-4f57-97a7-cb8f213f21a8'
  static readonly MANAGEMENT_BUSINESSUNIT_IMAGE: string = '6ad253cd-9a7d-4faf-8277-b75ec29ae4fc'
  static readonly PRODUCTION_MACHINERYEQUIPMENTCLASSIFICATION_CARDIMAGE: string = '941219db-de21-4951-b47a-f62c38e54c71'
  static readonly MANAGEMENT_WATER_SOURCE: string = '96960b7f-eed1-4a89-9e7a-3a80403ffcff'

  static readonly PRODUCTION_ADMIN_HARVEST: string = '0efbb6d8-1be2-4eaa-9be4-1bff77e29397'
  static readonly PRODUCTION_ADMIN_ANIMAL: string = '1b0d4b64-33b4-4e3d-b16d-772b9df1c110'
  static readonly PRODUCTION_ADMIN_BY_PRODUCT: string = '1a59127f-0281-4a89-8a61-88f1d66f1a58'
  static readonly MANAGEMENT_ADMIN_POSITION: string = 'db77b75f-fbff-433d-9ccd-3d072b13f32f'
  static readonly MANAGEMENT_THIRDPARTY_ADMIN_PROVIDER: string = '3ae9d5dc-7e83-492c-aad1-31dffb31b390'
  static readonly MANAGEMENT_THIRDPARTY_ADMIN_CLIENT: string = 'a9771c5a-61c3-40f8-b3c1-635b1f98c68d'

  static readonly PRODUCTION_PRODUCT_ADMINFOOD: string = 'ffe7bd5d-76ee-47fe-98a9-83395c00303f'
  static readonly PRODUCTION_PRODUCT_ADMINMEDICINE: string = '748019ff-8a2c-48a3-b210-9cb47a85f3ba'
  static readonly MANAGEMENT_THIRDPARTY_ATTACHEDPRODUCER: string = '09b95c02-7468-474c-8f08-54615bf3a5c6'
  static readonly MANAGEMENT_ATTACHEDFARM_ADMINFARM: string = '6be3e5e1-f3ef-4e93-86b3-3ba1ca83df24'
  static readonly PRODUCTION_PRODUCT_FERTILIZER: string = '1b5aaf75-8443-4b65-89e0-2fc74f0f2125'
  static readonly PRODUCTION_PRODUCT_SEEDSSEEDLINGSTREES: string = 'ed30e5b6-1009-483a-91b8-9e441a4757c5'
  static readonly PRODUCTION_ANIMAL_ADMINBOVINE: string = 'f9e7c648-cda6-495d-91e2-015aeb0f0af8'
  static readonly PRODUCTION_ANIMAL_ADMINPORCIE: string = 'cfb34dbc-f804-4257-a671-423a57c24a05'

  static readonly PRODUCTION_ACTIVITIES_EVIDENCES: string = '2b67320c-6231-41ee-b41b-dfef192f7a6d'

  static readonly MANAGEMENT_CERTIFITATION: string = '2a94fc1a-6875-42ba-b57a-05f05dae42a3'

  static readonly DEVICE_VARIABLES: string = ' e80a7ed3-f965-4898-9709-24a99cdd925d'
}
