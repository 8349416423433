<ng-container *transloco="let t">
    <div class="flex flex-col">
        <div class="flex w-full items-center justify-center">
            <img class="w-32 sm:w-64 mt-4 dark:hidden" src="assets/images/empty-states/passion_fruit_light.svg"
                alt="No data found">
            <img class="w-32 sm:w-64 mt-4 hidden dark:flex" src="assets/images/empty-states/passion_fruit_dark.svg"
                alt="No data found">
        </div>
        <div class="text-2xl text-2xl sm:text-3xl font-semibold tracking-tight text-center w-full">{{ title ? title :
            t('ndfc.no-data-found.title') }}</div>
        <div class="mb-6 text-sd sm:text-base font-normal tracking-tight text-center w-full">{{ message ? message :
            t('ndfc.no-data-found.suggestion') }}</div>
    </div>
</ng-container>