<div class="relative flex flex-col w-full h-full">

  <!-- Dismiss button -->
  @if (data.dismissible) {
  <div class="absolute top-0 right-0 pt-4 pr-4">
    <button mat-icon-button [matDialogClose]="undefined">
      <mat-icon class="text-secondary" [svgIcon]="'heroicons_outline:x'"></mat-icon>
    </button>
  </div>
  }

  <!-- Content -->
  <div class="p-6 sm:pb-8">

    @if (data.title || data.message) {
    <div class="flex flex-col items-center sm:items-start mt-4 sm:mt-0  space-y-1 text-center ">
      <div class="m-auto w-full text-center" [ngClass]="{'text-primary-600': data.icon.color === 'primary',
                            'text-accent-600 ': data.icon.color === 'accent',
                            'text-warn-600 ': data.icon.color === 'warn',
                            'text-gray-600 ': data.icon.color === 'basic',
                            'text-blue-600 ': data.icon.color === 'info',
                            'text-green-500 ': data.icon.color === 'success',
                            'text-amber-500 ': data.icon.color === 'warning',
                            'text-red-600 ': data.icon.color === 'error'
                            }">
        <mat-icon class="text-current w-full h-12" [svgIcon]=" data.icon.name"></mat-icon>
        <!-- Title -->
        @if (data.title) {
        <div class="m-auto text-2xl leading-6 font-semibold" [innerHTML]="data.title"></div>
        }
      </div>
      <!-- Message -->
      @if (data.message) {

      @if (data.customTemplate){
      <ng-container *ngTemplateOutlet="data.customTemplate"> </ng-container>
      } @else {
      <div class="m-auto text-lg" [innerHTML]="data.message"></div>
      }

      }
    </div>
    }

  </div>

  <!-- Actions -->
  @if (data.actions.confirm.show || data.actions.cancel.show) {
  <div
    class="content-acction-button-confirm-modal m-auto flex flex-col items-center justify-center px-6 pb-6 sm:flex-row sm:justify-end sm:space-x-3">
    <!-- Cancel -->
    @if (data.actions.cancel.show) {
    <button class="app-form-buttons-cancel dark:text-primary-50" [color]="'primary'" mat-stroked-button
      [matDialogClose]="'cancelled'">
      <span>{{data.actions.cancel.label}}</span>
    </button>
    }
    <!-- Confirm -->
    @if (data.actions.confirm.show) {
    <button class="app-form-buttons-save" mat-flat-button [color]="'primary'" [matDialogClose]="'confirmed'">
      <span>{{data.actions.confirm.label}}</span>
    </button>
    }
  </div>
  }

</div>