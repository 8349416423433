import { provideHttpClient } from '@angular/common/http'
import { ApplicationConfig, ErrorHandler, importProvidersFrom } from '@angular/core'
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { provideAnimations } from '@angular/platform-browser/animations'
import { PreloadAllModules, provideRouter, withInMemoryScrolling, withPreloading } from '@angular/router'
import { Ability, PureAbility } from '@casl/ability'
import { AbilityModule } from '@casl/angular'
import { provideFuse } from '@fuse'
import { appRoutes } from 'app/app.routes'
import { provideAuth } from 'app/core/auth/auth.provider'
import { provideIcons } from 'app/core/icons/icons.provider'
import { provideTransloco } from 'app/core/transloco/transloco.provider'
import { mockApiServices } from 'app/mock-api'
import { NgxWebstorageModule } from 'ngx-webstorage'
import { GenericConstants } from './core/constants/generic.constants'
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field'
import { MomentDateAdapter } from '@angular/material-moment-adapter'
import { AppErrorHandlerService } from './core/services/errors/app-error-handler.service'
import { FormlyModule } from '@ngx-formly/core'
import { CustomInputTextComponent } from './shared/dynamic-form/custom-input-text/custom-input-text.component'
import {
  maxLengthMessage,
  minLengthMessage,
  numeric,
  numericMessage,
  required,
  requiredMessage,
} from './shared/dynamic-form/validators/validators-dynamic-form'
import { CustomDateInputComponent } from './shared/dynamic-form/custom-date-input/custom-date-input.component'
import { CustomSelectionButtonComponent } from './shared/dynamic-form/custom-selection-button/custom-selection-button.component'
import { CustomSlideToggleComponent } from './shared/dynamic-form/custom-slide-toggle/custom-slide-toggle.component'
import { CustomFileInputComponent } from './shared/dynamic-form/custom-file-input/custom-file-input.component'
import { CustomSignatureComponent } from './shared/dynamic-form/custom-signature/custom-signature.component'
import { AccordionWrapperComponent } from './shared/dynamic-form/wrappers/accordion.wrapper'
import { CustomMapComponent } from './shared/dynamic-form/custom-map/custom-map.component'

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideHttpClient(),
    provideRouter(
      appRoutes,
      withPreloading(PreloadAllModules),
      withInMemoryScrolling({ scrollPositionRestoration: 'enabled' }),
    ),

    // Transloco Config
    provideTransloco(),

    importProvidersFrom(
      MatSnackBarModule,
      NgxWebstorageModule.forRoot(),
      AbilityModule,
      FormlyModule.forRoot({
        types: [
          { name: 'custom-input-text', component: CustomInputTextComponent },
          { name: 'custom-date-input', component: CustomDateInputComponent },
          { name: 'custom-selection', component: CustomSelectionButtonComponent },
          { name: 'custom-slide-toggle', component: CustomSlideToggleComponent },
          { name: 'custom-file-input', component: CustomFileInputComponent },
          { name: 'custom-signature', component: CustomSignatureComponent },
          { name: 'custom-map', component: CustomMapComponent },
        ],
        validators: [
          { name: 'required', validation: required },
          { name: 'numeric', validation: numeric },
        ],
        validationMessages: [
          { name: 'required', message: 'Este campo es requerido' },
          { name: 'minLength', message: minLengthMessage },
          { name: 'maxLength', message: maxLengthMessage },
          { name: 'numeric', message: numericMessage },
        ],
        wrappers: [{ name: 'accordion', component: AccordionWrapperComponent }],
      }),
    ),
    { provide: Ability, useValue: new Ability() },
    { provide: PureAbility, useExisting: Ability },
    // Material Date Adapter
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: GenericConstants.MY_DATE_FORMAT },
    { provide: ErrorHandler, useClass: AppErrorHandlerService },

    // Fuse
    provideAuth(),
    provideIcons(),
    provideFuse({
      mockApi: {
        delay: 0,
        services: mockApiServices,
      },
      fuse: {
        layout: 'modern',
        scheme: 'light',
        screens: {
          sm: '600px',
          md: '960px',
          lg: '1280px',
          xl: '1440px',
        },
        theme: 'theme-brand',
        themes: [
          {
            id: 'theme-default',
            name: 'Default',
          },
          {
            id: 'theme-brand',
            name: 'Brand',
          },
          {
            id: 'theme-teal',
            name: 'Teal',
          },
          {
            id: 'theme-rose',
            name: 'Rose',
          },
          {
            id: 'theme-purple',
            name: 'Purple',
          },
          {
            id: 'theme-amber',
            name: 'Amber',
          },
        ],
      },
    }),

    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { subscriptSizing: 'dynamic' } },
  ],
}
