@if (!externalViewer) {
  @if (configuredViewer !== 'pdf') {
    <div [innerHtml]="docHtml"></div>
  }
  @if (fullUrl && configuredViewer === 'pdf') {
    <object
      [data]="fullUrl"
      type="application/pdf"
      width="100%"
      height="100%"
      >
      <p>
        Your browser does not support PDFs.
        <a [href]="fullUrl">Download the PDF</a>.
      </p>
    </object>
  }
}
@if (externalViewer) {
  @if (fullUrl && disableContent === 'none') {
    <iframe
      (load)="iframeLoaded()"
      #iframe
      id="iframe-doc-viewer"
      frameBorder="0"
      [src]="fullUrl"
    ></iframe>
  }
  @if (disableContent !== 'none') {
    <div class="container">
      <div
        [class.overlay-full]="disableContent === 'all'"
      [class.overlay-popout-google]="
        configuredViewer === 'google' && (disableContent === 'popout' || disableContent === 'popout-hide')
      "
      [class.overlay-popout-office]="
        configuredViewer === 'office' && (disableContent === 'popout' || disableContent === 'popout-hide')
      "
        [style.background-color]="disableContent === 'popout-hide' ? '#fff' : 'transparent'"
      ></div>
      @if (fullUrl) {
        <iframe (load)="iframeLoaded()" #iframe id="iframe" frameBorder="0" [src]="fullUrl"></iframe>
      }
    </div>
  }
}
