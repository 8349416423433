<div class="wrapper">
  <mat-label>{{ props['label'] }}</mat-label>
  <p class="custom-description">{{ props['description'] }}</p>
  <div class="container-toggle">
    <mat-button-toggle-group [multiple]="props['multiple']" [formControl]="formControl" [formlyAttributes]="field"
      [required]="props['required']" [name]="props['name']">
      @for(option of props['selectOptions']; track option; let indes=$index){
      <mat-button-toggle (change)="toggleActiveStyle($event, option.color)" [style.width]="props['width']"
        [style.min-width]="props['width']" [value]="option.value">{{ option.label }}
      </mat-button-toggle>
      }
    </mat-button-toggle-group>
    <mat-error *ngIf="showError">{{ errorMessage }}</mat-error>
  </div>
  <app-custom-note (sendText)="getNote($event)" [textValue]="props['note']" (sendImage)="getImage($event)"
    [formDisable]="formControl"></app-custom-note>
</div>