import { Injectable } from '@angular/core'
import { cloneDeep } from 'lodash-es'
import { FuseMockApiService } from '@fuse/lib/mock-api'
import { OdataResponse } from '../../../core/interfaces/others/odata-response.interface'
import { IndexCategory } from 'app/core/interfaces/others/index-category.interface'
import {
  settingsCategoriesEs,
  settingsCategoriesEn,
  managementCategoriesEn,
  managementCategoriesEs,
  planningCategoriesEs,
  planningCategoriesEn,
  inventoryCategoriesEs,
  inventoryCategoriesEn,
  inventoryManagementEn,
  inventoryManagementEs,
  reportsCategoriesEs,
  reportsCategoriesEn,
  analyticsCategoriesEs,
  analyticsCategoriesEn,
  staffCategoriesEn,
  staffCategoriesEs,
  certificationsCategoriesEn,
  certificationsCategoriesEs,
  IoTCategoriesEs,
  IoTCategoriesEn,
} from './data'
import { IndexTypes } from 'app/core/enums/index-types.enum'
import { Languages } from 'app/core/enums/languages.enum'
import { Permission } from 'app/core/interfaces/identity/permission.interface'
import { Permissions } from 'app/mock-api/common/permissions/data'
import { IndexOption } from 'app/core/interfaces/others/index-option.interface'
import { CaslActions } from 'app/core/enums/casl-actions.enum'
import { UserService } from 'app/core/services/identity/user.service'

@Injectable({
  providedIn: 'root',
})
export class IndexCategoriesMockApi {
  private _allCategories: IndexCategory[] = []
  private _userCategories: IndexCategory[] = []
  private readonly _permissions: Permission[] = Permissions

  /**
   * Constructor
   */
  constructor(private _fuseMockApiService: FuseMockApiService, private _userService: UserService) {
    // Register Mock API handlers
    this.registerHandlers()
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Register Mock API handlers
   */
  registerHandlers(): void {
    // -----------------------------------------------------------------------------------------------------
    // @ Navigation - GET
    // -----------------------------------------------------------------------------------------------------
    this._fuseMockApiService.onGet('api/common/index-categories/:type/:filter', 100).reply(({ request, urlParams }) => {
      this._userCategories = []
      let filter = urlParams['filter'].toLowerCase()
      let language = request.headers.get('Accept-Language')

      if (urlParams['type']) {
        switch (urlParams['type']) {
          case IndexTypes.Settings:
            this._allCategories = language == Languages.EsCo ? settingsCategoriesEs : settingsCategoriesEn
            break
          case IndexTypes.Management:
            this._allCategories = language == Languages.EsCo ? managementCategoriesEs : managementCategoriesEn
            break
          case IndexTypes.Planning:
            this._allCategories = language == Languages.EsCo ? planningCategoriesEs : planningCategoriesEn
            break
          case IndexTypes.Inventory:
            this._allCategories = language == Languages.EsCo ? inventoryCategoriesEs : inventoryCategoriesEn
            break
          case IndexTypes.ManagementInventory:
            this._allCategories = language == Languages.EsCo ? inventoryManagementEs : inventoryManagementEn
            break
          case IndexTypes.Reports:
            this._allCategories = language == Languages.EsCo ? reportsCategoriesEs : reportsCategoriesEn
            break
          case IndexTypes.Analytics:
            this._allCategories = language == Languages.EsCo ? analyticsCategoriesEs : analyticsCategoriesEn
            break
          case IndexTypes.Staff:
            this._allCategories = language == Languages.EsCo ? staffCategoriesEs : staffCategoriesEn
            break
          case IndexTypes.Certifications:
            this._allCategories = language == Languages.EsCo ? certificationsCategoriesEs : certificationsCategoriesEn
            break
          case IndexTypes.Iot:
            this._allCategories = language == Languages.EsCo ? IoTCategoriesEs : IoTCategoriesEn
            break
        }
      }

      // Set items for user navigation
      this._allCategories.forEach(category => {
        let options: IndexOption[] = []

        category.options.forEach(option => {
          //Validate permissions for this items
          const optionWithPermission = this._permissions.find(
            per =>
              per.subject == option.id &&
              per.action == CaslActions.View &&
              per.roles.some(role => this._userService.getRoles().includes(role)),
          )
          if (optionWithPermission) {
            if (filter) {
              if (
                option.title.toLocaleLowerCase().includes(filter) ||
                option.description.toLocaleLowerCase().includes(filter)
              ) {
                options.push(option)
              }
            } else {
              options.push(option)
            }
          }
        })

        if (options.length) {
          this._userCategories.push({
            id: category.id,
            title: category.title,
            options: options,
          })
        }
      })

      let response: OdataResponse<IndexCategory>
      let value: IndexCategory[] = cloneDeep(this._userCategories)

      response = {
        '@odata.count': value.length,
        value: value,
      }

      // Return the response
      return [200, response]
    })
  }
}
