<ng-container *transloco="let t">
  <!-- Actions -->
  <div class="flex flex-row-reverse mt-3">
    <!-- Add button -->
    @if (isEditable && showAddButton) {
      <button
        class="app-header-options-library"
        mat-stroked-button
        (click)="openOptionalLoadForm()"
        >
        <mat-icon class="app-button-icon" [svgIcon]="'heroicons_outline:plus'"></mat-icon>
        <span class="app-button-text">{{ t('libcom.list.buttons.add-optional') }}</span>
      </button>
    }
  </div>

  <!-- List -->
  <div class="app-list-container-in-step">
    @if (items$ | async; as items) {
      <div class="app-list-subcontainer">
        <!-- Header -->
        <div
          class="items-grid app-list-header"
          matSort
          [matSortActive]="configList.defaultSort"
          [matSortDirection]="configList.defaultOrder"
          matSortDisableClear="true"
          >
          <div [mat-sort-header]="'fileTypeName'">{{ t('libcom.list.column-headers.fileTypeName') }}</div>
          <div class="hidden sm:block" [mat-sort-header]="'required'">
            {{ t('libcom.list.column-headers.required') }}
          </div>
          <div class="hidden sm:block" [mat-sort-header]="'file/name'">{{ t('libcom.list.column-headers.name') }}</div>
          <div class="hidden md:block" [mat-sort-header]="'file/size'">{{ t('libcom.list.column-headers.size') }}</div>
          <div class="hidden md:block" [mat-sort-header]="'file/observation'">
            {{ t('libcom.list.column-headers.observation') }}
          </div>
          <div>{{ t('libcom.list.column-headers.actions') }}</div>
        </div>
        <!-- Rows -->
        @for (item of items; track trackByFn($index, item)) {
          <div class="items-grid app-list-row">
            <!-- fileTypeName -->
            <div class="truncate">
              <span matTooltip="{{ item.fileTypeName }}">{{ item.fileTypeName }}</span>
            </div>
            <!-- required -->
            <div class="hidden sm:block">
              <mat-chip-listbox>
                <mat-chip [color]="item.required ? 'warn' : 'accent'" highlighted>{{
                  t(item.required ? 'yes-or-no-options.yes' : 'yes-or-no-options.no')
                }}</mat-chip>
              </mat-chip-listbox>
            </div>
            <!-- Name -->
            <div class="hidden sm:block truncate">
              @if (item.file) {
                <span>
                  <a href="javascript:void(0)" class="general-link" (click)="viewFile(item.file)">{{
                    item.file?.name
                  }}</a>
                </span>
              }
            </div>
            <!-- size -->
            <div class="hidden md:block">
              {{ item.file ? genericUtil.convertSize(item.file.size) : '' }}
            </div>
            <!-- observation -->
            <div class="hidden md:block truncate">
              {{ item.file?.observation }}
            </div>
            <!-- Actions -->
            <div class="app-list-actions">
              @if (!item.file && isEditable) {
                <span>
                  <button mat-icon-button (click)="openRequiredLoadForm(item)" title="{{ t('libcom.list.info.upload') }}">
                    <mat-icon class="icon-size-4" [svgIcon]="'attach_file'"></mat-icon>
                  </button>
                </span>
              }
              @if (item.file) {
                <span>
                  <button mat-icon-button [matMenuTriggerFor]="actionsMenu">
                    <mat-icon class="icon-size-4" [svgIcon]="'feather:more-vertical'"></mat-icon>
                  </button>
                  <mat-menu #actionsMenu="matMenu">
                    <button mat-menu-item (click)="saveFile(item.file)">
                      <span class="app-mat-menu-button">
                        <mat-icon class="app-menu-button-icon" [svgIcon]="'mat_outline:file_download'"></mat-icon>
                        <span class="app-menu-button-text">{{ t('libcom.list.buttons.download') }}</span>
                      </span>
                    </button>
                    @if (isEditable) {
                      <button mat-menu-item (click)="confirmDelete(item)">
                        <span class="app-mat-menu-button">
                          <mat-icon class="app-menu-button-icon" [svgIcon]="'mat_outline:delete'"></mat-icon>
                          <span class="app-menu-button-text">{{ t('libcom.list.buttons.delete') }}</span>
                        </span>
                      </button>
                    }
                  </mat-menu>
                </span>
              }
            </div>
          </div>
        }
        <!-- No data -->
        @if (!items.length) {
          <app-no-data-found></app-no-data-found>
        }
        <!-- Paginator -->
        <div class="app-mat-paginator">
          <mat-paginator
            [length]="numberItems"
            [pageSize]="('' | constants).genericConstants.PAGE_SIZE_DEFAULT"
            [pageSizeOptions]="('' | constants).genericConstants.PAGE_SIZE_OPTIONS"
            [showFirstLastButtons]="true"
            >
          </mat-paginator>
        </div>
      </div>
    }
  </div>
</ng-container>
