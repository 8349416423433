import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, ReplaySubject, switchMap, tap } from 'rxjs';
import { Permission } from 'app/core/interfaces/identity/permission.interface';

@Injectable({
    providedIn: 'root'
})
export class PermissionsService
{
    private _permissions: ReplaySubject<Permission[]> = new ReplaySubject<Permission[]>(1);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get permissions$(): Observable<Permission[]>
    {
        return this._permissions.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all permissions data
     */
    get(roles: string[]): Observable<Permission[]>
    {
        return this._httpClient.get<Permission[]>('api/common/permissions').pipe(
            switchMap((permissions) => {
                let permissionsRoles = this._getPermissionsRoles(roles, permissions);
                
                this._permissions.next(permissionsRoles);

                return of(permissionsRoles);
            })
        );
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    private _getPermissionsRoles(roles: string[], permissions: Permission[]): Permission[]
    {
        let permissionsRoles:Permission[] = [];

        if(roles != null && permissions != null)
        {
            roles.forEach(role => {
                permissions.forEach(permission => {
                    if(permission.roles.includes(role))
                    {
                        permissionsRoles.push(permission);
                    }
                });
            });
        }
        
        return permissionsRoles;
    }

}
