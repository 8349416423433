import { Component, Inject, OnDestroy, OnInit } from '@angular/core';

import { AppDocViewerComponent } from '../app-doc-viewer/app-doc-viewer.component';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { ConfirmDialogResponses } from 'app/core/enums/confirm-dialog-responses.enum';
import { AppAlertService } from 'app/core/services/others/app-alert.service';
import { ErrorUtil } from 'app/core/utils/error.util';
import { GenericUtil } from 'app/core/utils/generic.util';
import { Subject, takeUntil } from 'rxjs';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { AppFileViewerConfig } from './app-file-viewer-config.interface';
import { FilesService } from 'app/core/library/services/files.service';

@Component({
  selector: 'app-file-viewer',
  standalone: true,
  imports: [AppDocViewerComponent, TranslocoModule, MatDialogModule, MatIconModule, MatButtonModule],
  templateUrl: './app-file-viewer.component.html',
  styleUrls: ['./app-file-viewer.component.scss']
})
export class AppFileViewerComponent implements OnInit, OnDestroy {
  protected _unsubscribeAll: Subject<any> = new Subject<any>();
  confirmDialogResponses = ConfirmDialogResponses;
  showFileViewer: boolean = false;
  showAudioPlayer: boolean = false;
  showVideoPlayer: boolean = false;
  showImageViewer: boolean = false;
  docExtensions: string[] = ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'pdf'];
  audioExtensions: string[] = ['mp3', 'aac', 'ogg', 'webm'];
  videoExtensions: string[] = ['webm', 'mp4'];
  imageExtensions: string[] = ['gif', 'jpg', 'jpeg', 'png'];

  /**
   *
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AppFileViewerConfig,
    protected _translocoService: TranslocoService,
    public errorUtil: ErrorUtil,
    protected _genericUtil: GenericUtil,
    private _filesService: FilesService,
    protected _appAlertService: AppAlertService
  ) {}

  ngOnInit(): void {
    const fileExtension: string = this.data.extension.toLowerCase();

    if (this.docExtensions.includes(fileExtension)) {
      this.showFileViewer = true;
    } else if (this.audioExtensions.includes(fileExtension)) {
      this.showAudioPlayer = true;
    } else if (this.videoExtensions.includes(fileExtension)) {
      this.showVideoPlayer = true;
    } else if (this.imageExtensions.includes(fileExtension)) {
      this.showImageViewer = true;
    } else {
      this._appAlertService.showError('File with unknown extension');
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  /**
   * Save file
   * @param appFile
   */
  saveFile() {
    this._filesService.saveFile(
      this.data.fileUrl.guid,
      `${this.data.title}.${this.data.extension}`,
      this._translocoService.translate('generic-messages.messages.save-finished-file')
    );
  }
}
