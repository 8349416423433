/**
 * Storage keys in the browser
 */
export abstract class StorageKeysConstants {
  /**
   * User
   */
  static readonly USER: string = 'user';

  /**
   * Access token
   */
  static readonly ACCESS_TOKEN: string = 'accessToken';

  /**
   * Refresh token
   */
  static readonly REFRESH_TOKEN: string = 'refreshToken';

  /**
   * Remember me
   */
  static readonly REMEMBER_ME: string = 'rememberme';

  /**
   * Validate email for sign up
   */
  static readonly VALIDATE_EMAIL: string = 'validateEmail';
}
