import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { BooleanInput } from '@angular/cdk/coercion';
import { Subject, takeUntil, switchMap, Observable } from 'rxjs';
import { User } from 'app/core/interfaces/identity/user.interface';
import { UserService } from 'app/core/services/identity/user.service';
import { BusinessUnit } from '../../../core/interfaces/management/business-unit.interface';
import { GenericConstants } from '../../../core/constants/generic.constants';
import { OdataResponse } from 'app/core/interfaces/others/odata-response.interface';
import { AuthService } from '../../../core/auth/auth.service';
import { SignInResponse } from 'app/core/interfaces/identity/sign-in-response.interface';
import { TranslocoService, TranslocoModule, TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { IdentificatorsConstants } from 'app/core/constants/identificator.constants';
import { MatOptionModule } from '@angular/material/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { NgClass, AsyncPipe } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { MatSelectChange } from '@angular/material/select';
import { Administrator } from '../../../core/interfaces/management/administrator.interface';
import { Roles } from 'app/core/enums/roles.enum';
import { RoleTypes } from 'app/core/enums/role-types.enum';
export const loader = GenericConstants.APP_LANGUAGES.reduce((acc, lang) => {
  acc[lang] = () => import(`./i18n/${lang}.json`);
  return acc;
}, {});

@Component({
  selector: 'user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'user',
  standalone: true,
  imports: [
    TranslocoModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    NgClass,
    MatDividerModule,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    RxReactiveFormsModule,
    MatOptionModule,
    RouterLink,
    AsyncPipe
  ],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'UserModule',
        alias: 'm',
        loader
      },
      multi: true
    }
  ]
})
export class UserComponent implements OnInit, OnDestroy {
  /* eslint-disable @typescript-eslint/naming-convention */
  static ngAcceptInputType_showAvatar: BooleanInput;
  /* eslint-enable @typescript-eslint/naming-convention */

  @Input() showAvatar: boolean = true;
  user: User;

  businessUnitGuid: string;
  userBusinessUnits: BusinessUnit[];
  genericBusinessUnitGuid: string = IdentificatorsConstants.GENERIC_BUSINESS_UNIT_GUID;
  currentBusinessUnit: BusinessUnit = null;
  userHasGenericRoles: boolean = false;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  businessUnits$: Observable<BusinessUnit[]> = this._userService.businessUnits$;

  /**
   * Constructor
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _router: Router,
    private _userService: UserService,
    private _authService: AuthService,
    private _translocoService: TranslocoService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to user changes

    this.user = this._userService.user;

    if (this.user != null) {
      this._userService
        .getBusinessUnits()
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe({
          next: (response: OdataResponse<BusinessUnit>) => {
            this.userBusinessUnits = response.value;

            this.businessUnitGuid = this._userService.businessUnitId;
            this.userHasGenericRoles =
              this._userService.hasRole(Roles.Producer, RoleTypes.Generic) ||
              this._userService.hasRole(Roles.Organization, RoleTypes.Generic)
                ? true
                : false;
            this.currentBusinessUnit = this.userBusinessUnits.find((x) => x.guid == this.businessUnitGuid);

            if (this.currentBusinessUnit == null) {
              this._translocoService.selectTranslate('manage-all', null, 'UserModule').subscribe({
                next: (value) => {
                  this.currentBusinessUnit = {
                    name: this._translocoService.translate('manage-all', null, 'UserModule'),
                    guid: this.businessUnitGuid
                  };
                }
              });
            }

            // Mark for check
            this._changeDetectorRef.markForCheck();
          },
          error: (err) => {
            //console.log('error', err);
          }
        });
    }
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Sign out
   */
  signOut(): void {
    this._router.navigate(['/sign-out']);
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  onBusinessUnitChange(businessUnitChange: MatSelectChange) {
    let businessUnitType = '';

    this.businessUnits$.pipe(takeUntil(this._unsubscribeAll)).subscribe({
      next: (result) => {
        this.userBusinessUnits = result;

        let businessUnit = this.userBusinessUnits.find((bu) => bu.guid == businessUnitChange.value);
        if (businessUnit) {
          businessUnitType = businessUnit.type;
        }

        this._authService
          .changeBusinessUnit(businessUnitChange.value, businessUnitType)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe({
            next: (response: SignInResponse) => {
              this._router.navigate(['/dashboard/refresh']);
            }
          });
      }
    });
  }
}
