import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FuseAlertComponent } from '@fuse/components/alert';

@Component({
    selector: 'app-app-snack-bar',
    templateUrl: './app-snack-bar.component.html',
    styleUrls: ['./app-snack-bar.component.scss'],
    standalone: true,
    imports: [MatIconModule, MatButtonModule, FuseAlertComponent ]
})
export class AppSnackBarComponent implements OnInit {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) { }

  ngOnInit(): void {
  }

  get getIcon() {
    switch (this.data.snackType) {
      case 'success':
        return {type: this.data.snackType, icon: 'mat_outline:check_circle'};
      case 'error':
        return {type: this.data.snackType, icon: 'heroicons_solid:x-circle'};
      case 'warning':
        return {type: this.data.snackType, icon: 'heroicons_outline:exclamation-triangle'};
      case 'info':
        return {type: this.data.snackType, icon: 'heroicons_solid:information-circle'};
    }
  }

}
