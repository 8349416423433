import {
  AfterContentInit,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Type,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatIconModule } from '@angular/material/icon'
import { TranslocoModule } from '@jsverse/transloco'
import { FieldWrapper } from '@ngx-formly/core'
import { Subscription } from 'rxjs'
@Component({
  selector: 'app-accordion-wrapper',
  standalone: true,
  imports: [MatExpansionModule, MatIconModule, TranslocoModule],
  templateUrl: './accordion.wrapper.html',
  styleUrl: './accordion.wrapper.scss',
  encapsulation: ViewEncapsulation.None,
})
export class AccordionWrapperComponent extends FieldWrapper {
  @ViewChild('fieldComponent', { read: ViewContainerRef })
  fieldComponent: ViewContainerRef
  isComplete: boolean = false
  private subscriptions: Subscription[] = []

  constructor(private resolver: ComponentFactoryResolver) {
    super()
  }

  ngAfterContentInit() {
    this.checkFieldsCompletion()
    this.subscribeToFormChanges()
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe())
  }

  subscribeToFormChanges() {
    if (this.field && this.field.fieldGroup) {
      this.field.fieldGroup.forEach(field => {
        if (field.formControl) {
          const subscription = field.formControl.valueChanges.subscribe(() => {
            this.checkFieldsCompletion()
          })
          this.subscriptions.push(subscription)
        }
      })
    }
  }

  checkFieldsCompletion() {
    if (this.field && this.field.fieldGroup) {
      this.isComplete = this.field.fieldGroup.every(field => {
        if (field.fieldGroup) {
          return field.fieldGroup.every(
            subField =>
              subField.formControl &&
              (subField.formControl.value || subField.formControl.value === false) &&
              (subField.formControl.valid || subField.formControl.disabled),
          )
        } else {
          return (
            field.formControl &&
            (field.formControl.value || field.formControl.value === false) &&
            (field.formControl.valid || field.formControl.disabled)
          )
        }
      })
    }
  }
}
