@if (options.length) {
  <button class="app-header-options" mat-stroked-button [matMenuTriggerFor]="optionsMenu">
    <mat-icon class="app-button-icon" [svgIcon]="'mat_outline:adjust'"></mat-icon>
    <span class="app-button-text">{{ label }}</span>
  </button>
}

<mat-menu #optionsMenu="matMenu">
  @for (option of options; track option) {
    <button mat-menu-item (click)="setFunctionToCall(option.function)">
      <span class="app-mat-menu-button">
        <mat-icon class="app-menu-button-icon" [svgIcon]="option.icon"></mat-icon>
        <span class="app-menu-button-text">{{ option.label }}</span>
      </span>
    </button>
  }
</mat-menu>
