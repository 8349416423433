import { ChangeDetectorRef, Component, ViewChild, ViewEncapsulation } from '@angular/core'
import { SignatureComponent, SignatureModule } from '@syncfusion/ej2-angular-inputs'
import { BaseCustomFieldComponent } from '../base-custom-field.component'
import { NgIf, NgFor } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatTooltipModule } from '@angular/material/tooltip'
import { FormlyModule } from '@ngx-formly/core'
import { ErrorMessageComponent } from 'app/shared/error-message/error-message.component'
import { Observable } from 'rxjs'
import { BlockScrollStrategy, Overlay } from '@angular/cdk/overlay'
import { MatDialog } from '@angular/material/dialog'
import { ImageCropperComponent } from 'app/shared/image-cropper/image-cropper.component'
@Component({
  selector: 'app-custom-signature',
  standalone: true,
  imports: [
    SignatureModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule,
    MatIconModule,
    MatTooltipModule,
    ErrorMessageComponent,
    NgIf,
    NgFor,
    MatButtonModule,
  ],
  templateUrl: './custom-signature.component.html',
  styleUrl: './custom-signature.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class CustomSignatureComponent extends BaseCustomFieldComponent {
  @ViewChild('signature') signature: SignatureComponent
  file: any
  draw: boolean = false
  private _overlay: Overlay
  private _scrollStrategy: BlockScrollStrategy

  constructor(private _matDialog: MatDialog, private cd: ChangeDetectorRef) {
    super()
  }
  change(event): void {
    if (!this.signature.isEmpty()) {
      this.formControl.patchValue(this.signature.signatureValue)
      this.cd.detectChanges()
    }
  }

  clear(): void {
    this.signature.clear()
  }

  openItemEditor(image: string): Observable<any> {
    let data = {
      image,
      custom_cropper: true,
    }
    this._scrollStrategy = this._overlay?.scrollStrategies.block()
    const dialogRef = this._matDialog.open(ImageCropperComponent, {
      autoFocus: false,
      disableClose: true,
      data: data,
      panelClass: ['app-modal-panel-full', 'custom-cropper'],
      scrollStrategy: this._scrollStrategy,
    })

    return dialogRef.afterClosed()
  }

  onFileChange(event: any) {
    const files = event.target.files as FileList

    if (files.length > 0) {
      const _file = URL.createObjectURL(files[0])
      this.openItemEditor(_file).subscribe(result => {
        if (result) {
          if (result.size > this.props['maxFileSize'] * 1024 * 1024) {
            // this.errorMessage = `The file size exceeds the maximum allowed size of ${this.maxFileSize / (1024 * 1024)} MB.`;
            alert('max MB')
            return
          }
          let file = files[0]
          file['image'] = result
          this.file = file
          this.draw = false
          this.formControl.patchValue(this.file)
          this.cd.detectChanges()
        }
      })
    }
  }

  onDraw() {
    this.draw = true
    this.file = null
  }
}
