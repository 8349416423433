import { AbstractControl, FormControl, ValidationErrors } from '@angular/forms'
import { FormlyFieldConfig } from '@ngx-formly/core'

export function required(control: FormControl): ValidationErrors {
  return control.value ? null : { required: true }
}

export function numeric(control: FormControl): ValidationErrors | null {
  if (!control.value || isNaN(control.value)) {
    return { numeric: true }
  }
  return null
}

export function requiredMessage(err, field: FormlyFieldConfig) {
  return `'${field.props.label}' no debería estar vacío`
}

export function maxLengthMessage(err, field: FormlyFieldConfig) {
  return `'${field.props.label}' debe ser menor o igual que ${field.props.maxLength}`
}

export function minLengthMessage(err, field: FormlyFieldConfig) {
  return `'${field.props.label}' debe ser mayor o igual que ${field.props.minLength}`
}

export function numericMessage(err, field: FormlyFieldConfig) {
  return `'${field.props.label}'  solo debe tener caracteres numéricos.`
}

export function maxMessage(err, field: FormlyFieldConfig) {
  return `'${field.props.label}' debe ser menor o igual que ${field.props.max}`
}

export function minMessage(err, field: FormlyFieldConfig) {
  return `'${field.props.label}' debe ser mayor o igual que ${field.props.min}`
}
