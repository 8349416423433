<div
  class="settings-cog fixed flex items-center justify-center right-0 w-10 h-10 shadow-lg rounded-l-lg z-90 cursor-pointer bg-red-600 bg-opacity-90 print:hidden"
  [class.lg:right-0]="config.layout === 'centered' || config.layout === 'material'"
  [class.lg:right-0]="config.layout !== 'centered' && config.layout !== 'material'" style="top: 275px"
  (click)="settingsDrawer.toggle()">
  <mat-icon class="icon-size-5 text-white animate-spin-slow" [svgIcon]="'heroicons_solid:cog-8-tooth'"></mat-icon>
</div>

<fuse-drawer class="w-screen min-w-screen sm:w-100 sm:min-w-100 z-999" fixed [mode]="'over'" [name]="'settingsDrawer'"
  [position]="'right'" #settingsDrawer>

  <div class="flex flex-col w-full overflow-auto">
    <div class="flex flex-row items-center px-6 h-20 min-h-20 text-white bg-primary">
      <mat-icon class="icon-size-7 text-current" [svgIcon]="'heroicons_solid:cog-8-tooth'"></mat-icon>
      <div class="ml-3 text-2xl font-semibold tracking-tight">Settings</div>
      <button class="ml-auto" mat-icon-button (click)="settingsDrawer.close()">
        <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
      </button>
    </div>

    <div class="flex flex-col p-6">

      <!-- Scheme -->
      <div class="text-md font-semibold text-secondary">SCHEME</div>
      <div class="grid grid-cols-3 gap-3 justify-items-start mt-6">
        <!-- Auto -->
        <div class="flex items-center py-3 pl-5 pr-6 rounded-full cursor-pointer ring-inset ring-primary bg-hover"
          [class.ring-2]="config.scheme === 'auto'"
          matTooltip="Automatically sets the scheme based on user's operating system's color scheme preference using 'prefer-color-scheme' media query."
          (click)="setScheme('auto')">
          <div class="flex items-center rounded-full overflow-hidden">
            <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:bolt'"></mat-icon>
          </div>
          <div class="flex items-center ml-2 font-medium leading-5" [class.text-secondary]="config.scheme !== 'auto'">
            Auto
          </div>
        </div>
        <!-- Dark -->
        <div class="flex items-center py-3 pl-5 pr-6 rounded-full cursor-pointer ring-inset ring-primary bg-hover"
          [class.ring-2]="config.scheme === 'dark'" (click)="setScheme('dark')">
          <div class="flex items-center rounded-full overflow-hidden">
            <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:moon'"></mat-icon>
          </div>
          <div class="flex items-center ml-2 font-medium leading-5" [class.text-secondary]="config.scheme !== 'dark'">
            Dark
          </div>
        </div>
        <!-- Light -->
        <div class="flex items-center py-3 pl-5 pr-6 rounded-full cursor-pointer ring-inset ring-primary bg-hover"
          [class.ring-2]="config.scheme === 'light'" (click)="setScheme('light')">
          <div class="flex items-center rounded-full overflow-hidden">
            <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:sun'"></mat-icon>
          </div>
          <div class="flex items-center ml-2 font-medium leading-5" [class.text-secondary]="config.scheme !== 'light'">
            Light
          </div>
        </div>
      </div>

      <hr class="my-8">

      <!-- Language -->
      <div class="text-md font-semibold text-secondary">LANGUAGE</div>
      <div class="grid grid-cols-2 gap-3 justify-items-start mt-6">
        <!-- English -->
        <div class="flex items-center py-3 pl-5 pr-6 rounded-full cursor-pointer ring-inset ring-primary bg-hover"
          [class.ring-2]="language === 'en'"
          matTooltip="Automatically sets the scheme based on user's operating system's color scheme preference using 'prefer-color-scheme' media query."
          (click)="setLanguage('en')">
          <div class="flex items-center rounded-full overflow-hidden">
            <img class="w-8 ml-2" [src]="'../../../../assets/images/flags/en.svg'">
          </div>
          <div class="flex items-center ml-2 font-medium leading-5" [class.text-secondary]="language !== 'en'">
            English
          </div>
        </div>
        <!-- Spanish -->
        <div class="flex items-center py-3 pl-5 pr-6 rounded-full cursor-pointer ring-inset ring-primary bg-hover"
          [class.ring-2]="language === 'es'" (click)="setLanguage('es')">
          <div class="flex items-center rounded-full overflow-hidden">
            <img class="w-8 ml-2" [src]="'../../../../assets/images/flags/es.svg'">
          </div>
          <div class="flex items-center ml-2 font-medium leading-5" [class.text-secondary]="language !== 'es'">
            Spanish
          </div>
        </div>
      </div>

      <hr class="my-8">

      <!-- Theme -->
      <div class="text-md font-semibold text-secondary">THEME</div>
      <div class="grid grid-cols-2 sm:grid-cols-3 gap-3 mt-6">
        @for (theme of config.themes; track theme) {
        <div
          class="flex items-center justify-center px-4 py-3 rounded-full cursor-pointer ring-inset ring-primary bg-hover"
          [class.ring-2]="config.theme === theme.id" [ngClass]="theme.id" (click)="setTheme(theme.id)">
          <div class="flex-0 w-3 h-3 rounded-full bg-primary"></div>
          <div class="ml-2.5 font-medium leading-5 truncate" [class.text-secondary]="config.theme !== theme.id">
            {{theme.name}}
          </div>
        </div>
        }
      </div>

    </div>

  </div>

</fuse-drawer>