import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TranslocoModule } from '@jsverse/transloco';
import { ActionButton } from './action-button.interface';
import { GenericUtil } from 'app/core/utils/generic.util';
import { AppAlertService } from 'app/core/services/others/app-alert.service';

@Component({
  selector: 'app-actions-button-list',
  standalone: true,
  imports: [MatButtonModule, MatMenuModule, MatIconModule, TranslocoModule],
  templateUrl: './actions-button-list.component.html',
  styleUrls: ['./actions-button-list.component.scss']
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionsButtonListComponent implements OnChanges {
  @Input('data') data: any;
  @Input('actions') actions: ActionButton[];
  @Output() actionRequested = new EventEmitter<{ functionName: string; args: any[] }>();
  private _cachedShowButton: { [key: number]: boolean } = {};
  //private _cachedValues: { [key: number]: any } = {};

  /**
   *
   */
  constructor(private _genericUtil: GenericUtil, private _appAlertService: AppAlertService) {}

  ngOnChanges(changes: SimpleChanges) {
    this._cachedShowButton = {};
  }

  /**
   * Notify parent component to call a given function
   * @param action
   */
  notifyParent(action: ActionButton) {
    let params: any[] = [];

    action.function.params.forEach((par) => {
      let paramValue: any = par.name;

      if (par.name === 'data') {
        //All data
        paramValue = this.data;
      }

      if (par.fromItem) {
        paramValue = this.getDataValue(par.name);
      }

      if (par.encrypted) {
        paramValue = this._genericUtil.encryptUrlParameter(paramValue);
      }

      params.push(paramValue);
    });

    this.actionRequested.emit({ functionName: action.function.name, args: params });
  }

  /**
   * determines whether action should be shown or not based on conditions
   * @param action
   * @returns
   */
  showButton(action: ActionButton): boolean {
    let show: boolean = true;

    if (this._cachedShowButton.hasOwnProperty(action.id)) {
      return this._cachedShowButton[action.id];
    }
    if (action.dataConditions) {
      for (let dc of action.dataConditions) {
        let propertyValue = this.getDataValue(dc.property);

        if ((dc.operator === '==' || dc.operator == undefined) && propertyValue != dc.expectedValue) {
          show = false;
          break;
        } else if (dc.operator === '!=' && propertyValue == dc.expectedValue) {
          show = false;
          break;
        }
      }
    }

    this._cachedShowButton[action.id] = show;
    return show;
  }

  /**
   * Gets the value of a property from its name
   * @param property
   * @returns
   */
  getDataValue(property: string): any {
    let paramValue: any;
    const properties = property.split('.');
    let currentObject = this.data;
    for (const prop of properties) {
      if (currentObject.hasOwnProperty(prop)) {
        currentObject = currentObject[prop];
      } else {
        this._appAlertService.showError('The parameter does not exist in the item');
        return false;
      }
    }
    paramValue = currentObject;
    return paramValue;
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }
}
